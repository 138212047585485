import React from 'react';
import {
	Col,
	FormGroup,
	Row,
	Input,
	Label,
	ButtonGroup,
	Container,
	ModalFooter,
} from 'reactstrap';
import {
	UserRole,
	getUserRoleName,
	roleIsAtLeast,
} from '../../../accounts/models/UserRole.model';
import { UserStatus } from '../../../accounts/models/UserStatus.model';
import { enumValues } from '../../../../utils/common';

import { LabeledInput, SubmitButton } from '../../../forms';
import UserSelect from './UserSelect';
import UserSelectWithOptions from './UserSelect.component';
import { useAuthContext, User } from '../../../../utils/auth';
import { StyledChangeFormButton } from 'pages/views/Register/components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faEnvelope,
	faFileUpload,
	faLock,
} from '@fortawesome/free-solid-svg-icons';
import DialogModal from 'components/modals/dialog-modal.component';
import { NotificationsContext } from 'components/notifications';
import {
	DangerButton,
	SecondaryButton,
} from 'components/buttons.styled-components';
import { Role, useRoleContext } from 'context/PermissionsContext';
import { navigate } from '@reach/router';

interface EditUserFormProps {
	selectedUser: User;
	onSubmit: (updatedUser: User) => Promise<void>;
	submitLabel?: string;
	showRole?: boolean;
	showChangePassword?: boolean;
	isReadonly?: boolean;
}

type ChangePasswordProps = {
	afterSubmit: () => void;
};

const ChangePasswordDialog = (props: ChangePasswordProps) => {
	const [newPassword, setNewPassword] = React.useState('');
	const [confirmNewPassword, setConfirmNewPassword] = React.useState('');
	const { changePassword } = useAuthContext();
	const { info, error: showError } = React.useContext(NotificationsContext);

	const isValid = React.useMemo(() => {
		return (
			/(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}/.test(newPassword) &&
			newPassword === confirmNewPassword
		);
	}, [newPassword, confirmNewPassword]);
	const confirmPasswordChange = async () => {
		const wasSuccessful = await changePassword(newPassword);
		if (wasSuccessful) {
			info('Updated password successfully!');
		} else {
			showError(
				'Issue encountered while updating password. Please try again later.'
			);
		}
		props.afterSubmit();
	};

	return (
		<DialogModal header="Update password" onClosed={props.afterSubmit}>
			<Container>
				<Row className="mt-2">
					<Col xl={12}>
						<Label inline>Password</Label>
						<Input
							type="password"
							onChange={(e) => setNewPassword(e.target.value)}
						/>
						{!isValid && (
							<small style={{ color: 'red', display: 'inline-block' }}>
								Password must contain atleast 8 characters consisting of 1
								numeric, uppercase, and lowercase value
							</small>
						)}
					</Col>

					<Col xl={12}>
						<Label inline>Confirm Password</Label>
						<Input
							type="password"
							onChange={(e) => setConfirmNewPassword(e.target.value)}
						/>
						{!isValid && (
							<small style={{ color: 'red', display: 'inline-block' }}>
								Confirm password must match password above
							</small>
						)}
					</Col>
				</Row>
			</Container>
			<ModalFooter>
				<SecondaryButton onClick={confirmPasswordChange} disabled={!isValid}>
					Update password
				</SecondaryButton>
				<DangerButton onClick={props.afterSubmit}>Cancel</DangerButton>
			</ModalFooter>
		</DialogModal>
	);
};

const EditUserForm: React.FC<EditUserFormProps> = (
	props: EditUserFormProps
) => {
	const [editedUser, setEditedUser] = React.useState(props.selectedUser);
	const { roles, canViewByRole } = useRoleContext();
	const { currentUser } = useAuthContext();

	const filterByRole = (role: Role) => {
		console.log('Current User Role', currentUser?.role);
		// If role is under this one
		return (
			!roleIsAtLeast(currentUser?.roleId, role?.roleId) ||
			role?.roleId === currentUser?.roleId
		);
	};

	const onSubmit = () => {
		return props.onSubmit(editedUser);
	};
	const { isReadonly, showChangePassword } = props;
	const [isUpdatingPassword, setIsUpdatingPassword] = React.useState(false);
	const isTrulyReadonly = React.useMemo(() => {
		const callback = () => {
			if (!currentUser?.role) return false;
			if (roleIsAtLeast(UserRole.ClientAdmin, currentUser?.roleId))
				return false;

			return true;
		};
		return callback();
	}, [currentUser]);
	return (
		<>
			{!!showChangePassword && isUpdatingPassword && (
				<ChangePasswordDialog
					afterSubmit={() => setIsUpdatingPassword(false)}
				/>
			)}
			<Row form>
				<Col md={6}>
					<LabeledInput
						readOnly={isTrulyReadonly}
						label="Given Name"
						type="text"
						name="givenName"
						id="givenName"
						value={editedUser.givenName}
						onChange={(e) =>
							setEditedUser({ ...editedUser, givenName: e.target.value })
						}
						required
						inputValid={!!editedUser.givenName}
						errorMsg="First name is required"
					/>
				</Col>

				<Col md={6}>
					<LabeledInput
						label="Family Name"
						type="text"
						name="familyName"
						id="familyName"
						readOnly={isTrulyReadonly}
						value={editedUser.familyName}
						onChange={(e) =>
							setEditedUser({ ...editedUser, familyName: e.target.value })
						}
						required
						inputValid={!!editedUser.familyName}
						errorMsg="Last name is required"
					/>
				</Col>

				<Col md={6}>
					<LabeledInput
						label="Email"
						name="email"
						readOnly={isTrulyReadonly}
						id="userEmail"
						value={editedUser.email}
						onChange={(e) =>
							setEditedUser({ ...editedUser, email: e.target.value })
						}
						required
						inputValid={!!editedUser.email}
						errorMsg="Please enter a valid email"
					/>
				</Col>
			</Row>

			<Row form>
				<Col md={6}>
					<LabeledInput
						label="Title"
						readOnly={isTrulyReadonly}
						type="text"
						name="expertise"
						id="userExpertise"
						value={editedUser.title || editedUser.expertise}
						onChange={(e) => {
							setEditedUser({ ...editedUser, title: e.target.value });
						}}
					/>
				</Col>

				<Col md={6}>
					<LabeledInput
						label="Department"
						type="text"
						name="department"
						readOnly={isTrulyReadonly}
						id="userDepartment"
						value={editedUser.department}
						onChange={(e) => {
							setEditedUser({ ...editedUser, department: e.target.value });
						}}
					/>
				</Col>
			</Row>
			<Label className="text-bold w-100">
				Referral Info <hr />
			</Label>
			<Row form>
				<Col md={6}>
					<LabeledInput
						label="Referrer name"
						type="text"
						name="referenceName"
						id="referenceName"
						readOnly={isTrulyReadonly}
						value={editedUser.referenceName}
						onChange={(e) =>
							setEditedUser({ ...editedUser, referenceName: e.target.value })
						}
					/>
				</Col>
				<Col md={6}>
					<LabeledInput
						label="Referrer e-mail"
						type="text"
						readOnly={isTrulyReadonly}
						name="referenceEmail"
						id="referenceEmail"
						value={editedUser.referenceEmail}
						onChange={(e) =>
							setEditedUser({ ...editedUser, referenceEmail: e.target.value })
						}
					/>
				</Col>
			</Row>

			<Row>
				{props.showRole === false && isReadonly === false && (
					<Col md={6}>
						<UserSelect
							readOnly={isTrulyReadonly}
							id="userStatus"
							name="status"
							label="Status"
							value={editedUser.status}
							onChange={(e) =>
								setEditedUser({ ...editedUser, status: e.target.value })
							}
							options={enumValues(UserStatus)}
						/>
					</Col>
				)}

				{props.showRole && (
					<>
						<Col md={6}>
							<UserSelect
								readOnly={isTrulyReadonly}
								id="userStatus"
								name="status"
								label="Status"
								value={editedUser.status}
								onChange={(e) =>
									setEditedUser({ ...editedUser, status: e.target.value })
								}
								options={enumValues(UserStatus)}
							/>
						</Col>
						<Col md={6}>
							<FormGroup>
								{/* BOOMARK */}
								<UserSelectWithOptions
									id="userRole"
									name="role"
									label="Role"
									readOnly={isTrulyReadonly}
									value={editedUser.roleId.toString()}
									onChange={(e) =>
										setEditedUser({
											...editedUser,
											roleId: parseInt(e.target.value),
										})
									}
									options={roles.filter(filterByRole).map((role) => ({
										option: getUserRoleName(role),
										value: role.roleId.toString(),
									}))}
								/>
							</FormGroup>
						</Col>
					</>
				)}
			</Row>
			{props.showRole && (
				<>
					<Label className="mt-2 text-bold w-100">
						Approval Info <hr />
					</Label>
					<Row form>
						<Col xl={12} className="ml-3">
							<Label check className="w-100">
								<Input
									readOnly={isTrulyReadonly}
									type="checkbox"
									checked={editedUser.isLevelOneApprover}
									onChange={(e) => {
										setEditedUser({
											...editedUser,
											isLevelOneApprover: e.target.checked as boolean,
										});
									}}
								/>
								New User Registrations Approval 1 (Client Admin)
							</Label>

							<Label check className="w-100">
								<Input
									readOnly={isTrulyReadonly}
									type="checkbox"
									checked={editedUser.isLevelTwoApprover}
									onChange={(e) => {
										setEditedUser({
											...editedUser,
											isLevelTwoApprover: e.target.checked as boolean,
										});
									}}
								/>
								New User Registrations Approval 2 (Site Admin)
							</Label>
						</Col>
					</Row>
				</>
			)}
			{isReadonly && (
				<div className={'m-0 float-right'}>
					{canViewByRole('canViewFileSharing') && (
						<SecondaryButton>
							<FontAwesomeIcon icon={faFileUpload} /> &nbsp;
							<span
								onClick={() =>
									navigate(`/admin/filesharing/send/${editedUser?.email}`)
								}
							>
								Share a file
							</span>
						</SecondaryButton>
					)}
					<StyledChangeFormButton>
						<FontAwesomeIcon icon={faEnvelope} />
						&nbsp;
						<a
							className={'text-dark'}
							target={editedUser?._id}
							href={`mailto:${editedUser?.email}`}
						>
							Send an e-mail
						</a>
					</StyledChangeFormButton>
				</div>
			)}
			<ButtonGroup className="float-right">
				{isReadonly === false ||
					(isReadonly === undefined && showChangePassword && (
						<StyledChangeFormButton
							className="mr-2"
							type="button"
							onClick={() => setIsUpdatingPassword(true)}
						>
							<FontAwesomeIcon icon={faLock} /> Update Password
						</StyledChangeFormButton>
					))}
				{isReadonly === false ||
					(isReadonly === undefined && (
						<SubmitButton
							className="ml-2"
							onClick={onSubmit}
							disabled={!editedUser.givenName || !editedUser.familyName}
							label={props.submitLabel || 'Save changes'}
						/>
					))}
			</ButtonGroup>
		</>
	);
};

export default EditUserForm;
