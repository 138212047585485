import {
	faCheckCircle,
	faExclamationCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { navigate } from '@reach/router';
import { UploadResult } from '@uppy/core';
import {
	EntityPropList,
	EntityPropListItem,
} from 'components/entity-details.styled-components';
import ExistingMetadataFields from 'components/metadata/components/existing-metadata-fields.component';
import DialogModal from 'components/modals/dialog-modal.component';
import TagInput from 'components/tag-input/TagInput';
import { useMetadataContext } from 'context/useMetadataContext';
import { pick, uniq, cloneDeep } from 'lodash';
import { castToSnapshot } from 'mobx-state-tree';
import React, { FormEvent, useContext, useEffect, useState } from 'react';
import {
	Col,
	Container,
	ModalFooter,
	Row,
	UncontrolledTooltip,
} from 'reactstrap';
import styled from 'styled-components';
import { Maybe, Nullable } from 'types/globals';
import { toFile } from 'utils/core/FileUploadProvider';
import defaultAsset from '../../../assets/images/default-asset.jpg';
import { UploadContext } from '../../../context/file-upload-provider.component';
import themeStore from '../../../core-ui/models/ThemeStore';
import { doesNameableMatch, generateID } from '../../../utils/common';
import { _logError } from '../../../utils/common/log';
import { SecondaryButton } from '../../buttons.styled-components';
import DownshiftSingleSelect from '../../downshift-select/downshift-single-select.component';
import {
	LabeledInput,
	LabeledSelect,
	StyledLabel,
	SubmitButton,
} from '../../forms';
import { NotificationsContext } from '../../notifications';
import RenderWhen from '../../render-when.component';
import { Link, Subheading } from '../../ui';
import {
	AssetVersion,
	EntityMetadata,
	EntityMetadataTemplate,
} from '../../workflow/workflows/types/workflow.types';
import { extractPreviewURL } from './asset-card.component';
import {
	buildEmptyAssetMetadata,
	useAssetHelper,
} from './helpers/useAssetHelper';
import MultipleAssetPicker from './multiple-asset-picker.component';

export interface AssetUploadDialogProps {
	afterSubmit?: () => void;
}

const AssetsBlock = styled.div`
	cursor: pointer;
	padding: 15px;
	background: #fff;
	font-size: 14px;
	width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	&.selected {
		border-bottom: 5px solid #597f71;
	}
`;

const DefaultAsset = styled.div`
	background: url(${defaultAsset}) center center / cover no-repeat;
	height: 172px;
	position: relative;
	top: 10px;
	margin-top: -1.25em;
`;

const requiredMetadataFields = ['Brand'];

const getMissingMetadataFields = (
	asset: AssetVersion | CustomAssetVersion | undefined
): string[] => {
	if (!asset || !asset.metadata || !asset.metadata._id) return [];

	return requiredMetadataFields.filter(
		(field) =>
			!asset.metadata.fieldOptions?.[field] ||
			!asset.metadata.values?.[field] ||
			asset.metadata.values[field].length === 0
	);
};

const handleInvalidAssetMetadataValues = (
	asset: AssetVersion | CustomAssetVersion | undefined,
	errorCallback: (message: string) => void
): boolean => {
	const missingFields = getMissingMetadataFields(asset);
	if (missingFields.length > 0) {
		errorCallback(`Missing: ${missingFields.join(', ')}`);
		return true;
	}
	return false;
};

type CustomAssetVersion = AssetVersion & {
	file?: File;
};

type PreviewUploadDialogProps = {
	asset: CustomAssetVersion;
	afterSubmit: () => void;
	onSuccess?: (asset: CustomAssetVersion) => void;
};
export const CustomPreviewUploadDialog = (props: PreviewUploadDialogProps) => {
	const { asset, afterSubmit } = props;
	const { error: showError, info } = React.useContext(NotificationsContext);
	const { uploadCustomPreview } = useAssetHelper();
	const [fileToSend, setFileToSend] = React.useState<Maybe<UploadResult>>();

	useEffect(() => {
		if (fileToSend) {
			onSubmit(fileToSend).then(() => console.log('Sent file'));
		}
		//eslint-disable-next-line
	}, [fileToSend]);

	const onSubmit = async (uppyResult: Maybe<UploadResult>) => {
		const file = uppyResult?.successful[0];

		if (!file) {
			setFileToSend(undefined);
			showError('You must select a file.');
			return;
		}
		try {
			const updatedAsset = (await uploadCustomPreview(
				toFile(file),
				asset
			)) as AssetVersion;
			info('Succesfully uploaded custom preview!');
			if (props.onSuccess) {
				props.onSuccess({ ...updatedAsset, file: asset.file });
			}
			props.afterSubmit();
		} catch (error) {
			_logError(error);
			showError(
				'An issue occurred while attempting to upload your file. Please try again later.'
			);
		}
	};

	return (
		<DialogModal
			modalSize="lg"
			header={`Upload custom preview for ${asset.fileName}`}
			onClosed={afterSubmit}
		>
			<Container>
				<Row>
					<Col xl={12}>
						<StyledLabel>
							<p>Select a file that will be used as this assets preview.</p>
							<p>
								After selecting, the preview will be automatically converted.
							</p>
						</StyledLabel>
					</Col>
					<Col xl={12}>
						<MultipleAssetPicker files={[]} onSelect={setFileToSend} />
					</Col>
				</Row>
				<ModalFooter></ModalFooter>
			</Container>
		</DialogModal>
	);
};

function generateInitialAssetFromAssetId(a: File): CustomAssetVersion {
	return {
		file: a,
		previewURL: URL.createObjectURL(a),
		fileName: a.name,
		updatedAt: a.lastModified,
		_id: generateID(),
		fileSizeBytes: a.size,
		metadata: buildEmptyAssetMetadata,
		createdAt: new Date(),
		extractedMetadata: {},
		account: '',
	} as any;
}

export const AssetUploadMetadata = () => {
	const { info, error } = React.useContext(NotificationsContext);
	const { assetIds, clear } = useContext(UploadContext);
	const { templates } = useMetadataContext();
	const [assets, setAssets] = useState<CustomAssetVersion[]>(() =>
		assetIds.map(generateInitialAssetFromAssetId)
	);
	const [selectedFile, setSelectedFile] = useState(assets[0]?.fileName);
	const { uploadNonWorkflowFiles } = useAssetHelper();
	const [uploadingPreview, setUploadingPreview] = React.useState(false);

	useEffect(() => {
		return () => {
			// Clear the list of selected assets when leaving this page
			clear();
		};
	}, []);

	const [savedAssets, setSavedAssets] = useState<CustomAssetVersion[]>([]);
	const [uploadingAsset, setUploadingAsset] = React.useState<
		CustomAssetVersion
	>();
	const afterSubmit = () => {
		setUploadingPreview(false);
		setUploadingAsset(undefined);
	};
	/**
	 * @param event Form event
	 * @param asset Asset Version to be updated
	 * @description updates a single given asset version's metadata on click of update button
	 */
	const updateSingleAssetSubmit = async (
		event?: FormEvent,
		asset?: CustomAssetVersion
	) => {
		event?.preventDefault();
		if (asset) {
			await handleAssetUpdate(asset, [asset]);
		}
		return false;
	};

	/**
	 * @param event Form event
	 * @param asset Asset Version to be updated
	 * @description updates all current assets in state with given asset version's metadata on click of update all button
	 */
	const updateAllAssetsSubmit = async (
		event: FormEvent,
		asset: AssetVersion
	) => {
		event.preventDefault();

		const updatedAssets = assets.map((version) => ({
			...version,
			metadata: cloneDeep(asset.metadata),
			file: version.file,
		}));
		await handleAssetUpdate(asset, updatedAssets);
		return false;
	};

	/**
	 * Helper function to handle the common logic for updating assets.
	 *
	 * @param asset The asset being updated.
	 * @param updatedAssets The list of assets to be saved.
	 */
	const handleAssetUpdate = async (
		asset: CustomAssetVersion,
		updatedAssets: CustomAssetVersion[]
	) => {
		try {
			const snapshot = castToSnapshot({ ...asset }) as CustomAssetVersion;
			setSavedAssets(updatedAssets);
			if (updatedAssets.length === 1) {
				updateAsset({ ...snapshot, file: asset?.file });
				info(`${asset?.file?.name} saved!`);
			} else {
				setAssets(updatedAssets);
				updatedAssets.forEach((version) => info(`${version.fileName} saved!`));
			}
		} catch (ex) {
			_logError(ex);
			error(
				`An issue occurred while updating ${themeStore._.asset.toLowerCase()}. Please try again later.`
			);
		}
	};

	const patchTemplateUsed = async (
		template: Maybe<Nullable<EntityMetadataTemplate>>,
		asset: CustomAssetVersion
	) => {
		window.location.hash = `${template?._id},${asset._id}` as string;
		updateAsset({
			...asset,
			metadata: buildEmptyAssetMetadata as EntityMetadata,
			metadataTemplateUsed: template?._id as string,
		});
		info('Updated asset ' + asset?.file?.name + '.');
	};

	const navigateToDAM = async () => {
		await uploadNonWorkflowFiles(
			savedAssets.map((a) => a.file!),
			savedAssets.map((a) => ({
				title: a.title,
				message: '',
				metadata: pick(
					a.metadata,
					'fields',
					'fieldTypes',
					'fieldOptions',
					'values',
					'tags'
				),
				templateUsed: a.metadataTemplateUsed
					? a.metadataTemplateUsed
					: a.metadata?._id
					? a.metadata?._id
					: undefined,
			}))
		);
		info('Successfully imported the files the DAM');
		window.location.href = '/admin';
	};

	const updateAsset = (asset: CustomAssetVersion) => {
		setAssets(assets.map((a) => (a._id === asset._id ? asset : a)));
	};

	return (
		<Container>
			{uploadingPreview && uploadingAsset && (
				<CustomPreviewUploadDialog
					asset={uploadingAsset}
					afterSubmit={afterSubmit}
					onSuccess={async (asset: CustomAssetVersion) => {
						if (handleInvalidAssetMetadataValues(asset, error)) return;
						await updateSingleAssetSubmit(undefined, asset);
					}}
				/>
			)}
			<Subheading>ASSETS</Subheading>
			<Row>
				<Col md={4}>
					{uniq(assets).map((file) => (
						<Row key={file?.fileName}>
							<AssetsBlock
								className={
									selectedFile === file?.fileName ? 'selected mb-1' : 'mb-1'
								}
								style={{
									textDecoration:
										selectedFile === file?.fileName ? 'underline' : 'none',
								}}
								onClick={() => setSelectedFile(file?.fileName)}
							>
								{file.fileName}
								<FontAwesomeIcon
									className="float-right"
									icon={
										savedAssets.some((a) => a.fileName === file.fileName)
											? faCheckCircle
											: faExclamationCircle
									}
								/>
							</AssetsBlock>
						</Row>
					))}
					<RenderWhen when={!!assets?.length}>
						<Row>
							<Col md={8}>
								<SubmitButton
									id={'importToDam'}
									label="Import to DAM"
									onClick={() => {
										// We check assets vs savedAssets, because saved already get evaluated.
										// This catches when we went from no template, updated, then to a template.
										const hasInvalidAssets = assets.some(
											(asset: CustomAssetVersion) =>
												handleInvalidAssetMetadataValues(asset, error)
										);

										if (!hasInvalidAssets) {
											navigateToDAM();
										}
									}}
									disabled={savedAssets.length !== assets?.length}
								>
									<RenderWhen when={assets?.length !== savedAssets.length}>
										<UncontrolledTooltip target="importToDam">
											You must save all assets to import
										</UncontrolledTooltip>
									</RenderWhen>
								</SubmitButton>
							</Col>
						</Row>
					</RenderWhen>
				</Col>

				<Col md={8}>
					{assets.map((file) => (
						<React.Fragment key={file?._id}>
							<div
								style={{
									display: selectedFile === file?.fileName ? 'block' : 'none',
								}}
							>
								<div className={'col-xl-12 d-flex justify-content-center'}>
									{file.previewURL && (
										<img
											style={{ maxWidth: 300 }}
											src={extractPreviewURL(file)}
											alt={file.fileName}
											className={'img-fluid img-thumbnail'}
										/>
									)}
									{!file.previewURL && (
										<>
											<DefaultAsset />
											<Link
												to=""
												style={{ cursor: 'pointer' }}
												className="link"
												onClick={() => {
													setUploadingAsset(file);
													setUploadingPreview(true);
												}}
											>
												Upload preview
											</Link>
										</>
									)}
								</div>
								<DownshiftSingleSelect
									label={`Metadata Template*`}
									placeholder="Search by name..."
									selectionState={{
										selection: (file?.templateUsed as EntityMetadataTemplate)
											?._id,
										options: [...templates],
										searchPredicate: doesNameableMatch,
									}}
									selectionActions={{
										select: (template) =>
											patchTemplateUsed(
												template as EntityMetadataTemplate,
												file
											),
									}}
								/>
								<EntityPropList>
									<EntityPropListItem>
										<LabeledInput
											label="Display name"
											type="text"
											name="title"
											value={file?.title ? file.title : file.fileName}
											onChange={(event) => {
												const updated = { ...file, title: event.target.value };
												updateAsset(updated);
											}}
										/>
									</EntityPropListItem>
									<EntityPropListItem>
										<LabeledSelect
											id="assetStatus"
											label="Asset Status"
											value={file.archived ? 'true' : 'false'}
											name="archived"
											onChange={(event) => {
												const updated = {
													...file,
													archived: event.target.value === 'true',
												};
												updateAsset(updated);
											}}
										>
											{[
												{ option: 'Archived', value: 'true' },
												{ option: 'Active', value: 'false' },
											].map((opt) => (
												<option key={opt.option} value={opt.value}>
													{opt.option}
												</option>
											))}
										</LabeledSelect>
									</EntityPropListItem>
								</EntityPropList>
								<div className="mt-2">
									<Subheading>Metadata</Subheading>
									<hr />
									<ExistingMetadataFields
										showAddField
										assetId={file._id}
										onChange={(e) => {
											updateAsset({ ...file, metadata: e as EntityMetadata });
											return e as EntityMetadata;
										}}
										metadata={file.metadata}
									/>
									<TagInput
										onChange={(e) => updateAsset({ ...file, metadata: e })}
										metadata={file.metadata}
									/>
								</div>
								<Row>
									<Col md={8}>
										<div className="d-flex align-items-center">
											<RenderWhen when={!!assets?.length && assets?.length > 1}>
												<SecondaryButton
													onClick={async (e: FormEvent) => {
														const existingIdx = assets?.indexOf(file) as number;
														const nextIndex =
															(existingIdx + 1) % assets?.length;
														if (handleInvalidAssetMetadataValues(file, error))
															return false;
														await updateSingleAssetSubmit(e, file);
														setSelectedFile(assets[nextIndex].fileName);
													}}
												>
													Save &amp; Edit Next Asset
												</SecondaryButton>
											</RenderWhen>
											<SecondaryButton
												className="ml-2"
												onClick={(e: FormEvent) => {
													if (handleInvalidAssetMetadataValues(file, error))
														return false;
													updateAllAssetsSubmit(e, file);
												}}
											>
												Update
												<RenderWhen
													when={!!assets?.length && assets?.length > 1}
												>
													&nbsp;All
												</RenderWhen>
											</SecondaryButton>
										</div>
									</Col>
								</Row>
							</div>
						</React.Fragment>
					))}
				</Col>
			</Row>
		</Container>
	);
};
export default AssetUploadMetadata;
