import {
	faCheckCircle,
	faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { StyledLink } from 'components/dam-assets/components/asset-card.styled-components';
import moment from 'moment';
import React, { FormEvent, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
	Alert,
	ButtonGroup,
	Col,
	FormFeedback,
	FormGroup,
	Input,
	Label,
	ModalBody,
	ModalFooter,
	Row,
} from 'reactstrap';
import { Maybe } from 'types/globals';
import { _logError } from 'utils/common/log';
import { useModalCloser } from 'utils/ModalStack';
import { SecondaryButton } from '../../../components/buttons.styled-components';
import { FormSubmitButton } from '../../../components/forms/FormSubmitButton';
import DialogModal from '../../../components/modals/dialog-modal.component';
import { NotificationsContext } from '../../../components/notifications';
import { ProxyRequest, useAuthContext, User } from '../../../utils/auth';

type Props = {
	enabled?: boolean;
	onAuthStateChanged?: Function;
	afterSubmit: () => void;
};

export const ProxyModal = (props: Props) => {
	const {
		entities: all,
		currentUser,
		updateUserProxySettings,
		deleteProxyRequest,
	} = useAuthContext();
	const modalCloser = useModalCloser();
	const [isValid, setIsValid] = React.useState(true);
	const [startDate, setStartDate] = useState<Maybe<Date>>(
		currentUser?.proxyRequest?.proxyStartDate ?? undefined
	);
	const [endDate, setEndDate] = useState<Maybe<Date>>(
		currentUser?.proxyRequest?.proxyEndDate ?? undefined
	);
	const [message, setMessage] = useState(
		currentUser?.proxyRequest?.message ?? ''
	);
	const form = useForm();
	const { info, error: showError } = React.useContext(NotificationsContext);
	const hasValidProxy = (user: User) => {
		const now = new Date();
		const request = user?.proxyRequest as ProxyRequest;
		if (
			request &&
			request.proxyingUser &&
			moment(request.proxyStartDate).isValid() &&
			moment(request.proxyEndDate).isValid()
		)
			return (
				moment(request?.proxyStartDate).isSameOrBefore(now) &&
				moment(request?.proxyEndDate).isSameOrAfter(now) &&
				request.action === 'accepted'
			);
	};
	const clearExisting = async () => {
		try {
			if (currentUser?.proxyRequest?._id) {
				await deleteProxyRequest(currentUser?.proxyRequest?._id);
				info('Successfully cleared user proxy.');
				props.afterSubmit();
			} else {
				showError('Proxy request does not exist.');
			}
		} catch (e) {
			_logError(e);
			showError(
				'Failed to update user proxy settings. Please try again later.'
			);
		}
	};
	const onSubmit = async () => {
		if (!proxiedUser || !startDate || !endDate) {
			setIsValid(false);
			return;
		}

		await updateUserProxySettings(
			{
				proxyStartDate: moment(startDate).format('yyyy-MM-DDThh:mm:ss'),
				proxyEndDate: moment(endDate).format('yyyy-MM-DDThh:mm:ss'),
				proxyingUser: proxiedUser?._id,
				message,
			},
			proxiedUser?._id
		);

		info('Successfully update proxy info!');
		props.afterSubmit();
	};

	const [proxiedUser, setProxiedUser] = React.useState<User>(
		currentUser?.proxyRequest?.proxyingUser as User
	);

	const awaitingApproval = () =>
		moment(endDate).isValid() &&
		moment(startDate).isValid() &&
		!!proxiedUser &&
		!!currentUser?.proxyRequest?._id &&
		currentUser?.proxyRequest?.action !== 'accepted';

	const isEndDateValid = (endDate: string) =>
		moment(endDate).isValid() &&
		moment(endDate).isAfter(moment(form.getValues('startDate')));

	return (
		<DialogModal modalSize={'md'} header="Add user proxy">
			<ModalBody>
				<Row>
					{awaitingApproval() && (
						<Col md={12}>
							<Alert color={'warning'}>
								<FontAwesomeIcon icon={faExclamationTriangle} /> The Proxy
								Request is awaiting approval. If you wish to make a new request,
								you must first delete the
								<StyledLink
									onClick={() => modalCloser.closeModal()}
									to={'/admin/proxies/' + currentUser?.proxyRequest?._id}
								>
									&nbsp;existing request
								</StyledLink>
								.
							</Alert>
						</Col>
					)}
					{hasValidProxy(currentUser) && (
						<Col xl={12}>
							<Alert color={'success'}>
								<FontAwesomeIcon icon={faCheckCircle} /> The Proxy Request is
								currently active. To remove the existing proxy, you must delete
								the
								<StyledLink
									onClick={() => modalCloser.closeModal()}
									to={'/admin/proxies/' + currentUser?.proxyRequest?._id}
								>
									&nbsp;active request
								</StyledLink>
								.
							</Alert>
						</Col>
					)}
					<Col md={12} className="mb-3">
						<label className="d-block" htmlFor="proxiedUserId">
							Proxy User
						</label>
						<select
							disabled={hasValidProxy(currentUser) || awaitingApproval()}
							name="proxiedUserId"
							className="form-control"
							defaultValue={proxiedUser?._id}
							ref={form.register}
							onChange={(event) =>
								setProxiedUser(
									all?.find((m) => m._id === event.target.value) as User
								)
							}
						>
							<option value="">Please select...</option>
							{all
								.filter((user) => user._id !== currentUser?._id)
								.map((user) => (
									<option value={user._id} key={user._id}>
										{`${user.givenName} ${user.familyName}`}
									</option>
								))}
						</select>
					</Col>

					<Col md={6}>
						<FormGroup>
							<Label inline>Proxy Start Date</Label>
							<Input
								disabled={hasValidProxy(currentUser) || awaitingApproval()}
								className="form-control w-100"
								onChange={(e) => {
									const date = e.target.value;
									setStartDate(new Date(date));
								}}
								defaultValue={
									currentUser?.proxyRequest?.proxyStartDate
										? moment(currentUser?.proxyRequest?.proxyStartDate).format(
												'YYYY-MM-DDTHH:mm'
										  )
										: ''
								}
								name="startDate"
								type="datetime-local"
								label="Start Date"
								required
								style={{ padding: awaitingApproval() ? '1px' : '' }}
							/>
						</FormGroup>
					</Col>

					<Col md={6}>
						<FormGroup>
							<Label inline>Proxy End Date</Label>

							<Input
								disabled={hasValidProxy(currentUser) || awaitingApproval()}
								className="form-control w-100"
								onChange={(e) => {
									const date = e.target.value;
									setEndDate(new Date(date));
								}}
								defaultValue={
									currentUser?.proxyRequest?.proxyEndDate
										? moment(currentUser?.proxyRequest?.proxyEndDate).format(
												'YYYY-MM-DDTHH:mm'
										  )
										: ''
								}
								type="datetime-local"
								name="endDate"
								label="End Date"
								validate={{ 'Must Be After Start Date': isEndDateValid }}
								required
								style={{ padding: awaitingApproval() ? '1px' : '' }}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					<Col xl={12}>
						<Label inline>Requestor Notes</Label>
						<Input
							disabled={hasValidProxy(currentUser) || awaitingApproval()}
							type={'textarea'}
							defaultValue={message}
							onChange={(e) => {
								const value = e.target?.value;
								setMessage(value);
							}}
							placeholder={'Notes about the request...'}
						/>
					</Col>
				</Row>
				<Row>
					<Col xl={12}>
						{isValid === false && (
							<FormFeedback className="d-block" invalid="true">
								All fields are required in order to submit a proxy request.
							</FormFeedback>
						)}
					</Col>
				</Row>
			</ModalBody>
			<ModalFooter>
				<ButtonGroup>
					<FormSubmitButton
						disabled={hasValidProxy(currentUser) || awaitingApproval()}
						onClick={async () => await onSubmit()}
						type="submit"
					>
						Add Proxy
					</FormSubmitButton>
					{awaitingApproval() ||
						(hasValidProxy(currentUser) && (
							<SecondaryButton
								className="ml-2"
								onClick={async (e: FormEvent) => {
									e.preventDefault();
									e.stopPropagation();
									await clearExisting();
								}}
							>
								Clear Proxy
							</SecondaryButton>
						))}
					<SecondaryButton
						className="ml-2"
						type="button"
						onClick={props.afterSubmit}
					>
						Cancel
					</SecondaryButton>
				</ButtonGroup>
			</ModalFooter>
		</DialogModal>
	);
};
