import { faTag } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { capitalizeFirstLetter } from '../../../../utils/common';
import {
	EntityPropLabel,
	EntityPropListItem,
	TagBadge,
} from '../../../entity-details.styled-components';
import { EntityMetadata } from '../../../workflow/workflows/types/workflow.types';

export enum SortMetadataBy {
	SortByField = 'sortByField',
	SortByValue = 'sortByValue',
}

const AssetMetadataProps = ({
	metadata,
	sortBy = SortMetadataBy.SortByField,
}: {
	metadata: EntityMetadata;
	sortBy?: SortMetadataBy;
}) => {
	const metadataFields = getMetadataFields(metadata, sortBy);

	return (
		<>
			{metadataFields}
			{metadata?.tags?.length ? (
				<EntityPropListItem>
					<EntityPropLabel>
						<FontAwesomeIcon icon={faTag} /> Tags
					</EntityPropLabel>
					{metadata?.tags?.length ? renderTags(metadata.tags) : null}
				</EntityPropListItem>
			) : null}
		</>
	);
};

const renderField = (field: string, value: any) => (
	<EntityPropListItem key={field}>
		<EntityPropLabel>{capitalizeFirstLetter(field)}</EntityPropLabel>
		<p>{Array.isArray(value) ? value.join(', ') : value}</p>
	</EntityPropListItem>
);

const renderTags = (tags: string[]) => (
	<EntityPropListItem>
		<EntityPropLabel>
			<FontAwesomeIcon icon={faTag} /> Tags
		</EntityPropLabel>
		{tags.map((tag: any) => (
			<TagBadge key={tag}>{tag}</TagBadge>
		))}
	</EntityPropListItem>
);

const sortFieldsByValue = (fields: string[]) => {
	return fields.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));
};

const getMetadataFields = (
	metadata: EntityMetadata,
	sortBy: SortMetadataBy
): JSX.Element[] => {
	if (!metadata?.values || !metadata?.fields) {
		return [];
	}

	let sortedFields =
		sortBy === SortMetadataBy.SortByValue
			? sortFieldsByValue(metadata.fields)
			: [...metadata.fields];

	return sortedFields
		.filter((field) => metadata.values?.[field] !== undefined)
		.map((field: string) => renderField(field, metadata.values?.[field]));
};

export default AssetMetadataProps;
