import {
	faCheck,
	faPencilAlt,
	faSpinner,
	faTimes,
	faTimesCircle,
	faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UserGroup } from 'components/accounts/types';
import { RomeSwal } from 'components/alert';
import RenderWhen from 'components/render-when.component';
import { useWorkflowContext } from 'context/useWorkflowStore';
import { flattenDeep, isArray, uniqBy } from 'lodash';
import React, { useState } from 'react';
import { Input, UncontrolledTooltip } from 'reactstrap';
import { useAuthContext, useGroupContext, User } from 'utils/auth';
import themeStore from '../../../../../core-ui/models/ThemeStore';
import { humanReadableFileSizeSI } from '../../../../../utils/common';
import {
	Creatable,
	getCreationDateStr,
} from '../../../../../utils/models/CreatableEntityModel';
import { CollaboratorAvatarLg, SmallText } from '../../../../ui';
import {
	flattenStages,
	getStageEventAsString,
} from '../../helpers/workflowStage.helpers';
import {
	AssetVersion,
	InputSlot,
	Stage,
	StageEvent,
	Workflow,
} from '../../types/workflow.types';
import { workflowRoute } from '../../workflow-routes';
import {
	AssetVersionLink,
	EventDetails,
	MessageContainer,
	StageTitle,
	TimelineItem,
	TimelineList,
} from './timeline.styled-components';

type AssetUploadEvent = {
	type: 'assetUpload';
	slot: InputSlot;
	version: AssetVersion;
	fileName: string;
};
interface AssetLinkProps {
	event: AssetUploadEvent;
}

interface TimelineLogProps {
	event: StageEvent;
	displayStageTitle?: boolean;
	workflow?: Workflow;
	createdBy?: User | string | UserGroup;
	showDelete?: boolean;
}

interface TimelineProps {
	events: Array<StageEvent>;
	displayStageTitle?: boolean;
	workflow?: Workflow;
	showDelete?: boolean;
}

const workflowContainsVersion = (event: StageEvent, workflow: Workflow) =>
	event?.type === 'assetUpload' &&
	((!!event?.version?.hasOwnProperty('_id') ||
		flattenStages(workflow)?.some((stage) =>
			stage.substages && stage.substages?.length
				? flattenDeep(stage.substages).some(
						(sub) =>
							sub.events?.some((evt) => evt.type === 'assetUpload') ||
							sub.inputSlots?.some((slot) =>
								slot?.versions?.some((version) => version._id === event.version)
							)
				  )
				: stage?.events?.some((evt) => evt.type === 'assetUpload') &&
				  stage?.inputSlots?.some((slot) =>
						slot?.versions?.some((version) => version._id === event.version)
				  )
		)) as boolean);

const findVersion = (workflow: Workflow, event: StageEvent) => {
	return event?.version?.hasOwnProperty('_id')
		? (event?.version as AssetVersion)
		: flattenStages(workflow)
				?.flatMap((s) => s)
				?.find((stage) =>
					isArray(stage)
						? !!stage.some(
								(sub) =>
									sub.events?.some(
										(evt: StageEvent) => evt.type === 'assetUpload'
									) &&
									sub.inputSlots?.some((slot: InputSlot) =>
										slot?.versions?.some(
											(version: AssetVersion) => version?._id === event?.version
										)
									)
						  )
						: stage?.events?.some((evt) => evt.type === 'assetUpload') &&
						  !!stage?.inputSlots?.some((slot) =>
								slot?.versions?.some(
									(version) => version?._id === event?.version
								)
						  )
				)
				?.inputSlots?.find((slot: InputSlot) =>
					slot?.versions?.some(
						(version: AssetVersion) => version._id === event?.version
					)
				)
				?.versions?.find((version) => version?._id === event?.version);
};
const AssetLink = ({ event }: { event: StageEvent }) => {
	const { workflow } = useWorkflowContext();

	if (
		humanReadableFileSizeSI(
			event?.version?.hasOwnProperty('_id')
				? (event?.version as AssetVersion)?.fileSizeBytes
				: findVersion(workflow as Workflow, event)?.fileSizeBytes
		) !== ''
	)
		return (
			<AssetVersionLink
				to={`${workflowRoute(workflow as Workflow)}/assets/${
					event?.version?.hasOwnProperty('_id')
						? (event?.version as AssetVersion)?._id
						: event?.version
				}`}
			>
				uploaded {event?.fileName} (
				{humanReadableFileSizeSI(
					event?.version?.hasOwnProperty('_id')
						? (event?.version as AssetVersion)?.fileSizeBytes
						: findVersion(workflow as Workflow, event)?.fileSizeBytes
				)}
				)
			</AssetVersionLink>
		);
	else {
		return (
			<span>
				uploaded {event?.fileName} (
				{humanReadableFileSizeSI(
					event?.version?.hasOwnProperty('_id')
						? (event?.version as AssetVersion)?.fileSizeBytes
						: findVersion(workflow as Workflow, event)?.fileSizeBytes
				)}
				)
			</span>
		);
	}
};
export const TimelineLog = (props: TimelineLogProps) => {
	const { entities: users, currentUser } = useAuthContext();
	const { entities: groups } = useGroupContext();
	const { deleteComment, updateComment } = useWorkflowContext();
	const { event, displayStageTitle, showDelete, workflow, createdBy } = props;
	const [showEdit, setShowEdit] = useState(false);
	const [newCommentText, setNewCommentText] = useState(event.message);
	const [showDeleteBtn, setShowDeleteBtn] = useState(false);

	const initialValue = () => {
		if (typeof createdBy === 'string') {
			if (
				users !== undefined &&
				users !== null &&
				users.find((m) => m._id === createdBy) !== undefined
			)
				return users?.find((m) => m._id === createdBy) as User;
		} else if (
			(createdBy as User) &&
			(createdBy as User).hasOwnProperty('givenName')
		) {
			return createdBy as User;
		}
	};
	const [createdByUser] = useState<User>(initialValue() as User);

	const isUploadEvent = event?.type === 'assetUpload' || !!event?.version;

	const renderEventCreatedBy = () => {
		const createdByUser = initialValue();
		if (users && groups) {
			return (
				<div>
					{`${createdByUser?.givenName} ${createdByUser?.familyName} `}
					{getStageEventAsString(
						{
							...event,
							createdBy: createdByUser,
						},
						(event?.removed || [])?.map((removed) =>
							removed.type === 'AccountGroup'
								? groups.find((m) => m._id === removed._id)
								: (users.find((m) => m._id === removed._id) as User | UserGroup)
						) as (User | UserGroup)[],
						(event?.added || [])?.map((added) =>
							added.type === 'AccountGroup'
								? groups.find((m) => m._id === added._id)
								: (users.find((m) => m._id === added._id) as User | UserGroup)
						) as (User | UserGroup)[],
						flattenStages(workflow as Workflow)?.find(
							(stage) => stage._id === event?.stageId
						)
					)}
					{event && event.message && !showEdit ? (
						<span className="text-dark d-block">
							{event?.message?.includes('bypass')
								? event.message.replace('bypass', '')
								: event.message}
						</span>
					) : !!event && !!event.message && !!showEdit ? (
						<Input
							style={{ width: 300 }}
							type={'textarea'}
							defaultValue={event.message}
							onChange={(e) => setNewCommentText(e.target.value)}
						/>
					) : (
						''
					)}
				</div>
			);
		}
	};

	const workflowCompletedComment = () => {
		if (event.newStatus === 'completed') {
			const stage = getStage();
			return (
				<div>
					<span className="text-dark d-block">
						{stage?.final
							? 'Workflow completed'
							: event?.message?.includes('bypass')
							? stage?.title + ':\t Bypassed'
							: stage?.title + ':\t Completed'}
					</span>
				</div>
			);
		}
	};

	const getStage = () => {
		const stage = flattenStages(workflow as Workflow)?.find(
			(stage: Stage[] | Stage) => {
				if (Array.isArray(stage))
					return stage.find((stg) =>
						stg.events?.some((evt) => evt._id === event._id)
					)?.title;
				return stage.events?.some((evt) => evt._id === event._id);
			}
		) as Stage;
		return stage;
	};
	const getStageTitle = () => {
		const stage = flattenStages(workflow as Workflow)?.find(
			(stage: Stage[] | Stage) => {
				if (Array.isArray(stage))
					return stage.find((stg) =>
						stg.events?.some((evt) => evt._id === event._id)
					)?.title;
				return stage.events?.some((evt) => evt._id === event._id);
			}
		) as Stage;
		return stage?.title;
	};

	const updateCommentCallBack = async () => {
		if (!newCommentText) {
			RomeSwal.fire({
				title: 'Please input a comment to update',
				icon: 'warning',
			});
			return;
		}
		const success = await updateComment(
			event._id,
			workflow?._id as string,
			getStage()?._id,
			newCommentText
		);
		RomeSwal.fire({
			title: success
				? 'Successfully updated comment'
				: 'Failed to delete comment',
			text: success
				? 'Updated the comment within the workflow ' +
				  workflow?.title +
				  ' succesfully.'
				: 'An error occurred trying to update the comment within the workflow. Please try again.',
			icon: success ? 'success' : 'error',
		});
		setShowEdit(false);
	};

	return (
		<>
			{users && groups && createdBy ? (
				<TimelineItem
					onMouseOver={() => {
						if (event.type === 'comment' && !event.disabled)
							setShowDeleteBtn(true);
					}}
					onMouseLeave={() => setShowDeleteBtn(false)}
				>
					<article className="">
						{displayStageTitle ? (
							<StageTitle>
								{themeStore._.stage}: {getStageTitle()}
							</StageTitle>
						) : null}
						<EventDetails className="text-muted">
							<CollaboratorAvatarLg user={initialValue()} />
							<MessageContainer
								className="message-container cy-wf-message-container"
								style={{ paddingLeft: 12.5 }}
							>
								{renderEventCreatedBy()}
								{workflowCompletedComment()}
								{isUploadEvent ? <AssetLink event={event} /> : null}
								<SmallText className="my-1">
									{getCreationDateStr((event as any) as Creatable)}{' '}
									{event.updatedAt &&
										`/${new Date(event.updatedAt).toLocaleString()}`}
								</SmallText>
								{event.disabled && (
									<span className="d-block">
										<small className="text-danger">
											<FontAwesomeIcon className="text-danger" icon={faTrash} />{' '}
											Comment deleted by{' '}
											{typeof event.disabledBy === 'object' &&
												`${event.disabledBy?.givenName} ${event.disabledBy?.familyName}`}
											{typeof event.disabledBy === 'string' &&
												`${
													users?.find(
														(u: User) => u._id === (event.disabledBy as string)
													)?.givenName
												}


                                            ${
																							users?.find(
																								(u: User) =>
																									u._id ===
																									(event.disabledBy as string)
																							)?.familyName
																						}`}
										</small>
									</span>
								)}
							</MessageContainer>
						</EventDetails>
						{!!showEdit && (
							<div
								className="position-relative"
								style={{ bottom: 100, left: 300, width: 50 }}
							>
								<UncontrolledTooltip target={'update_comment_button'}>
									Update comment
								</UncontrolledTooltip>
								<UncontrolledTooltip target={'cancel_update_comment_button'}>
									Cancel
								</UncontrolledTooltip>

								<FontAwesomeIcon
									id={'update_comment_button'}
									style={{ marginRight: 15, fontSize: 20, cursor: 'pointer' }}
									icon={faCheck}
									onClick={updateCommentCallBack}
								/>
								<FontAwesomeIcon
									id={'cancel_update_comment_button'}
									style={{ fontSize: 20, cursor: 'pointer' }}
									icon={faTimes}
									onClick={() => setShowEdit(false)}
								/>
							</div>
						)}
						{createdByUser?._id === currentUser?._id &&
							!showEdit &&
							showDeleteBtn &&
							event.type === 'comment' &&
							!event.disabled &&
							showDelete && (
								<>
									<UncontrolledTooltip target={'delete_comment_button'}>
										Delete comment
									</UncontrolledTooltip>
									<UncontrolledTooltip target={'edit_comment_button'}>
										Edit comment
									</UncontrolledTooltip>

									<FontAwesomeIcon
										id={'delete_comment_button'}
										className="text-danger"
										style={{
											float: 'right',
											color: 'red',
											fontSize: 18,
											cursor: 'pointer',
										}}
										onClick={async () => {
											const result = await RomeSwal.fire({
												icon: 'question',
												title: 'Are you sure?',
												text: 'Deleting this comment cannot be reversed.',
												confirmButtonText: 'Delete',
												showCancelButton: true,
											});
											if (result.isConfirmed) {
												await deleteComment(
													event._id,
													workflow?._id as string,
													getStage()?._id
													// @ts-ignore
												).then((success) => {
													RomeSwal.fire({
														toast: true,
														timerProgressBar: true,
														position: 'center',
														timer: 3000,
														title: success
															? 'Successfully deleted comment'
															: 'Failed to delete comment',
														text: success
															? 'Deleted the comment from the workflow ' +
															  workflow?.title +
															  ' succesfully.'
															: 'An error occurred trying to delete the comment from the workflow. Please try again.',
														icon: success ? 'success' : 'error',
													});
												});
											}
										}}
										icon={faTimesCircle}
									/>
									<FontAwesomeIcon
										id={'edit_comment_button'}
										className="text-danger"
										style={{
											float: 'right',
											fontSize: 18,
											cursor: 'pointer',
											marginRight: 15,
										}}
										onClick={async () => {
											setShowEdit(true);
										}}
										icon={faPencilAlt}
									/>
								</>
							)}
					</article>
				</TimelineItem>
			) : (
				<article className="">
					<FontAwesomeIcon className={'fa-spin'} icon={faSpinner} />
				</article>
			)}
		</>
	);
};

export const Timeline = (props: TimelineProps) => {
	const { events, displayStageTitle, workflow, showDelete } = props;

	// TODO: Refer to ticket #1960 (https://dev.azure.com/waadevops/AppDev/_boards/board/t/AppDev%20Team/Stories/?workitem=1960)
	// This will fix a UI bug where the timeline log compounds with fluff comments. This is caused by stages having
	// duplicate events (by mongo ObjectId). It seems to happen when rejecting a completed stage. We need to find
	// why we are saving duplicate events to stages in the db when rejectin a completed stage.
	const dedupedEvents = uniqBy(events, '_id');
	return (
		<TimelineList>
			{dedupedEvents.map((event: StageEvent) => {
				return (
					<TimelineLog
						showDelete={showDelete}
						createdBy={event?.createdBy}
						workflow={workflow as Workflow}
						// @ts-ignore
						key={event?._id ?? event?.createdBy}
						event={event}
						displayStageTitle={displayStageTitle}
					/>
				);
			})}
		</TimelineList>
	);
};
