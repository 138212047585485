import { faMailBulk, faRedoAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UploadResult, UppyFile } from '@uppy/core';
import { SecondaryButton } from 'components/buttons.styled-components';
import MultipleAssetPicker from 'components/dam-assets/components/multiple-asset-picker.component';
import { Loading } from 'components/index';
import { NotificationsContext } from 'components/notifications';
import EmailInput from 'components/tag-input/EmailInput';
import { useFileShare } from 'context/useFileShare';
import moment from 'moment';
import { StyledChangeFormButton } from 'pages/views/Register/components';
import React from 'react';
import {
	ButtonToolbar,
	Card,
	CardBody,
	CardFooter,
	CardHeader,
	Col,
	FormGroup,
	Input,
	Label,
	Row,
} from 'reactstrap';
import { Maybe } from 'types/globals';
import { toFile } from 'utils/core/FileUploadProvider';

const FileSharingSend = ({ recipient_email }: { recipient_email?: string }) => {
	const defaultDaysValid = 90;
	let defaultExpires = new Date();
	defaultExpires.setDate(defaultExpires.getDate() + defaultDaysValid);

	const { shareFiles } = useFileShare();
	const { info } = React.useContext(NotificationsContext);
	const [to, setTo] = React.useState<string[]>(
		recipient_email ? [recipient_email] : []
	);
	const [subject, setSubject] = React.useState('');
	const [message, setMessage] = React.useState('');
	const [uppyResult, setResult] = React.useState<Maybe<UploadResult>>(
		undefined
	);
	const [sendReceipt, setSendReceipt] = React.useState(false);
	const [useCc, setUseCc] = React.useState(false);
	const [isSending, setIsSending] = React.useState(false);
	const [validUntil, setValidUntil] = React.useState<string>(
		defaultExpires?.toISOString()
	);

	const [reset, setReset] = React.useState<boolean>(false);

	const resetForm = () => {
		setSendReceipt(false);
		setMessage('');
		setSubject('');
		setUseCc(false);
		setTo([]);
		setResult(undefined);
		setValidUntil(defaultExpires.toISOString());
		setReset(true);
	};

	const preparePayload = async () => {
		setIsSending(true);
		const attachments: File[] = [];
		uppyResult?.successful.forEach((file) => {
			const fileData = toFile(file);
			attachments.push(fileData);
		});
		const payload = {
			subject,
			to,
			message,
			sendReceipt,
			validUntil,
			useCc,
		};
		await shareFiles(payload, attachments).finally(() => {
			info('Successfully shared files!');
			resetForm();
			setIsSending(false);
		});
	};

	const handleDateChange = (date: Date | null) => {
		const newValidDate = date
			? date.toString()
			: new Date(defaultExpires).toLocaleDateString();
		setValidUntil(newValidDate);
	};

	return (
		<>
			{isSending && (
				<Loading
					label="Sending files..."
					alignItems="center"
					justifyContent="center"
				/>
			)}

			{isSending === false && (
				<Card>
					<CardHeader>Send Files</CardHeader>
					<CardBody>
						<div className="px-2">
							<Row>
								<Col sm={12}>
									<FormGroup>
										<EmailInput
											emails={to}
											onChange={(updated) => setTo(updated)}
										/>
									</FormGroup>
									<FormGroup className="text-left ml-4">
										<Label check>
											<Input
												checked={sendReceipt}
												type="checkbox"
												onChange={(e) => setSendReceipt(e.target.checked)}
											/>
											Send me a receipt
										</Label>
									</FormGroup>
									<FormGroup className="text-left ml-4">
										<Label check>
											<Input
												checked={useCc}
												type="checkbox"
												onChange={(e) => setUseCc(e.target.checked)}
											/>
											Show copied users
										</Label>
									</FormGroup>
								</Col>
							</Row>

							<Row className="align-items-center">
								<Col xl={7}>
									<FormGroup>
										<Label>Subject</Label>
										<Input
											placeholder={'Subject'}
											value={subject}
											type="text"
											onChange={(e) => setSubject(e.target.value)}
										/>
									</FormGroup>
								</Col>

								<Col xl={5} className="col-sm-5">
									<FormGroup>
										<Label>Valid Until</Label>
										<Input
											type={'datetime-local'}
											locale={'en'}
											value={moment(new Date(validUntil)).format(
												'YYYY-MM-DDTHH:mm:ss'
											)}
											onChange={(e: any) => handleDateChange(e.target.value)}
										/>
									</FormGroup>
								</Col>
							</Row>

							<Row>
								<Col>
									<FormGroup>
										<Label>Message</Label>
										<Input
											value={message}
											placeholder={'Enter a message here...'}
											type="textarea"
											aria-rowcount={9}
											onChange={(e) => setMessage(e.target.value)}
										/>
									</FormGroup>
								</Col>
							</Row>

							<Row>
								<Col>
									<FormGroup>
										<Label>Files to Send</Label>
										<MultipleAssetPicker
											files={uppyResult?.successful as UppyFile[]}
											onSelect={setResult}
											reset={reset}
											setReset={setReset}
											id={'fileSharing'}
										/>
									</FormGroup>
								</Col>
							</Row>
						</div>
					</CardBody>
					<CardFooter>
						<ButtonToolbar className="align-items-baseline">
							<SecondaryButton onClick={preparePayload}>
								<FontAwesomeIcon icon={faMailBulk} />
								&nbsp;Send
							</SecondaryButton>
							<StyledChangeFormButton className="ml-2" onClick={resetForm}>
								<FontAwesomeIcon icon={faRedoAlt} />
								&nbsp;Reset
							</StyledChangeFormButton>
						</ButtonToolbar>
					</CardFooter>
				</Card>
			)}
		</>
	);
};
export default FileSharingSend;
