import { SiteDefaults } from 'context/useThemeContext';
import { useAuthContext } from 'utils/auth';
import React, { useState } from 'react';
import { Card, CardBody, CardHeader, Col, FormText, Row } from 'reactstrap';
import { _logError } from '../../../utils/common/log';
import { NotificationsContext } from '../../../components/notifications';

import { RegisterForm, RegisterFormData, StyledLogo } from './components';

export type RegisteredUser = {
	givenName: string;
	familyName: string;
	email: string;
	company: string;
	title: string;
	department: string;
	addressLine1: string;
	addressLine2?: string;
	city: string;
	state: string;
	zip: string;
	country: string;
	phone: string;
	referenceName: string;
	referencePhone: string;
	referenceEmail: string;
};

type Props = {
	defaults: SiteDefaults;
	setLoggingIn: () => void;
};

export const Register = ({ setLoggingIn, defaults }: Props) => {
	const { info, error } = React.useContext(NotificationsContext);
	const { registerUser } = useAuthContext();
	const [hasRegistered, setHasRegistered] = useState(false);

	const onRegister = async (formData: RegisterFormData) => {
		const { password, confirmPassword, ...user } = formData;
		try {
			var result = await registerUser(user, password);
			if (!result) {
				error(
					'Sorry, an issue occurred while registering the user. Please try again later.'
				);
			} else {
				setHasRegistered(result);
				info('Successfully registered user.');
			}
		} catch (err) {
			// @ts-ignore
			error(
				err?.message
					? err.message
					: 'Failed to register user. Please try again later'
			);
			_logError(err);
		}
	};

	return (
		<Row className="w-50 m-auto justify-content-center mt-2">
			<Col>
				<Card className="mt-2">
					<CardHeader>
						<div className="d-flex justify-content-center">
							<StyledLogo />
						</div>
						<h2>
							{!hasRegistered
								? 'Register For An Account'
								: 'Congratulations! You have registered for a Rome Account'}
						</h2>
						<FormText style={{ display: 'block' }}>
							{!hasRegistered
								? 'After Registering, you will need to be approved.  Upon approval, a confirmation email will be sent to your Email.'
								: 'You will Receive an email with further instructions shortly'}
						</FormText>
					</CardHeader>

					<CardBody>
						<div className="text-center text-muted mb-4"></div>

						{/* Register Form */}
						{!hasRegistered && (
							<RegisterForm
								defaults={defaults}
								onSubmit={onRegister}
								setLoggingIn={setLoggingIn}
							/>
						)}
					</CardBody>
				</Card>
			</Col>
		</Row>
	);
};
