import React, { useState } from 'react';
import { Card, Nav } from 'reactstrap';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

import {
	TabProps,
	TabsetButton,
	TabsetContent,
} from '../../../tabset.component';

import { TabNavItem } from './asset-details-tab-set.styled-components';
import InfoPanel from './info-panel.component';
import {
	AssetVersion,
	Workflow,
} from '../../../workflow/workflows/types/workflow.types';

export interface AssetTabsProps {
	asset: AssetVersion;
	collectionId?: string;
	damAsset?: AssetVersion;
	wf: Workflow;
	tabs: Readonly<TabProps<AssetTabsProps>[]>;
	onDelete?: () => void;
	onRemoveFromCollection?: () => void;
	workflowOwner?: string;
	isAssetVersion?: boolean;
	isEditedByCreatorOnly?: boolean;
}

const infoTab = {
	tabId: 'infoTab',
	label: 'Info',
	icon: faInfoCircle,
	content: InfoPanel,
};

export const DetailsTabs: Readonly<TabProps<AssetTabsProps>[]> = [infoTab];

// component
const AssetDetailsTabSet = (props: AssetTabsProps) => {
	const [activeTab, setActiveTab] = useState(props.tabs[0].tabId);
	const renderTabButton = (tabProps: TabProps) => (
		<TabNavItem key={tabProps.tabId}>
			<TabsetButton
				activeTab={activeTab}
				selectTab={setActiveTab}
				{...tabProps}
			/>
		</TabNavItem>
	);

	const renderTabContent = (tabProps: TabProps<AssetTabsProps>) => (
		<TabsetContent
			key={tabProps.tabId}
			activeTab={activeTab}
			selectTab={setActiveTab}
			{...tabProps}
		>
			{tabProps.content(props)}
		</TabsetContent>
	);

	return (
		<Card>
			<Nav tabs>{props.tabs.map(renderTabButton)}</Nav>
			{props.tabs.map(renderTabContent)}
		</Card>
	);
};

export default AssetDetailsTabSet;
