import { saveNode } from 'utils/common';
import { PageSubheading } from '../../components/index';
import BackLink from 'components/back-link/back-link.component';
import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import styled from 'styled-components';
const color = '#ddd',
	width = 125,
	height = width * 0.6,
	circle_size = width / 6 + 'px',
	boxshadow = '0 0 .55rem  rgba(17,17,17,0.5),0 0 .55rem rgba(17,17,17,0.5)',
	animation_time = '1.5s',
	animation_delay = '0.5s',
	bubbleHeight = height / 6 + 'px',
	bubbleHeightHalf = height / 3 + 'px';
const SuccessWrapper = styled.div`
	display: flex;
	min-height: 100vh;
	align-items: center;
	justify-content: center;
	background-color: #fbfcf7;
	@keyframes fade_in_out {
		0% {
			opacity: 1;
		}
		50% {
			opacity: 0.25;
		}
		100% {
			opacity: 1;
		}
	}

	.bubble {
		display: flex;
		position: relative;
		width: ${width}px;
		height: ${height}px;
		align-items: center;
		justify-content: space-around;

		border-radius: ${height}px;
		background-color: ${color};

		&::before,
		&::after {
			position: absolute;
			content: '';

			background: ${color};
			border-radius: 100%;
		}

		&::before {
			bottom: 0;
			left: -6px;
			width: ${bubbleHeightHalf};
			height: ${bubbleHeightHalf};
		}

		&::after {
			bottom: -5px;
			left: -18px;
			width: ${bubbleHeight};
			height: ${bubbleHeight};
		}
	}

	.bubble__circle {
		width: ${circle_size};
		height: ${circle_size};

		border-radius: 100%;
		background-color: #bbb;

		animation: fade_in_out ${animation_time} infinite;
		animation-delay: ${animation_delay} / 2;

		&:first-of-type {
			margin-left: ${circle_size};

			animation-delay: 0s;
		}

		&:last-of-type {
			margin-right: ${circle_size};

			animation-delay: ${animation_delay};
		}
	}
`;

export const AwaitingApproval = () => {
	// reset the users auth token status, before navigating away,
	// seeing as they aren't technically `authorized` until they are invited user status
	window.onbeforeunload = () => {
		saveNode('rome_auth', {
			accessToken: null,
			expiresAt: -1,
			idToken: null,
			authUser: null,
		});
	};
	return (
		<Container>
			<SuccessWrapper>
				<Row>
					<Col md={3}>
						<div className="bubble">
							<div className="bubble__circle"></div>
							<div className="bubble__circle"></div>

							<div className="bubble__circle"></div>
						</div>
					</Col>
					<Col>
						<div className="position-relative">
							<h1>AWAITING APPROVAL</h1>
							<PageSubheading text="ALMOST! BEFORE YOU CAN ACCESS ROME, YOU NEED ADDITIONAL APPROVAL." />
						</div>
						<Row>
							<BackLink link="/auth" title="login" />
						</Row>
					</Col>
				</Row>
			</SuccessWrapper>
		</Container>
	);
};
