import { RouteComponentProps, Router } from '@reach/router';
import { useAxios } from 'hooks';
import { useFetching } from 'hooks/useFetching';
import { flattenDeep, isArray, uniqBy } from 'lodash';
import React, { ReactElement } from 'react';
import { Col, Container, Nav, Row } from 'reactstrap';
import BackLink from '../../back-link/back-link.component';
import { LoadingRow } from '../../loading.component';
import OwnerAvatarList from '../../owner-avatar-list.component';
import WorkflowHealthFlag from '../../dashboard.component/components/workflow-health-flag.component';
import themeStore from '../../../core-ui/models/ThemeStore';
import AccessLogsPanel from './components/workflow-details-tab-set/access-logs-panel.component';
import AssetsPanel from './components/workflow-details-tab-set/assets-tab-panel.component';
import WorkflowDetailPanel from './components/workflow-details-tab-set/details-panel.component';
import {
	Link,
	WorkflowTitle,
} from './components/workflow-details.styled-components';
import StageIndexPanel from './components/workflow-stage-details/workflow-stage-index-panel.component';
import { flattenStages } from './helpers/workflowStage.helpers';
import { useWorkflowContext } from '../../../context/useWorkflowStore';
import { SKUDetail } from './SKUDetail';
import { SKUPanel } from './SKUPanel.component';
import {
	BaseWorkflowOwner,
	InputSlot,
	SKUMetadata,
	StageEvent,
	Workflow,
	WorkflowTemplate,
} from './types/workflow.types';
import WorkflowAssetVersionDetailsView from './workflow-asset-version-details.view';
import { SelectedWorkflow } from './workflow.contexts';
import { WorkflowFollowersPanel } from './WorkflowFollowersPanel';

const Assets = ({ children }: { children: ReactElement[] }) => <>{children}</>;

interface WorkflowDetailsViewProps extends RouteComponentProps {
	workflowId: string;
}

function wfDetailsReducer(
	state: {
		workflow: Workflow | null;
	},
	action: { type: 'set' | 'update'; payload: Workflow }
) {
	switch (action.type) {
		case 'set':
			return { ...state, workflow: action.payload };
		case 'update':
			return { ...state, workflow: action.payload };
		default:
			return state;
	}
}
export const RouterGroup = ({ children }: any) => <>{children}</>;

const WorkflowDetailsView = (props: WorkflowDetailsViewProps) => {
	const { findOne } = useAxios<Workflow>('workflows');
	const { workflow, setWorkflow } = useWorkflowContext();
	const workflowTerm = themeStore._.workflow;
	const [state, dispatchWorkflow] = React.useReducer(wfDetailsReducer, {
		workflow: null,
	});
	const {
		isFetching: isLoading,
		beginFetching: startLoad,
		finishFetching: finishLoad,
	} = useFetching();

	React.useEffect(() => {
		if (isLoading) return;
		if (!state.workflow) {
			startLoad();
			findOne(props.workflowId)
				.then((workflow) => {
					setWorkflow(workflow);
					dispatchWorkflow({ type: 'set', payload: workflow as Workflow });
				})
				.finally(finishLoad);
		}
	}, [
		isLoading,
		finishLoad,
		setWorkflow,
		startLoad,
		props.workflowId,
		state.workflow,
		workflow,
		findOne,
	]);
	const isSelected = (link: string) => {
		return window.location.pathname.includes(link) ? 'selected' : '';
	};

	const workflowState = React.useMemo(() => {
		if (state.workflow) {
			return state.workflow;
		}
	}, [state.workflow]);

	const inputSlots = React.useMemo(
		() =>
			flattenDeep(
				flattenStages(workflow as Workflow)?.map((stage) => {
					return isArray(stage)
						? stage.map(
								({ inputSlots }: { inputSlots: InputSlot[] }) =>
									inputSlots ?? ([] as InputSlot[])
						  )
						: stage?.inputSlots ?? ([] as InputSlot[]);
				})
			) as InputSlot[],
		[workflow]
	);

	const getEvents = React.useMemo(
		() =>
			flattenStages(workflow as Workflow)?.flatMap((stage) => {
				if (isArray(stage)) {
					return stage?.flatMap((sub) => [...sub?.events] as StageEvent[]);
				} else return [...(stage?.events || [])] as StageEvent[];
			}),

		[workflow]
	);
	const renderDetail = () => {
		if (isLoading)
			return <LoadingRow label={`Loading ${workflowTerm.toLowerCase()}...`} />;
		if (!!state.workflow && !!state.workflow.stages?.length)
			return (
				<Row>
					<Col xs={12}>
						<div className="mb-4">
							<BackLink link=".." title={`${workflowTerm}s`} />
							<div className="d-flex justify-content-between">
								<div className="d-flex align-items-center">
									<WorkflowTitle className="mr-2 d-inline">
										{workflow?.title}
									</WorkflowTitle>
									<WorkflowHealthFlag workflow={workflow as Workflow} />
								</div>
								<div>
									<OwnerAvatarList
										displayNames={false}
										owners={uniqBy(
											workflow?.involved as BaseWorkflowOwner[],
											(m) => m._id
										)}
									/>
								</div>
							</div>
						</div>
					</Col>
					<Col xs={12}>
						<Nav tabs>
							<Link to="stages" className={isSelected('stages')}>
								{themeStore._.stage}s
							</Link>
							<Link to="details" className={isSelected('details')}>
								{workflowTerm} Details
							</Link>
							{inputSlots && inputSlots.length ? (
								<Link to="assets" className={isSelected('assets')}>
									{themeStore._.asset}s
								</Link>
							) : null}
							<Link to="logs" className={isSelected('logs')}>
								Access Logs
							</Link>
							<Link to="followers" className={isSelected('followers')}>
								Followers
							</Link>
							{/* 							<Link to="comments" className={isSelected('skus')}>
								Comments
							</Link> */}
							{(workflowState?.templateUsed as WorkflowTemplate)?.useSKUs && (
								<Link to="skus" className={isSelected('skus')}>
									SKUs
								</Link>
							)}
						</Nav>
					</Col>
					<Col xs={12} className="mt-4">
						<SelectedWorkflow.Provider
							value={[
								workflow as Workflow,
								(workflow: Workflow) => {
									dispatchWorkflow({ type: 'set', payload: workflow });
								},
							]}
						>
							<Router primary={false}>
								<StageIndexPanel
									workflow={workflow ?? (workflowState as Workflow)}
									path="stages"
									default
								/>
								<WorkflowDetailPanel
									dispatch={dispatchWorkflow}
									workflow={workflow}
									path="details"
								/>
								<Assets path="assets">
									<AssetsPanel workflow={workflow as Workflow} path="/" />
									<WorkflowAssetVersionDetailsView
										path=":versionId"
										workflow={workflow as Workflow}
									/>
								</Assets>
								<AccessLogsPanel
									events={getEvents as StageEvent[]}
									path="logs"
								/>
								<Assets path="assets">
									<AssetsPanel workflow={workflow as Workflow} path="/" />
									<WorkflowAssetVersionDetailsView
										path=":versionId"
										workflow={workflow as Workflow}
									/>
								</Assets>
								<RouterGroup path="skus">
									<SKUPanel path={'/'} />
									<SKUDetail
										skuMetadata={workflow?.skuMetadata as SKUMetadata}
										path={'/:rowId'}
									/>
								</RouterGroup>
								<RouterGroup path="followers">
									<WorkflowFollowersPanel
										workflow={workflow as Workflow}
										followers={workflow?.followers as BaseWorkflowOwner[]}
										path="/"
									/>
								</RouterGroup>

								{/* 							Temporarily Removing
								<RouterGroup path="comments">
									<WorkflowCommentPanel
										workflow={workflow as Workflow}
										path="/"
									/>
								</RouterGroup> */}
							</Router>
							<></>
						</SelectedWorkflow.Provider>
					</Col>
				</Row>
			);
		return null;
	};

	return (
		<Container fluid className="px-5">
			{renderDetail()}
		</Container>
	);
};
export default WorkflowDetailsView;

// tested fine for 648
