import { action, computed, IObservableArray, observable } from 'mobx';
import { ReactElement } from 'react';

/**
 * Singleton with an observable array of modals,
 * used by {@link DialogPortal} to render the active (if any) modal.
 * Any component can 'useModalStack' to access it.
 */
class ModalStack {
	private readonly _modals: IObservableArray<
		ReactElement
	> = observable.array([], { deep: false });

	@computed
	public get modals(): ReadonlyArray<ReactElement> {
		return this._modals;
	}

	@computed
	public get isEmpty() {
		return this._modals.length === 0;
	}

	@computed
	public get currentModal(): ReactElement | null {
		return this._modals[this._modals.length - 1] || null;
	}

	@computed
	public get renderCurrentModal(): () => ReactElement | null {
		return () => this.currentModal;
	}

	@action
	public addModal = (modal: ReactElement) => this._modals.push(modal);

	/**
	 * This should ONLY be called by Modal components themselves.
	 */
	@action
	public closeModal = () => this._modals.pop();

	/**
	 * This should ONLY be called by Modal components themselves.
	 */
	@action
	public closeAllModals = () => this._modals.splice(0, this._modals.length);
}

let modalStack: ModalStack;

/**
 * Use one of the more specific modal aspect getters specified below.
 * We never want the same component to handle both creating and deleting modals,
 * so we are splitting the ModalStack into several interfaces.
 */

const getModalStackInstance = () => {
	if (!modalStack) {
		modalStack = new ModalStack();
	}
	return modalStack;
};

export const useModalCreator: {
	(): Pick<ModalStack, 'addModal'>;
} = getModalStackInstance;
export const useModalCloser: {
	(): Pick<ModalStack, 'closeModal' | 'closeAllModals'>;
} = getModalStackInstance;
export const useModalRenderer: {
	(): Pick<ModalStack, 'modals' | 'currentModal' | 'renderCurrentModal'>;
} = getModalStackInstance;
