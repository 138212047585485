import { Redirect } from '@reach/router';
import { CreateATicketFab } from 'components/CreateATicketFab';
import DialogPortal from 'components/modals/dialog-portal.component';
import { ViewWrapper } from 'components/ui';
import moment from 'moment';
import React, { useEffect } from 'react';
import { saveNode } from 'utils/common';
import { AuthContext } from 'utils/core';
import {
	SessionTimeout,
	SessionTimeoutService,
} from 'utils/core/SessionTimeoutService';
import { useModalCreator } from 'utils/ModalStack';
import { appRoutes } from './app.routes';
import AdminNavigation from '../components/admin-navigation/admin-navigation.component';
import Footer from 'components/footer.component';

const ConfirmationDialog = React.lazy(() =>
	import('../components/modals/confirmation-dialog.component')
);

const MainView = () => {
	return (
		<ViewWrapper>
			{<DialogPortal />}
			<CreateATicketFab />
			<div className="navwrapper">
				<AdminNavigation editThemeView={false} />
			</div>
			{appRoutes}
			<Footer />
			<SessionTimeout />
		</ViewWrapper>
	);
};

const Admin = () => {
	const auth = React.useContext(AuthContext);

	const storedAuthValue = JSON.parse(
		localStorage.getItem('rome_auth') as string
	);

	const isOverdue =
		!storedAuthValue?.expiresAt ||
		storedAuthValue?.expiresAt === -1 ||
		moment(storedAuthValue?.expiresAt).isBefore(moment(new Date()));

	const isSessionAboutToEnd = React.useMemo(
		() => moment(storedAuthValue?.expiresAt).isBefore(moment(new Date())),
		[storedAuthValue]
	);
	const sessionTimeoutSvc = React.useMemo(
		() => new SessionTimeoutService(auth),
		[auth]
	);
	const modalCreator = useModalCreator();

	useEffect(() => {
		if (sessionTimeoutSvc.isIdle || isSessionAboutToEnd) {
			modalCreator.addModal(
				<ConfirmationDialog
					header="Session is about to expire"
					onConfirm={() => sessionTimeoutSvc.hideModal()}
				>
					<p>Your session is about to expire. Continue?</p>
					<span>{sessionTimeoutSvc.logoutCountdown}</span>
				</ConfirmationDialog>
			);
		}
	}, [sessionTimeoutSvc, isSessionAboutToEnd, modalCreator]);

	if (!storedAuthValue || isOverdue) {
		saveNode('rome_auth', {
			accessToken: null,
			expiresAt: -1,
			idToken: null,
			authUser: null,
		});
		return <Redirect to="/auth" noThrow />;
	}

	const Skeleton = <MainView />;

	return <>{Skeleton}</>;
};

export default Admin;
