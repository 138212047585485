import React from 'react';
import './LoadingIndicator.scss';
import MaterialIconSvg, {
	AvailableSvgIconsEnum,
} from './svg-icons/MaterialIconSvg';
import classNames from 'classnames';

interface LoadingIndicatorProps {
	dismiss: boolean;
}

export default function LoadingIndicator({ dismiss }: LoadingIndicatorProps) {
	return (
		<div
			className={classNames('no-freeze-spinner', { dismiss })}
			style={{ display: dismiss ? 'none' : '' }}
		>
			<div id="no-freeze-spinner">
				<div>
					<MaterialIconSvg icon={AvailableSvgIconsEnum.account_tree} />
					<MaterialIconSvg icon={AvailableSvgIconsEnum.photo_library} />
					<MaterialIconSvg icon={AvailableSvgIconsEnum.description} />
					<MaterialIconSvg icon={AvailableSvgIconsEnum.account_circle} />
					<div></div>
				</div>
			</div>
		</div>
	);
}
