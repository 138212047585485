import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMatch } from '@reach/router';
import { Search, searchObject } from 'components/forms/Search';
import { Select } from 'components/forms/Select';
import { Option } from 'components/forms/Select/Option/Option';
import {
	getItemsOnPage,
	getTotalPages,
	Heading,
	PageSubheading,
	PaginationContainer,
	SaveQueryButton,
} from 'components/index';
import { usePageHistory } from 'context/PageHistoryContext';
import LoadingIndicator from 'core-ui/components/LoadingIndicator/LoadingIndicator';
import numeral from 'numeral';
import React, { useContext, useEffect, useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import useViewType from 'utils/core/hooks/useViewType';
import { RoleContext } from '../../../context/PermissionsContext';
import { useQueryStoreContext } from 'context/useQueryStore';
// prettier-ignore
import { CreateWorkflowButton, WorkflowPageFilters, WorkflowPageHeader } from './components';

type Selection = {
	label: string;
	value: any;
};

type Props = {
	title: string;
	collection: any[];
	filters?: {
		options: { label: string; value: string }[];
		fn: (value: string | string[]) => void;
	};
	defaultFilter?: string[];
	defaultSort?: string;
	template?: {
		state: string;
		options: Array<{ label: string; value: string }>;
		fn: (value: string) => void;
	};
	sorting?: {
		options: { label: string; value: string }[];
		fn: (value: string) => void;
	};
	views: {
		table: (collection: any[]) => React.ReactNode;
		// cards?: (collection: any[]) => React.ReactNode;
	};
	saveQuery?: boolean;
	create?: boolean;
	searchTarget?: string;
	isFetching: boolean;
};

export const OldWorkflowsIndexView = (props: Props) => {
	const {
		title,
		collection,
		template,
		filters,
		sorting,
		views,
		defaultFilter,
		searchTarget,
		isFetching,
	} = props;

	const match = useMatch('/admin/workflow/workflows/status/:status');

	const queryStore = useQueryStoreContext();

	// Context and Store Hooks
	const { canViewByRole } = useContext(RoleContext);
	// useState Hooks
	const pageHistory = usePageHistory();
	const [currentPage, setCurrentPage] = useState<number>(
		pageHistory.getStateForKey(window.location.href, 'currentPage') ?? 1
	);
	const [searchTerm, setSearchTerm] = useState('');
	const [viewType, setViewType] = useViewType();

	const FilterColSize = React.useMemo(() => (!!template?.options ? 2 : 3), [
		template,
	]);

	// Effects
	useEffect(() => {
		const href = window.location.href;
		const queryType = href.includes('workflows')
			? 'workflows'
			: href.includes('templates')
			? 'templates'
			: 'assignments';

		const val = queryStore.entities.find((a) => a.type === queryType)?.value;
		val && setSearchTerm(val);
		/* 		const queryValue = windowModel.getURLSearchParam('q');
		if (queryValue && searchTerm !== queryValue) {
			setSearchTerm(queryValue);
		} */
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		const page = {
			path: window.location.href,
			state: [
				{
					key: 'searchTerm',
					value: searchTerm,
				},
			],
		};

		pageHistory.updateState(page, 'update');
		//eslint-disable-next-line
	}, [searchTerm]);

	// view type toggles
	const containsTitle = (str: string) =>
		title?.toLowerCase()?.indexOf(str?.toLowerCase()) > -1;

	const toggleViewType = (vt: 'list' | 'cards') => {
		if (['Assignments', 'Templates', 'Roadblocks'].some(containsTitle)) return;
		setViewType(vt);
	};

	const getViewType = () => {
		return ['Assignments', 'Templates', 'Roadblocks'].some(containsTitle)
			? 'list'
			: viewType;
	};

	const searchList = (
		term: string,
		target: string = searchTarget ? searchTarget : 'default',
		list: any[]
	) => {
		let filtered: any[];
		if (target === 'workflow') {
			// ! temporary workaround to switch filtering to workflow title, not stage for assignments
			filtered = list.filter(
				({ workflowTitle, metadata }) =>
					searchObject(metadata?.values, term, true) ||
					searchObject({ workflowTitle }, term)
			);
		} else {
			filtered = list?.filter(
				({ title, metadata }) =>
					searchObject(metadata?.values, term, true) ||
					searchObject({ title }, term)
			);
		}
		return filtered;
	};

	return (
		<Container fluid style={{ paddingLeft: 30, paddingRight: 30 }}>
			<Row>
				<WorkflowPageHeader>
					<div>
						<PageSubheading text="workflows" />
						<Heading>{`${title}`}</Heading>
					</div>

					{((title !== 'Templates' && canViewByRole('wfEditable')) ||
						(title === 'Templates' && canViewByRole('wfTemplatesEditable'))) &&
						props.create && (
							<CreateWorkflowButton
								className="cy-new-workflow-button"
								to={match && match.status ? '../../new' : './new'}
							>
								<FontAwesomeIcon icon={faPlus} className="mr-2" />
								New {title.slice(0, -1)}
							</CreateWorkflowButton>
						)}
				</WorkflowPageHeader>

				<WorkflowPageFilters>
					<Col xl={5} className="my-1 col-xs-12 search-query-grid">
						{/* Search Heading */}
						<Search
							// className='search-query'
							defaultValue={searchTerm}
							onSearch={(val: any) => {
								setSearchTerm(val);
								setCurrentPage(1);
							}}
							placeholder={`Search ${searchTerm}`}
						/>

						{
							<SaveQueryButton
								className="mx-1"
								searchTerm={searchTerm}
								sort={props.defaultSort}
								filter={props.defaultFilter}
								templates={props.template?.state}
								type={
									window.location.href.includes('workflows')
										? 'workflows'
										: window.location.href.includes('templates')
										? 'templates'
										: 'assignments'
								}
							/>
						}
					</Col>
					{filters && (
						<Col className="my-1 col-xs-12">
							{/* Filter By Status */}

							<Select
								name="status"
								label={'Filter ' + title}
								defaultValue={defaultFilter as string[]}
								noLabel
								multiple
								onSelect={(selection: Selection | Selection[]) => {
									if (Array.isArray(selection))
										filters.fn(selection?.map((selection) => selection.value));
									if (currentPage > 1) setCurrentPage(1);
									if (typeof Selection === typeof selection)
										filters.fn((selection as any).value);
								}}
							>
								{filters?.options
									.filter((a) => a.value !== 'all')
									.map((props, idx) => (
										<Option key={idx} {...props} />
									))}
							</Select>
						</Col>
					)}

					{sorting && (
						<Col className="my-1 col-xs-12">
							{/* Sorting */}

							<Select
								name="sort"
								noLabel
								label="Sort Workflows"
								defaultValue={props.defaultSort}
								onSelect={(selection: Selection | Selection[]) =>
									sorting.fn((selection as any).value)
								}
							>
								{sorting.options.map(({ value, label }) => (
									<Option
										key={label}
										value={value}
										label={'Sort By ' + label}
									/>
								))}
							</Select>
						</Col>
					)}
					{FilterColSize === 2 && (
						<Col className="col-xs-12">
							<Select
								noLabel
								search
								name="templateFilter"
								label="Filter by Workflow Template"
								defaultValue={props.template?.state?.toString()}
								onSelect={(selection: Selection | Selection[]) => {
									const value = Array.isArray(selection)
										? selection.map((a) => a.value)
										: selection.value;
									if (currentPage > 1) setCurrentPage(1);
									template?.fn((selection as any).value);
								}}
							>
								{template?.options.map(({ value, label }) => (
									<Option key={label} value={value} label={label} />
								))}
							</Select>
						</Col>
					)}
				</WorkflowPageFilters>
			</Row>

			<Row>
				{/* Table List */}
				<div style={{ width: '100%' }}>
					{views.table(
						getItemsOnPage(
							searchList(searchTerm, searchTarget, collection),
							currentPage
						)
					)}
				</div>
			</Row>

			{/* Results count display */}
			{collection && collection.length > 0 && (
				<Row>
					<Col>
						<p className="text-right pt-3">
							{numeral(collection.length).format('0,0')} results found.
						</p>
					</Col>
				</Row>
			)}

			{/* Pagination */}

			{<LoadingIndicator dismiss={collection && !isFetching} />}
			{collection && getTotalPages(collection) > 1 && (
				<div className="d-flex justify-content-center">
					<PaginationContainer
						totalPages={getTotalPages(
							searchList(searchTerm, searchTarget, collection)
						)}
						currentPage={currentPage}
						setCurrentPage={setCurrentPage}
						isFetching={isFetching}
					/>
				</div>
			)}
		</Container>
	);
};
