import { WorkflowCollection } from 'components/workflow/workflows/types/workflow.types';
import React from 'react';
import { useFetching } from 'hooks/useFetching';
import * as axios from 'axios';
import { useHeaders } from 'utils/auth';
type WorkflowCollectionContextType = {
	entities: WorkflowCollection[];
	updateContext: (wfc: WorkflowCollection) => void;
	isFetching: boolean;
};

export const WorkflowCollectionContext = React.createContext<
	WorkflowCollectionContextType
>({
	entities: [],
	isFetching: true,
	updateContext: () => {
		return;
	},
});

export const WorkflowCollectionContextProvider = ({
	children,
}: {
	children: React.ReactNode[];
}) => {
	const [collections, setCollections] = React.useState<
		WorkflowCollection[] | undefined
	>();
	const { getHeaders } = useHeaders();
	const { isFetching, beginFetching, finishFetching } = useFetching();

	React.useEffect(() => {
		if (
			!!getHeaders()?.headers?.Authorization &&
			!getHeaders().headers?.Authorization?.includes('null')
		) {
			if (isFetching) return;
			beginFetching();
			if (!collections) {
				axios.default
					.get<WorkflowCollection[]>(
						`${process.env.REACT_APP_ROME_API_ENDPOINT}/projects`,
						getHeaders()
					)
					.then((collections) =>
						setCollections(collections?.data as WorkflowCollection[])
					);
			}
			finishFetching();
		}
	}, [isFetching, beginFetching, finishFetching, collections, getHeaders]);

	const updateContext = (wfc: WorkflowCollection) => {
		setCollections([...collections?.filter((a) => a._id !== wfc._id), wfc]);
	};

	return (
		<WorkflowCollectionContext.Provider
			value={{
				entities: collections as WorkflowCollection[],
				updateContext: updateContext,
				isFetching: isFetching,
			}}
		>
			{children}
		</WorkflowCollectionContext.Provider>
	);
};

export const useWorkflowCollectionContext = () => {
	const context = React.useContext(WorkflowCollectionContext);
	if (!context)
		throw new Error(
			'Expected to be in a workflows collection context but wasnt'
		);
	return context;
};
