import * as axios from 'axios';
import { useAxios } from 'hooks';
import { isArray } from 'lodash';
import React, { useCallback } from 'react';
import { Maybe } from 'types/globals';
import {
	workflowDefaultFilters,
	stagesDefaultFilters,
	templateDefaultFilters,
} from '../state/workflows/constants/index';
import { useAuthContext, useGroupContext, useHeaders, User } from 'utils/auth';
import { UserGroup } from '../components/accounts/types';
import { ReportData } from '../components/reports/status-report-table.component';
import { flattenStages } from '../components/workflow/workflows/helpers';
import {
	BaseWorkflowOwner,
	Flattenable,
	Stage,
	StatusEnum,
	Workflow,
	WorkflowCollection,
	WorkflowTemplate,
} from '../components/workflow/workflows/types';
import { _logError } from '../utils/common/log';
import { EventType } from '../utils/models/StageEventTypeModel';
type ReportColumn = {
	title: string;
	key: string;
	label: string;
};
export type ReportTemplate = {
	_id: string;
	title: string;
	filenameTemplate: string;
	columns: ReportColumn[];
};

export type ReportStats = Array<{
	views: number;
	reportId: string;
	timestamp: Date;
}>;

export type WorkflowFilter =
	| 'pipeline'
	| 'active'
	| 'paused'
	| 'cancelled'
	| 'completed'
	| 'healthy'
	| 'overdue'
	| 'roadblocked'
	| 'createdBy'
	| 'collaborating'
	| 'following';

type StoreProps = {
	updateStageTask: (
		taskId: string,
		workflowId: string,
		stageId: string,
		isComplete: boolean
	) => Promise<Maybe<Workflow>>;
	deleteWorkflowComment: (
		commentId: string,
		workflowId: string
	) => Promise<Maybe<Workflow>>;
	addWorkflowComment: (
		comment: { message: string; parentId?: string },
		workflowId: string
	) => Promise<Maybe<Workflow>>;
	updateFollowers: (
		workflowId: string,
		newFollowers: BaseWorkflowOwner[]
	) => Promise<boolean>;
	deleteComment: (
		commentId: string,
		workflowId: string,
		stageId: string
	) => Promise<boolean>;
	updateComment: (
		commentId: string,
		workflowId: string,
		stageId: string,
		commentText: string
	) => Promise<boolean>;
	updateTemplateContext: (template: WorkflowTemplate) => void;
	addToContext: (workflow: Workflow) => void;
	entities: Workflow[];
	templates: WorkflowTemplate[];
	stages: Stage[];
	totalPages: number;
	totalDocs: number;
	currentPage: number;
	templatesTotalPages: number;
	templatesTotalDocs: number;
	templatesCurrentPage: number;
	stagesFilters: WorkflowFilter[];
	stagesSort: string;
	stagesSearchTerm: string;
	stagesTotalPages: number;
	stagesTotalDocs: number;
	stagesCurrentPage: number;
	filters: WorkflowFilter[];
	template: string;
	sort: string;
	searchTerm: string;
	setPage: (pl: {}) => void;
	setTemplatePageState: (pl: {}) => void;
	setStagesPageState: (pl: {}) => void;
	updateOne: (id: string, workflow: Workflow) => Promise<Maybe<Workflow>>;
	findOne: (id: string) => Promise<Maybe<Workflow>>;
	fetchAllWorkflows: (
		page: number,
		filters?: any[],
		sort?: string,
		template?: string,
		searchTerm?: string
	) => Promise<Workflow[]>;
	fetchAllStages: (
		page: number,
		filters?: string[],
		sort?: string,
		template?: string,
		searchTerm?: string
	) => Promise<any>;
	fetchAllTemplates: (
		page: number,
		filters?: any[],
		sort?: string,
		searchTerm?: string
	) => Promise<WorkflowTemplate[]>;
	workflow?: Workflow;
	stage?: Stage;
	userFollowedTemplates?: WorkflowTemplate[];
	setStage: (s: Stage) => void;
	setOwner: (
		type: string,
		newOwners: BaseWorkflowOwner[],
		stage: Stage,
		workflow: Workflow
	) => Promise<Maybe<Workflow>>;
	setWorkflow: (wf: Workflow) => void;
	addComment: (comment: string) => Promise<Stage | undefined>;
	updateCollection: (
		workflow: Workflow,
		workflowCollection: string
	) => Promise<Maybe<Workflow>>;
	updateStatus: (
		newStatus: StatusEnum,
		wf: Workflow,
		message: string,
		stage?: Stage
	) => Promise<Maybe<Workflow>>;
	canOwnerActOnWorkflow: (
		currentUser: User,
		groupsForCurrentUser?: UserGroup[]
	) => boolean;
	removeFromCollection: (workflowId: string) => Promise<Maybe<Workflow>>;
	allTemplates: WorkflowTemplate[];
	getReportData: (
		reportId: string,
		dateTo: Date | undefined,
		dateFrom: Date | undefined,
		status: string[] | undefined
	) => Promise<Maybe<ReportData>>;
	getReportStats: () => Promise<Maybe<ReportStats>>;
	fetchAvailableReports: () => Promise<
		Maybe<{ reports: ReportTemplate[]; stats: ReportStats }>
	>;
	revertToStage: (
		stageId: string,
		workflowId: string,
		comment: string
	) => Promise<Workflow>;
	rejectStage: (
		transitionId: string,
		message: string,
		workflow: Workflow,
		stage: Stage
	) => Promise<Maybe<Workflow>>;
	isFetching: boolean;
};

type WorkflowStoreContext = {
	state: StoreProps;
};

export const WorkflowStoreContext = React.createContext<WorkflowStoreContext>({
	state: {
		...workflowDefaultFilters,
		...stagesDefaultFilters,
		...templateDefaultFilters,
		setPage: (info) => {
			return info;
		},
		setStagesPageState: (info) => {
			return info;
		},
		setTemplatePageState: (info) => {
			return info;
		},
		updateStageTask: () => Promise.resolve({} as Workflow),
		updateTemplateContext: () => {
			return;
		},
		updateFollowers: () => Promise.resolve(false),
		deleteComment: () => Promise.resolve(false),
		updateComment: () => Promise.resolve(false),
		addToContext: (workflow: Workflow) => {
			return;
		},
		deleteWorkflowComment: () => Promise.resolve({} as Workflow),
		addWorkflowComment: () => Promise.resolve({} as Workflow),
		updateOne: () => new Promise((res) => res({} as Workflow)),
		findOne: () => new Promise((res) => res({} as Workflow)),
		fetchAllWorkflows: () => new Promise((res) => res([] as Workflow[])),
		fetchAllStages: () => new Promise((res) => res([] as any[])),
		fetchAllTemplates: () => new Promise((res) => res([] as any[])),
		updateCollection: () => new Promise((res) => res({} as Workflow)),
		updateStatus: () => new Promise((res) => res({} as Workflow)),
		removeFromCollection: () => new Promise((res) => res({} as Workflow)),
		addComment: () => new Promise((res) => res({} as Stage)),
		userFollowedTemplates: [] as WorkflowTemplate[],
		stage: {} as Stage,
		setStage: (stage: Stage) => {
			return;
		},
		setOwner: () => new Promise((res) => res({} as Workflow)),
		entities: Array<Workflow>(),
		templates: Array<WorkflowTemplate>(),
		stages: Array<Stage>(),
		workflow: {} as Workflow,
		canOwnerActOnWorkflow: () => true,
		setWorkflow: (wf) => {
			return;
		},
		allTemplates: [],
		getReportData: () => Promise.resolve({} as ReportData),
		getReportStats: () => Promise.resolve([] as ReportStats),
		revertToStage: () => new Promise((res) => res({} as Workflow)),
		rejectStage: () => Promise.resolve<Workflow>({} as Workflow),
		fetchAvailableReports: () =>
			Promise.resolve({ reports: [] as ReportTemplate[], stats: [] }),
		isFetching: false,
	},
});

function stageReducer(
	state: { selectedStage: Maybe<Stage> },
	action: {
		type: 'setStage';
		payload: Stage;
	}
) {
	switch (action.type) {
		case 'setStage':
			return { ...state, selectedStage: action.payload as Stage };
		default:
			return state;
	}
}

function workflowReducer(
	state: { workflow: Maybe<Workflow> },
	action: {
		type: 'setWorkflow';
		payload: Workflow;
	}
) {
	switch (action.type) {
		case 'setWorkflow':
			return { ...state, workflow: action.payload as Workflow };
		default:
			return state;
	}
}

export function fetchingReducer(
	state: { isFetching: boolean },
	action: { type: 'set'; payload: boolean }
) {
	switch (action.type) {
		case 'set':
			return { ...state, isFetching: action.payload };
		default:
			return state;
	}
}

function usePageReducer() {
	return React.useReducer(pageReducer, workflowDefaultFilters);
}

function pageReducer(
	state: {
		filters: WorkflowFilter[];
		totalPages: number;
		currentPage: number;
		totalDocs: number;
		sort: string;
		template: string;
		searchTerm: string;
	},
	action: { type: 'setPage'; payload: {} }
) {
	switch (action.type) {
		case 'setPage':
			return { ...state, ...action.payload };
		default:
			return state;
	}
}

function useStagesPageReducer() {
	return React.useReducer(stagesPageReducer, stagesDefaultFilters);
}

function stagesPageReducer(
	state: {
		stagesFilters: any[];
		stagesTotalPages: number;
		stagesTotalDocs: number;
		stagesCurrentPage: number;
		stagesSort: string;
		stagesSearchTerm: string;
	},
	action: { type: 'setPage'; payload: {} }
) {
	switch (action.type) {
		case 'setPage':
			return { ...state, ...action.payload };
		default:
			return state;
	}
}

function useTemplatePageReducer() {
	return React.useReducer(templatesPageReducer, templateDefaultFilters);
}

function templatesPageReducer(
	state: {
		templatesFilters: any[];
		templatesTotalPages: number;
		templatesTotalDocs: number;
		templatesCurrentPage: number;
		templatesSort: string;
		templatesSearchTerm: string;
	},
	action: { type: 'setPage'; payload: {} }
) {
	switch (action.type) {
		case 'setPage':
			return { ...state, ...action.payload };
		default:
			return state;
	}
}

export const WorkflowStoreContextProvider = ({
	children,
}: {
	children: React.ReactNode[];
}) => {
	const { currentUser } = useAuthContext();
	const { groupsForCurrentUser } = useGroupContext();
	const templateStore = useAxios<WorkflowTemplate>('templates');
	const [state, dispatch] = React.useReducer(fetchingReducer, {
		isFetching: false,
	});
	const [pageState, setPage] = usePageReducer();
	const [stagesPageState, setStagesPageState] = useStagesPageReducer();
	const [templatePageState, setTemplatePageState] = useTemplatePageReducer();

	const {
		filters,
		totalPages,
		currentPage,
		totalDocs,
		sort,
		template,
		searchTerm,
	} = pageState;

	const {
		stagesFilters,
		stagesSort,
		stagesSearchTerm,
		stagesTotalPages,
		stagesTotalDocs,
		stagesCurrentPage,
	} = stagesPageState;

	const {
		templatesTotalPages,
		templatesTotalDocs,
		templatesCurrentPage,
	} = templatePageState;

	const { getHeaders } = useHeaders();
	const isFetching = state.isFetching;
	const [stages, setStages] = React.useState<Stage[]>();
	const [entities, setEntities] = React.useState<Workflow[]>();
	const [workflow, setWorkflow] = React.useReducer(workflowReducer, {
		workflow: undefined,
	});
	const [stage, setStage] = React.useReducer(stageReducer, {
		selectedStage: undefined,
	});
	const [userFollowedTemplates, setUserFollowedTemplates] = React.useState<
		WorkflowTemplate[]
	>();
	const [templates, setTemplates] = React.useState<WorkflowTemplate[]>();
	const [allTemplates, setAllTemplates] = React.useState<WorkflowTemplate[]>();

	const refreshEntity = (entity: Workflow) => {
		if (entities?.some((wf) => wf._id === entity._id)) {
			setEntities([...entities.filter((e) => e._id !== entity._id), entity]);
		}
	};

	const updateComment = async (
		commentId: string,
		workflowId: string,
		stageId: string,
		commentText: string
	) => {
		const response = await axios.default.patch<Workflow>(
			`${process.env.REACT_APP_ROME_API_ENDPOINT}/workflows/${workflowId}/stages/${stageId}/events/${commentId}/${commentText}`,
			{},
			getHeaders()
		);
		const workflowAfterCommentDelete = response.data;
		if (workflowAfterCommentDelete) {
			refreshEntity(workflowAfterCommentDelete);
			setWorkflow({ type: 'setWorkflow', payload: workflowAfterCommentDelete });
			const updatedStage = flattenStages(
				workflowAfterCommentDelete as Workflow
			)?.find((stage) => stage._id === stageId);
			setStage({ type: 'setStage', payload: updatedStage as Stage });
		}
		return !!workflowAfterCommentDelete;
	};

	const deleteComment = async (
		commentId: string,
		workflowId: string,
		stageId: string
	) => {
		const response = await axios.default.delete<Workflow>(
			`${process.env.REACT_APP_ROME_API_ENDPOINT}/workflows/${workflowId}/stages/${stageId}/events/${commentId}`,
			getHeaders()
		);
		const workflowAfterCommentDelete = response.data;
		if (workflowAfterCommentDelete) {
			refreshEntity(workflowAfterCommentDelete);
			setWorkflow({ type: 'setWorkflow', payload: workflowAfterCommentDelete });
			const updatedStage = flattenStages(
				workflowAfterCommentDelete as Workflow
			)?.find((stage) => stage._id === stageId);
			setStage({ type: 'setStage', payload: updatedStage as Stage });
		}
		return !!workflowAfterCommentDelete;
	};

	const rejectStage = async (
		transitionId: string,
		message: string,
		workflow: Workflow,
		stage: Stage
	) => {
		if (!getHeaders()?.headers?.Authorization) return;
		dispatch({ type: 'set', payload: true });
		const response = await axios.default
			.put<Workflow>(
				`${process.env.REACT_APP_ROME_API_ENDPOINT}/workflows/${workflow._id}/stages/${stage._id}`,
				{ type: EventType.backwardTransition, transitionId, message },
				getHeaders()
			)
			.finally(() => dispatch({ type: 'set', payload: false }));

		refreshEntity(response.data);
		setWorkflow({ type: 'setWorkflow', payload: response.data as Workflow });
		return response.data as Workflow;
	};

	const getReportData = async (
		id: string,
		dateTo: Date | undefined,
		dateFrom: Date | undefined,
		status: string[] | undefined
	) => {
		if (!getHeaders()?.headers?.Authorization) return;

		const response = await axios.default.get<ReportData>(
			`${process.env.REACT_APP_ROME_API_ENDPOINT}/workflow-reports/${id}/${dateTo}/${dateFrom}/${status}`,
			getHeaders()
		);

		return response.data;
	};

	const getReportStats = async () => {
		if (!getHeaders()?.headers?.Authorization) return;
		const response = await axios.default.get<ReportStats>(
			`${process.env.REACT_APP_ROME_API_ENDPOINT}/workflow-reports/stats`,
			getHeaders()
		);

		return response.data;
	};

	const fetchAvailableReports = async () => {
		if (!getHeaders()?.headers?.Authorization) return;
		const response = await axios.default.get<{
			reports: ReportTemplate[];
			stats: ReportStats;
		}>(
			`${process.env.REACT_APP_ROME_API_ENDPOINT}/workflow-reports`,
			getHeaders()
		);

		return response && response.data
			? response.data
			: { reports: [] as ReportTemplate[], stats: {} as ReportStats };
	};

	const revertToStage = async (
		stageId: string,
		workflowId: string,
		comment: string
	) => {
		const response = await axios.default.post<Workflow>(
			`${process.env.REACT_APP_ROME_API_ENDPOINT}/workflows/${workflowId}/revert/${stageId}`,

			{ message: comment },
			getHeaders()
		);
		refreshEntity(response.data);
		setWorkflow({ type: 'setWorkflow', payload: response.data });
		return response.data;
	};

	const fetchAllWorkflows = async (
		page: number,
		filters?: WorkflowFilter[],
		sort?: string,
		template?: string,
		searchTerm?: string
	) => {
		dispatch({ type: 'set', payload: true });
		const res = await axios.default
			.get<PaginateResult<Workflow>>(
				`${process.env.REACT_APP_ROME_API_ENDPOINT}/workflows`,
				{
					params: {
						page,
						filters,
						sort,
						template,
						searchTerm,
					},
					...getHeaders(),
				}
			)
			.finally(() => dispatch({ type: 'set', payload: false }));
		setPageCb({
			totalPages: res.data.totalPages,
			totalDocs: res.data.totalDocs,
		});
		setEntities(res.data.docs);
		return res.data.docs;
	};

	const fetchAllStages = async (
		page: number,
		filters?: string[],
		sort?: string,
		template?: string,
		searchTerm?: string
	) => {
		dispatch({ type: 'set', payload: true });
		const res = await axios.default
			.get<PaginateResult<Stage>>(
				`${process.env.REACT_APP_ROME_API_ENDPOINT}/workflows/stages/assigned`,
				{
					params: {
						page,
						filters,
						sort,
						template,
						searchTerm,
					},
					...getHeaders(),
				}
			)
			.finally(() => dispatch({ type: 'set', payload: false }));
		setStagesPageCb({
			stagesTotalPages: res.data.totalPages,
			stagesTotalDocs: res.data.totalDocs,
		});
		setStages(res.data.docs);

		return res.data.docs;
	};

	const fetchAllTemplates = async (
		page: number,
		filters?: string[],
		sort?: string,
		searchTerm?: string
	) => {
		dispatch({ type: 'set', payload: true });
		const res = await axios.default
			.get<PaginateResult<WorkflowTemplate>>(
				`${process.env.REACT_APP_ROME_API_ENDPOINT}/templates`,
				{
					params: {
						page,
						filters,
						sort,
						searchTerm,
					},
					...getHeaders(),
				}
			)
			.finally(() => dispatch({ type: 'set', payload: false }));
		setTemplatePageCb({
			templatesTotalPages: res.data.totalPages,
			templatesTotalDocs: res.data.totalDocs,
		});

		setTemplates(res.data.docs);
		return res.data.docs;
	};

	const isUserOwnerOrFollower = (template: WorkflowTemplate) => {
		const isFollower =
			!!template.followers?.some((follower) => {
				return (
					follower._id === currentUser._id ||
					!!groupsForCurrentUser.some(
						(group: UserGroup) => group._id === follower._id
					)
				);
			}) ||
			!!((template.createdBy as User)._id === currentUser._id) ||
			!!template.owners?.some(
				(owner) =>
					owner._id === currentUser._id ||
					groupsForCurrentUser.some((group) => group._id === owner._id)
			);

		const isStakeholder = flattenStages(
			template as Flattenable
		)?.some((stage) =>
			stage?.owners?.some(
				(owner) =>
					owner._id === currentUser._id ||
					groupsForCurrentUser.some((grp) => grp._id === owner._id)
			)
		);
		return isFollower || isStakeholder;
	};

	React.useEffect(() => {
		if (allTemplates?.length && allTemplates.length > 0) return;
		if (!getHeaders()?.headers?.Authorization) return;
		if (state.isFetching) return;
		dispatch({ type: 'set', payload: true });
		templateStore
			.findAll()
			.then((templates) => {
				setAllTemplates(templates);
				setUserFollowedTemplates(templates.filter(isUserOwnerOrFollower) || []);
			})
			.catch((e) => {
				_logError(e);
				dispatch({ type: 'set', payload: false });
			})
			.finally(() => dispatch({ type: 'set', payload: false }));
		// eslint-disable-next-line
	}, [
		getHeaders,
		userFollowedTemplates,
		groupsForCurrentUser,
		currentUser?._id,
		state.isFetching,
		allTemplates,
		templateStore,
	]);

	const addToContext = (workflow: Workflow) => {
		setEntities([...(entities || []), workflow]);
	};

	const addComment = async (comment: string) => {
		if (!getHeaders()?.headers?.Authorization) return;
		if (state.isFetching) return;
		dispatch({ type: 'set', payload: true });
		const updatedWorkflow = await axios.default
			.put<Workflow>(
				`${process.env.REACT_APP_ROME_API_ENDPOINT}/workflows/${workflow?.workflow?._id}/stages/${stage.selectedStage?._id}`,
				{ message: comment, type: 'comment' },
				getHeaders()
			)
			.finally(() => dispatch({ type: 'set', payload: false }));
		refreshEntity(updatedWorkflow.data);
		setWorkflow({
			type: 'setWorkflow',
			payload: updatedWorkflow.data as Workflow,
		});
		setStage({
			type: 'setStage',
			payload: flattenStages(updatedWorkflow.data)?.find(
				(updated) => updated._id === stage.selectedStage?._id
			) as Stage,
		});
		return flattenStages(updatedWorkflow.data)?.find(
			(updated) => updated._id === stage.selectedStage?._id
		) as Stage;
	};

	const setStageCb = useCallback((stage: Stage) => {
		setStage({ type: 'setStage', payload: stage });
	}, []);

	const updateOne = async (id: string, updatedWf: Workflow) => {
		if (!getHeaders()?.headers?.Authorization) return;
		if (state.isFetching) return;
		dispatch({ type: 'set', payload: true });
		const response = await axios.default
			.patch<Workflow>(
				`${process.env.REACT_APP_ROME_API_ENDPOINT}/workflows/${id}`,
				{ ...updatedWf, updatedBy: currentUser?._id },
				getHeaders()
			)
			.finally(() => dispatch({ type: 'set', payload: false }));

		if (response.data) {
			refreshEntity(response.data);
			setWorkflow({ type: 'setWorkflow', payload: response.data });
		}
		return response.data as Workflow;
	};

	const updateCollection = async (wf: Workflow, workflowCollection: string) => {
		if (!getHeaders()?.headers?.Authorization) return;
		if (state.isFetching) return;
		dispatch({ type: 'set', payload: true });
		const response = await axios.default
			.put<{ workflow: Workflow; collections: WorkflowCollection[] }>(
				`${process.env.REACT_APP_ROME_API_ENDPOINT}/workflows/${wf._id}/collection/${workflowCollection}`,
				wf,
				getHeaders()
			)
			.finally(() => dispatch({ type: 'set', payload: false }));

		refreshEntity({
			...response.data.workflow,
			workflowCollection: response.data.collections[0],
		});
		setWorkflow({
			type: 'setWorkflow',
			payload: {
				...(response.data.workflow as Workflow),
				workflowCollection: response.data.collections[0],
			},
		});

		return {
			...response.data.workflow,
			workflowCollection: response.data.collections[0],
		} as Workflow;
	};

	const findOne = async (id: string) => {
		if (!getHeaders()?.headers?.Authorization) return;
		if (state.isFetching) return;
		dispatch({ type: 'set', payload: true });
		const response = await axios.default
			.get<Workflow>(
				`${process.env.REACT_APP_ROME_API_ENDPOINT}/workflows/${id}`,
				getHeaders()
			)
			.finally(() => dispatch({ type: 'set', payload: false }));
		return response.data as Workflow;
	};

	const setOwner = async (
		type: string = 'setOwner',
		newOwners: BaseWorkflowOwner[],
		stage: Stage,
		workflow: Workflow
	) => {
		if (!getHeaders()?.headers?.Authorization) return;
		dispatch({ type: 'set', payload: true });
		const response = await axios.default
			.put<Workflow>(
				`${process.env.REACT_APP_ROME_API_ENDPOINT}/workflows/${workflow._id}/stages/${stage._id}`,
				{ type, newOwners },
				getHeaders()
			)
			.finally(() => dispatch({ type: 'set', payload: false }));

		refreshEntity(response.data);
		setWorkflow({ type: 'setWorkflow', payload: response.data as Workflow });
		return response.data as Workflow;
	};

	const updateStatus = async (
		newStatus: StatusEnum,
		wf: Workflow,
		message: string,
		stage?: Stage
	) => {
		if (!getHeaders()?.headers?.Authorization) return;
		dispatch({ type: 'set', payload: true });
		let endpoint: string = `${process.env.REACT_APP_ROME_API_ENDPOINT}`;
		if (stage) {
			endpoint += `/workflows/${wf._id}/stages/${stage._id}`;
		} else {
			endpoint += `/workflows/${wf._id}/status`;
		}

		let payload;
		if (!!stage)
			payload = {
				type: 'statusChange',
				message,
				newStatus,
				statusMsg: message,
			};
		else payload = { status: newStatus, statusMsg: message };

		const workflowAfterAction = await axios.default
			.put<Workflow>(endpoint, payload, getHeaders())
			.finally(() => dispatch({ type: 'set', payload: false }));

		refreshEntity(workflowAfterAction.data);
		setWorkflow({
			type: 'setWorkflow',
			payload: workflowAfterAction.data as Workflow,
		});

		if (stage) {
			setStage({
				type: 'setStage',
				payload: workflowAfterAction.data.stages?.find(
					(stg) => stg._id === stage?._id
				) as Stage,
			});
		}

		return workflowAfterAction.data as Workflow;
	};

	const setWorkflowCb = useCallback((workflow: Workflow) => {
		setWorkflow({ type: 'setWorkflow', payload: workflow });
	}, []);

	const setPageCb = useCallback((things: {}) => {
		setPage({ type: 'setPage', payload: things });
	}, []);

	const setStagesPageCb = useCallback((things: {}) => {
		setStagesPageState({ type: 'setPage', payload: things });
	}, []);

	const setTemplatePageCb = useCallback((things: {}) => {
		setTemplatePageState({ type: 'setPage', payload: things });
	}, []);

	const removeFromCollection = async (workflowId: string) => {
		if (!getHeaders()?.headers?.Authorization) return;
		if (state.isFetching) return;
		dispatch({ type: 'set', payload: true });
		const updated = await axios.default
			.delete<{ workflow: Workflow; collections: WorkflowCollection[] }>(
				`${process.env.REACT_APP_ROME_API_ENDPOINT}/workflows/${workflowId}/collection`,
				getHeaders()
			)
			.finally(() => dispatch({ type: 'set', payload: false }));
		if (updated.data) {
			refreshEntity(updated.data.workflow);
			setWorkflow({
				type: 'setWorkflow',
				payload: updated.data.workflow as Workflow,
			});
		}
		return updated.data.workflow as Workflow;
	};

	const canOwnerActOnWorkflow = (
		currentUser: User,
		groupsForCurrentUser?: UserGroup[]
	) => {
		if (!workflow.workflow) return false;
		if (!!flattenStages(workflow.workflow)) {
			return ((
				flattenStages(workflow.workflow) || []
			)?.some((stage: Stage[] | Stage) =>
				isArray(stage)
					? stage?.some((owner) =>
							owner?.owners?.some(({ _id }) => _id === currentUser?._id)
					  )
					: stage?.owners?.some(({ _id }) => _id === currentUser?._id) ||
					  (groupsForCurrentUser || [])?.some(({ members }) =>
							members?.some((u) => u._id === currentUser?._id)
					  )
			) ||
				workflow.workflow?.owners.some((m) => m._id === currentUser?._id) ||
				workflow.workflow?.createdBy._id === currentUser?._id ||
				currentUser?.isAdmin) as boolean;
		} else return false;
	};

	const addWorkflowPanelComment = async (
		comment: { message: string; parentId?: string },
		workflowId: string
	) => {
		if (!getHeaders()?.headers?.Authorization) return;
		const response = await axios.default
			.put<Workflow>(
				`${process.env.REACT_APP_ROME_API_ENDPOINT}/workflows/comment/workflow/${workflowId}`,
				comment,
				getHeaders()
			)
			.finally(() => dispatch({ type: 'set', payload: false }));

		refreshEntity(response.data);
		setWorkflow({ type: 'setWorkflow', payload: response.data as Workflow });
		return response.data as Workflow;
	};

	const updateStageTask = async (
		taskId: string,
		workflowId: string,
		stageId: string,
		isComplete: boolean
	) => {
		if (!getHeaders()?.headers?.Authorization) return;
		const response = await axios.default
			.patch<Workflow>(
				`${process.env.REACT_APP_ROME_API_ENDPOINT}/workflows/stage/task/${taskId}/${isComplete}/${stageId}/${workflowId}`,
				{},
				getHeaders()
			)
			.finally(() => dispatch({ type: 'set', payload: false }));

		refreshEntity(response.data);
		setWorkflow({ type: 'setWorkflow', payload: response.data as Workflow });
		return response.data as Workflow;
	};

	const deleteWorkflowPanelComment = async (
		commentId: string,
		workflowId: string
	) => {
		if (!getHeaders()?.headers?.Authorization) return;
		const response = await axios.default
			.delete<Workflow>(
				`${process.env.REACT_APP_ROME_API_ENDPOINT}/workflows/comment/${workflowId}/${commentId}`,
				getHeaders()
			)
			.finally(() => dispatch({ type: 'set', payload: false }));

		refreshEntity(response.data);
		setWorkflow({ type: 'setWorkflow', payload: response.data as Workflow });
		return response.data as Workflow;
	};

	const updateTemplateContext = (template: WorkflowTemplate) => {
		setAllTemplates([
			...(allTemplates || [])?.filter((a) => a._id !== template._id),
			template,
		]);
	};

	const updateFollowers = async (
		workflowId: string,
		newFollowers: BaseWorkflowOwner[]
	) => {
		const response = await axios.default.patch<Workflow>(
			`${process.env.REACT_APP_ROME_API_ENDPOINT}/workflows/${workflowId}/followers`,
			{ followers: newFollowers },
			getHeaders()
		);
		if (response && response.data) {
			refreshEntity(response.data);
			setWorkflow({
				type: 'setWorkflow',
				payload: response.data as Workflow,
			});
		}
		return !!response && !!response.data;
	};

	return (
		<WorkflowStoreContext.Provider
			value={{
				state: {
					updateStageTask,
					updateFollowers,
					deleteComment,
					updateComment,
					updateTemplateContext,
					addToContext,
					deleteWorkflowComment: deleteWorkflowPanelComment,
					addWorkflowComment: addWorkflowPanelComment,
					addComment,
					fetchAllWorkflows,
					fetchAllStages,
					fetchAllTemplates,
					findOne,
					setStage: setStageCb,
					updateOne,
					entities: entities as Workflow[],
					templates: templates as WorkflowTemplate[],
					stages: stages as Stage[],
					userFollowedTemplates,
					updateCollection,
					stage: stage.selectedStage,
					workflow: workflow.workflow as Workflow,
					setWorkflow: setWorkflowCb,
					updateStatus,
					removeFromCollection,
					setOwner,
					canOwnerActOnWorkflow,
					allTemplates: allTemplates as WorkflowTemplate[],
					getReportData,
					revertToStage,
					rejectStage,
					fetchAvailableReports,
					getReportStats,
					totalPages,
					totalDocs,
					currentPage,
					setPage: setPageCb,
					stagesFilters,
					stagesSort,
					stagesSearchTerm,
					stagesTotalPages,
					stagesTotalDocs,
					stagesCurrentPage,
					setStagesPageState: setStagesPageCb,
					templatesTotalPages,
					templatesTotalDocs,
					templatesCurrentPage,
					setTemplatePageState: setTemplatePageCb,
					filters,
					sort,
					template,
					searchTerm,
					isFetching,
				},
			}}
		>
			{children}
		</WorkflowStoreContext.Provider>
	);
};

export const useWorkflowContext = () => {
	const Context = React.useContext(WorkflowStoreContext);
	if (!Context)
		throw new Error('Expected to be in a workflow context, but was not');
	return { ...Context.state };
};
