import React from 'react';
import Moment from 'react-moment';
import { getOriginalTargetDueDate } from '../helpers';
import { Workflow } from '../types';

type Props = {
	workflow: Workflow;
};

export const WorkflowDueDate = ({ workflow }: Props) => {
	const date = getOriginalTargetDueDate(workflow);
	if (!date) {
		return <span>TBD</span>;
	}

	return <Moment format="MMM DD" date={date} />;
};
