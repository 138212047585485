import { orderBy } from 'lodash';
import * as R from 'ramda';

export const alphabetizeBy = (property: string, workflows: any[]) =>
	orderBy(workflows, (a) => a[property], 'asc');
export const reverseAlphabetizeBy = (property: string) => <T>(list: T[]) =>
	R.reverse(alphabetizeBy(property, list));

// sort alpha by property but case insensitive and ignore punctuation
export const stageAlphaSortByWorkflowTitle = (
	stages: any[],
	reverse: boolean = false
) => {
	let sorted = stages.sort((a, b) =>
		a.workflowTitle.localeCompare(b.workflowTitle, 'en', { numeric: true })
	);
	if (reverse) {
		sorted = sorted.reverse();
	}
	return sorted;
};

export const stageStatusSort = (
	stages: any[],
	reverse: boolean = false
) => {
	let sorted = stages.sort((a, b) =>
		a.status.localeCompare(b.status, 'en', { numeric: true })
	);
	if (reverse) {
		sorted = sorted.reverse();
	}
	return sorted;
};

// sort alpha by property but case insensitive and ignore punctuation
export const alphaSortByWorkflowTitle = (
	workflows: any[],
	reverse: boolean = false
) => {
	let sorted = workflows.sort((a, b) =>
		a.title.localeCompare(b.title, 'en', { numeric: true })
	);
	if (reverse) {
		sorted = sorted.reverse();
	}
	return sorted;
};
