import storageModel from 'utils/models/StorageModel';
import { SiteDefaults } from 'context/useThemeContext';
const defaults = (JSON.parse(storageModel.get('SITE_DEFAULTS') as string) ||
	{}) as SiteDefaults;

interface RefinementItem {
	label: string;
	value: string[];
	count: number;
	isRefined: boolean;
}

export interface RefinementSettings {
	attribute: string;
	label: string;
	searchable: boolean;
	limit: number;
	transformItems?: (items: Array<RefinementItem>) => Array<RefinementItem>;
}

export const transformArchived = (
	items: Array<RefinementItem>
): Array<RefinementItem> => {
	return items.map((item) => {
		item.label = item.label === 'false' ? 'Active' : 'Archived';
		return item;
	});
};

// Hardcoded for now, todo make configurable.
export const damAssetRefinementSettings: readonly RefinementSettings[] = defaults?.damRefinements?.map(
	(settings) => {
		const defaultss = {
			searchable: false,
			limit: 24,
		};
		return Object.assign(defaultss, settings) as RefinementSettings;
	}
);

// TODO: Hardcoded for now, make configurable.
export const damAssetFolderRefinementSettings: readonly RefinementSettings[] = defaults?.damRefinements?.map(
	(settings) => {
		const defaultss = {
			searchable: false,
			limit: 1000,
		};
		return Object.assign(defaultss, settings) as RefinementSettings;
	}
);
