import React, { PropsWithChildren, useCallback } from 'react';
import { TabContent, TabPane } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';

import { JsxFunction } from '../utils/common';
import tabButton from '../core-ui/components/tab-button';

export interface TabProps<ContentProps = any> {
	readonly tabId: string;
	readonly label: string;
	readonly icon?: IconDefinition;
	readonly content: JsxFunction<ContentProps>;
}

export interface TabsetProps<P = any, ID = string> extends TabProps<P> {
	activeTab: ID;
}

export const TabsetContent = <P extends PropsWithChildren<TabsetProps>>({
	tabId,
	activeTab,
	children,
}: P) => {
	return (
		<TabContent activeTab={activeTab}>
			<TabPane role="tabpanel" tabId={tabId} id={`${tabId}Pane`}>
				{children}
			</TabPane>
		</TabContent>
	);
};

export interface TabsetButtonProps<P = any, ID = string>
	extends TabsetProps<P, ID> {
	selectTab: (tabId: ID) => void;
}

const TabButton = styled.button`
	${tabButton}
	width: 100%;
`;

const renderTabIcon = (icon?: IconDefinition) =>
	icon ? <FontAwesomeIcon icon={icon} className="mr-2" /> : null;

export const TabsetButton = (props: TabsetButtonProps) => {
	const { icon, label, tabId, activeTab, selectTab } = props;

	const selectThisTab = useCallback(() => selectTab(tabId), [selectTab, tabId]);

	return (
		<TabButton
			id={`${tabId}Button`}
			className={activeTab === tabId ? 'selected' : ''}
			onClick={selectThisTab}
			role="tab"
			aria-selected={activeTab === tabId}
		>
			{renderTabIcon(icon)}
			{label}
		</TabButton>
	);
};
