import styled from 'styled-components';
import themeStore from '../../../../../core-ui/models/ThemeStore';

const { colors } = themeStore.selectedTheme;

export const OwnerAvatarWrapper = styled.div`
	display: flex;
	align-items: center;
	align-self: center;
	flex-flow: row wrap;
`;

export const CardTab = styled.span`
	position: absolute;
	top: 5px;
	right: 5px;
	color: ${colors.darkestGrey};
	font-size: 14px;

	&:before {
		position: absolute;
		background: ${colors.grey};
		content: '';
		height: 89px;
		right: -60px;
		top: -60px;
		transform: rotate(45deg);
		width: 89px;
		z-index: -1;
	}
`;

export const AvatarListContainer = styled.div`
	position: relative;
	height: 24px;
`;

export const StageCard = styled.div`
	position: relative;
	&.parallel,
	&.substage {
		max-width: 100%;
	}

	&.sideTask {
		z-index: 5;
	}

	.lg {
		max-width: 100%;
	}
	@media (min-width: 768px) {
		margin: 6px;
		padding: 9px 14px;
		border: 1px solid #eeee;
		box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
	}
	background: #ffffff;
	border: 0 solid var(--darkGrey);
	border-radius: 0.5rem;
	box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
	background: ${({ stage }: any) =>
		stage && stage.phase && stage.phase.phaseColor
			? stage.phase.phaseColor
			: ` #ffffff`};

	.title {
		padding: 1.2rem;
		padding-bottom: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		border-bottom: 1px solid darkgrey;

		h2 {
			font-size: 20px;
		}

		.hours {
			position: absolute;
			left: 0;
			top: 8px;
			white-space: nowrap;
			font-size: 11px;
			padding: 0 0.5rem;
			margin-left: 0.5rem;
			border-radius: 9999px;
			color: #05505c;
			background-color: #d5f5f6;
		}
	}

	.owners {
		display: flex;
		justify-content: center;
		margin-top: 0.5rem;
	}

	.card-content {
		padding: 1.5rem;
		padding-top: 0;
	}

	.card-buttons {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding-top: 1em;

		> * {
			margin-bottom: 10px;
			@media (min-width: 768px) {
				min-width: 180px;
			}
		}
	}

	.substages {
		padding-top: 0;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;

		.substage {
			height: 100%;
			width: 100%;
			padding: 1.5em;
		}
	}

	.actions {
		width: 100%;
		display: flex;
		height: 45px;

		.button {
			width: 100%;
			height: 100%;
			border: 1px solid #d2d6dc;
			transition: all 0.3s ease-in-out;

			:hover {
				background: lightgrey;
			}

			:disabled {
				:hover {
					background: none;
					background: #efefef;
				}
				background: #efefef;
				color: lightgrey;
				cursor: not-allowed;
			}
		}
	}
`;

export const AttachmentSlotContainer = styled.div`
	.edit-input-slot {
		padding: 1.5em;
		padding-top: 0;
		border-left: 1px solid darkgrey;
	}

	.edit-slot-actions {
		display: flex;

		:first-child {
			margin-right: 10px;
		}
	}
`;
