import { faCog } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useThemeContext } from 'context/useThemeContext';
import { PageSubheading } from 'components/index';
import { useLocalStorage } from 'utils/core/hooks/usePersistedState';
import React from 'react';
import { Card, CardBody, CardFooter, CardHeader } from 'reactstrap';
import { pure } from 'recompose';

type RMCardProps = {
	icon: any;
	title: string;
	total: number;
	active: number;
	archived: number;
	onIncludeChange: (newVal: boolean) => void;
};

export const useResourceSettings = () => {
	const [
		includeGroupsWithResources,
		setIncludeGroupsWithResources,
	] = useLocalStorage('includeGroupsWithResources', false);

	return [includeGroupsWithResources, setIncludeGroupsWithResources];
};

export const RMResourcesCard = pure((props: RMCardProps) => {
	const { defaults } = useThemeContext();
	const { archived, onIncludeChange, active, total, title, icon } = props;

	return (
		<Card className="h-100 rome-card">
			<CardHeader>
				<PageSubheading text={title} />
				<FontAwesomeIcon
					className="header-icon"
					style={{ color: defaults?.primary }}
					icon={icon}
				/>
			</CardHeader>
			<CardBody>
				<div className="main-statistic text-center">
					<span className="main-statistic__value">{total}</span>{' '}
					<span className="statistic__label text-muted">Total</span>
				</div>

				<div className="minor-statistic minor-statistic__left">
					<span>{active}</span>
					<span className="statistic__label">Active</span>
				</div>

				<div className="minor-statistic minor-statistic__right">
					<span>{archived}</span>
					<span className="statistic__label">Archived</span>
				</div>
			</CardBody>

			<CardFooter>
				{
					<div className={'d-flex justify-content-end'}>
						<FontAwesomeIcon
							onClick={() => onIncludeChange(true)}
							icon={faCog}
							style={{ color: `#ccc`, fontSize: 14, cursor: `pointer` }}
						/>
					</div>
				}
			</CardFooter>
		</Card>
	);
});
