import RoadblocksTable from 'components/workflow/workflows/components/tables/RoadblockedTable';
import { Stage } from 'components/workflow/workflows/types';
import { OldWorkflowsIndexView } from 'components/workflow/workflows/OldWorkflowIndexPage';
import React, { useMemo, useState } from 'react';
import * as H from '../helpers';
import { orderBy } from 'lodash';
import { useAsync, useAxios } from 'hooks';

function sortStages(stages: Stage[], sort: string): Stage[] {
	if (sort === 'A - Z') {
		return H.alphabetizeBy('title', stages);
	} else if (sort === 'Z - A') {
		return H.reverseAlphabetizeBy('title')(stages);
	} else if (sort === 'Newest - Oldest') {
		return orderBy(
			stages,
			(a) => a.events?.find((a) => a.newStatus === 'roadblocked')?.createdAt,
			'desc'
		);
	} else if (sort === 'Oldest - Newest') {
		return orderBy(
			stages,
			(a) => a.events?.find((a) => a.newStatus === 'roadblocked')?.createdAt,
			'asc'
		);
	} else {
		return stages;
	}
}

export const Roadblocks = () => {
	const stagesStore = useAxios<Stage>('workflows/stages/roadblocked');
	const [isFetching, unsortedStages] = useAsync(
		() => stagesStore.findAll(),
		[],
		[]
	);
	const [sort, setSort] = useState('');

	const sortedStages = useMemo(() => sortStages(unsortedStages, sort), [
		unsortedStages,
		sort,
	]);

	// handleSorting
	const sortingOptions = [
		'A - Z',
		'Z - A',
		'Newest - Oldest',
		'Oldest - Newest',
	].map((val) => ({
		label: val,
		value: val,
	}));

	const views = {
		table: (stages: Stage[]) => (
			<RoadblocksTable stages={stages} isFetching={isFetching} />
		),
	};

	const sorting = {
		state: '',
		options: sortingOptions,
		fn: (sort: string) => setSort(sort),
	};

	return (
		<>
			{/* Note: This "old" component doesn't have the updated pagination/filtering  */}
			<OldWorkflowsIndexView
				title="Roadblocks"
				collection={sortedStages}
				views={views}
				sorting={sorting}
				isFetching={isFetching}
			/>
		</>
	);
};
