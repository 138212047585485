import { css } from 'styled-components';
import { focus } from '../ui/ui';

export const anchor = css`
	&:focus {
		${focus};
	}
`;

export const underlinedAnchor = css`
	${anchor};
	text-decoration: underline;

	&:hover {
		text-decoration: none;
	}
`;

export default anchor;
