import {
	faCrown,
	faMinus,
	faPlus,
	faStar,
	faUser,
	faUserCheck,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UserGroup } from 'components/accounts/types';
import { useThemeContext } from 'context/useThemeContext';
import { UserAvatar } from 'components/admin-dashboard/components/user-details.styled-components';
import { useAuthContext, useGroupContext, User } from 'utils/auth';
import { Heading, Subheading } from 'components/index';
import { RomeSwal } from 'components/alert';
import CircleButton from 'components/circle-button.component';
import { GroupAvatarLg } from 'components/group-avatar.component';
import { AnchorSpan } from 'components/dashboard.component/components/proxy-panel.styled.component';
import { uniqBy } from 'lodash';
import React from 'react';
import {
	Card,
	CardBody,
	CardHeader,
	Container,
	FormText,
	ListGroup,
	ListGroupItem,
	PopoverBody,
} from 'reactstrap';
import { flattenStages } from './helpers';
import { useWorkflowContext } from '../../../context/useWorkflowStore';
import { BaseWorkflowOwner, Flattenable, Workflow } from './types';
import { AddWorkflowFollowerDialog } from './WorkflowAddFollwerDialog';
import { RoleContext } from '../../../context/PermissionsContext';
import RenderWhen from '../../render-when.component';

const UserList = ({
	followers,
	workflow,
	hideLabels,
	updatedFollowerCallBack,
}: {
	followers: BaseWorkflowOwner[];
	workflow: Workflow;
	hideLabels?: boolean;
	updatedFollowerCallBack?: (newFollowers: BaseWorkflowOwner[]) => void;
}) => {
	const { entities } = useAuthContext();
	const { entities: groups } = useGroupContext();
	const { defaults } = useThemeContext();
	const { canViewByRole } = React.useContext(RoleContext);

	const doesUserOwnStage = (user: User | UserGroup) => {
		return {
			stage: flattenStages(workflow as Flattenable)
				?.filter((a) => a?.owners?.some((owner) => owner._id === user?._id))
				.map((a) => a.title)
				.join(','),
		};
	};

	const removeFollower = (user: User | UserGroup) => {
		if (!updatedFollowerCallBack) return;

		const updatedFollowers = followers.filter((a) => a._id !== user._id);
		updatedFollowerCallBack(updatedFollowers as BaseWorkflowOwner[]);
	};
	const mapFollowerToUser = (follower: BaseWorkflowOwner) => {
		if (!follower) return;
		if (follower.type === 'AccountGroup')
			return groups?.find((a) => a._id === follower._id)!;
		if (follower.type === 'AccountUser')
			return entities?.find((a) => a._id === follower._id)!;
	};
	const [collapseMap, setCollapseMap] = React.useState(
		followers.map((a) => ({ ...a, isCollapsed: false }))
	);
	return (
		<ListGroup>
			{uniqBy(followers, (o) => o._id)
				?.map(mapFollowerToUser)
				.map((user, idx) => (
					<ListGroupItem key={`user_${idx}_${user?._id}`}>
						<div className="d-flex justify-between">
							{!!(user as User)?.email && (
								<UserAvatar size="lg" user={user as User} />
							)}
							{!!(user as UserGroup)?.members && (
								<GroupAvatarLg size="lg" group={user as UserGroup} />
							)}
							<div
								style={{
									paddingLeft: 10,
									marginLeft: 5,
									color: '#222',
									overflow: 'hidden',
									width: `100%`,
								}}
							>
								{!hideLabels && (
									<div
										style={{
											overflow: 'hidden',
											textOverflow: 'ellipsis',
											whiteSpace: 'nowrap',
										}}
									>
										{!doesUserOwnStage(user as User | UserGroup)?.stage &&
											(user as User)?._id !== workflow?.createdBy?._id && (
												<div className="d-flex">
													<FormText color={'info'} style={{ display: 'block' }}>
														<strong>FOLLOWER &nbsp;</strong>
														<FontAwesomeIcon icon={faUserCheck} />
													</FormText>
												</div>
											)}
										{doesUserOwnStage(user as User | UserGroup).stage && (
											<div className="d-flex">
												<FormText color={'info'} style={{ display: 'block' }}>
													<strong>STAGE OWNER OF&nbsp;</strong>
													{doesUserOwnStage(user as User | UserGroup).stage}
													<FontAwesomeIcon icon={faStar} />
												</FormText>
											</div>
										)}
										{(user as User)?._id === workflow?.createdBy?._id && (
											<div className="d-flex">
												<FormText color={'danger'} style={{ display: 'block' }}>
													<strong>CREATOR&nbsp;</strong>
													<FontAwesomeIcon icon={faCrown} />
												</FormText>
											</div>
										)}
									</div>
								)}
								<div style={{ color: defaults?.primary, fontSize: 12 }}>
									<div className="d-flex align-items-center">
										<p>
											{(user as User)?.givenName} {(user as User)?.familyName}
										</p>

										<FormText className="d-flex">
											{(user as UserGroup)?.members && (
												<div className="w-100 m-auto">
													<>
														({user?.title ?? (user as User)?.expertise}){' '}
														<AnchorSpan
															onClick={(e) => {
																e.stopPropagation();
																e.preventDefault();
																setCollapseMap(
																	collapseMap.map((item) =>
																		item._id === (user as UserGroup)._id
																			? {
																					...item,
																					isCollapsed: item.isCollapsed
																						? false
																						: true,
																			  }
																			: item
																	)
																);
															}}
														>
															Members
														</AnchorSpan>
													</>
													{collapseMap.some(
														(a) =>
															a._id === (user as UserGroup)._id && a.isCollapsed
													) && (
														<PopoverBody>
															<UserList
																hideLabels
																followers={(user as UserGroup).members.map(
																	(a) => ({ type: 'AccountUser', _id: a._id })
																)}
																workflow={workflow}
															/>
														</PopoverBody>
													)}
												</div>
											)}
										</FormText>
									</div>
									{updatedFollowerCallBack && (
										<small
											style={{
												position: 'absolute',
												right: 5,
												top: '45%',
												display: 'block ',
											}}
										>
											<RenderWhen when={['wfEditable'].some(canViewByRole)}>
												<CircleButton
													className="sm"
													color="danger"
													id="addFollowerToWorkflow"
													icon={faMinus}
													onClick={() => removeFollower(user as User)}
													tooltip="Remove follower"
												/>
											</RenderWhen>
										</small>
									)}
								</div>
							</div>
						</div>
					</ListGroupItem>
				))}
		</ListGroup>
	);
};

type FollowersPanelProps = {
	workflow: Workflow;
	followers: BaseWorkflowOwner[];
};

export const WorkflowFollowersPanel = React.memo(
	(props: FollowersPanelProps) => {
		const [showAddFollower, setShowAddFollower] = React.useState(false);
		const { workflow, followers } = props;
		const { updateFollowers } = useWorkflowContext();
		const { canViewByRole } = React.useContext(RoleContext);

		const updatedFollowerCallBack = async (
			newFollowers: BaseWorkflowOwner[]
		) => {
			const success = await updateFollowers(workflow._id, newFollowers);
			setShowAddFollower(false);
			RomeSwal.fire({
				icon: success ? 'success' : 'error',
				title: success ? 'Successfully updated!' : 'An error occurred!',
				text: success
					? 'Successfully updated ' + workflow.title + ' followers.'
					: 'An error occurred when trying to update ' +
					  workflow.title +
					  ' followers. Please try again.',
			});
		};
		return (
			<Container fluid>
				{showAddFollower && (
					<AddWorkflowFollowerDialog
						workflow={workflow}
						followers={followers}
						onConfirm={updatedFollowerCallBack}
						onCancel={() => setShowAddFollower(false)}
					/>
				)}
				<Card>
					<CardHeader>
						<Heading className="d-flex align-items-center">
							{workflow.title}
						</Heading>
						<Subheading>
							Workflow Followers <FontAwesomeIcon icon={faUser} />{' '}
						</Subheading>
					</CardHeader>
					<CardBody>
						<RenderWhen when={['wfEditable'].some(canViewByRole)}>
							<div className="d-flex justify-content-end">
								<div
									style={{
										display: 'block',
										marginRight: 15,
										marginBottom: 20,
									}}
								>
									{' '}
									Edit Followers
									<CircleButton
										className="sm"
										color="danger"
										id="addFollowerToWorkflow"
										icon={faPlus}
										onClick={() => setShowAddFollower(true)}
										tooltip="Edit followers"
									/>
								</div>
							</div>
						</RenderWhen>

						{followers.length === 0 && (
							<p>No followers have been added to the workflow.</p>
						)}
						<UserList
							updatedFollowerCallBack={updatedFollowerCallBack}
							workflow={workflow}
							followers={followers}
						/>
					</CardBody>
				</Card>
			</Container>
		);
	}
);
