import { navigate } from '@reach/router';
import React from 'react';
import { NotificationsContext } from '../../../notifications';
import { EntityMetadataTemplate } from '../../../workflow/workflows/types/workflow.types';
import { useAxios } from '../../../../hooks/useAxios';
import EntityMetadataTemplateEditForm from './entity-metadata-template-edit-form.component';
import { buildEmptyAssetMetadata } from 'components/dam-assets/components/helpers/useAssetHelper';
import { useAuthContext } from 'utils/auth';
import { generateID } from 'utils/common';

const EntityMetadataTemplateAddForm = () => {
	const { currentUser } = useAuthContext();
	const emptyMetadataTemplate = () =>
		({
			...buildEmptyAssetMetadata,
			createdBy: currentUser._id,
			title: '',
			_id: generateID(),
		} as EntityMetadataTemplate);
	const { info, error } = React.useContext(NotificationsContext);

	const [metadataTemplateRef] = React.useState(emptyMetadataTemplate());
	const api = useAxios<EntityMetadataTemplate>('metadata-template');

	const addMetadataTemplate = async (meta: EntityMetadataTemplate) => {
		try {
			const createdMetadataTemplate = (await api.createOne(
				meta
			)) as EntityMetadataTemplate;

			info(`${createdMetadataTemplate.title} saved!`);

			return navigate(
				`/admin/dashboard/metadata-templates/${createdMetadataTemplate._id}`
			);
		} catch (err) {
			// @ts-ignore
			error(
				err?.message
					? err.message
					: 'Failed to create new workflow. Please try again later'
			);
		}
	};

	return (
		<EntityMetadataTemplateEditForm
			selectedMetadataTemplate={metadataTemplateRef}
			onSubmit={addMetadataTemplate}
			displayTemplateField={false}
		/>
	);
};

export default EntityMetadataTemplateAddForm;
