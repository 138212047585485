import { Col } from 'reactstrap';
import React, { useCallback } from 'react';

import AssetCard from './asset-card.component';
import { AlgoliaAssetVersion } from './asset-infinite-hits.component';

interface Props {
	// flags
	areLinks?: boolean;
	isSelectionAvailable?: boolean;
	// props
	assets: AlgoliaAssetVersion[];
	collectionId?: string;
	onAssetSelected?: (assets: AlgoliaAssetVersion) => void;
	selectedAssets?: AlgoliaAssetVersion[];
	onDelete: (a: AlgoliaAssetVersion) => void;
	onRemoveFromCollection?: (a: AlgoliaAssetVersion) => void;
}

const AssetCardGrid = (props: Props) => {
	const {
		areLinks,
		assets,
		isSelectionAvailable,
		onAssetSelected,
		selectedAssets,
	} = props;
	const areLinksEnabled = () => {
		if (areLinks === undefined) {
			return true;
		} else {
			return areLinks;
		}
	};

	const isCardSelected = (asset: AlgoliaAssetVersion) => {
		if (selectedAssets?.find((x) => x._id === asset._id) !== undefined) {
			return true;
		} else {
			return false;
		}
	};

	const clickCallBack = useCallback(
		(asset: AlgoliaAssetVersion) => {
			if (isSelectionAvailable && onAssetSelected) {
				onAssetSelected(asset);
			}
		},
		[isSelectionAvailable, onAssetSelected]
	);

	const render = () => (
		<>
			{assets.map((asset) => (
				<Col lg={3} md={6} className="mt-2 mb-3" key={asset?._id}>
					<AssetCard
						type="uploaded"
						workflowId={asset?.workflowId}
						assetVersion={asset}
						collectionId={props.collectionId}
						showUploadedDate
						onClick={clickCallBack}
						isLinkEnabled={areLinksEnabled()}
						isSelected={isCardSelected(asset)}
						onDelete={() => props.onDelete(asset)}
						onRemoveFromCollection={() =>
							props.onRemoveFromCollection &&
							props.onRemoveFromCollection(asset)
						}
						hideCustomPreview={false}
					/>
				</Col>
			))}
		</>
	);

	return render();
};

export default AssetCardGrid;
