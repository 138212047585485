import { NotificationsContext } from 'components/notifications';
import { BaseOwnerSelect } from 'components/workflow/workflow-templates/components/owner-select.component';
import { uniqBy } from 'lodash';
import React from 'react';
import { useForm } from 'react-hook-form';
import { ModalBody } from 'reactstrap';
import { useAuthContext, useGroupContext } from 'utils/auth';
import themeStore from '../../../../../core-ui/models/ThemeStore';
import { _logError } from '../../../../../utils/common/log';
import { FormModal } from '../../../../Modal';
import { useWorkflowContext } from '../../../../../context/useWorkflowStore';
import { BaseWorkflowOwner, Stage, Workflow } from '../../types/workflow.types';

interface Props {
	isOpen: boolean;
	onClose: () => void;
}

const AssignStageOwnerDialog = ({ isOpen, onClose }: Props) => {
	const form = useForm();
	const {
		stage: updatedStage,
		setStage: setUpdatedStage,
		workflow,
	} = useWorkflowContext();

	const [owners, setOwners] = React.useState(updatedStage?.owners);
	const { info, warn, error } = React.useContext(NotificationsContext);
	const isModalOpen = React.useMemo(() => {
		if (isOpen) {
			return true;
		} else {
			return false;
		}
	}, [isOpen]);

	const stageTerm = themeStore._.stage;
	const ownerTerm = themeStore._.owner;

	const { setOwner } = useWorkflowContext();
	const updateStageSubmit = async () => {
		try {
			const updated = (await setOwner(
				'setOwner',
				owners as BaseWorkflowOwner[],
				updatedStage as Stage,
				workflow as Workflow
			)) as Workflow;
			onClose();
			setUpdatedStage({
				...(updated?.stages?.find((m) => m._id === updatedStage?._id) as Stage),
				owners: owners as BaseWorkflowOwner[],
			});
			info(`${stageTerm} saved!`);
		} catch (e) {
			onClose();
			_logError(e);
			// @ts-ignore
			if (e?.data?.message) {
				// @ts-ignore
				error(e.data.message);
			} else {
				warn(
					`An issue occurred while updating ${stageTerm.toLowerCase()}. Please try again later.`
				);
			}
		}
	};

	const ORG = process.env.REACT_APP_ORG;
	const { entities: groups } = useGroupContext();
	const { entities: users } = useAuthContext();

	const groupOptions = React.useMemo(() => {
		return (updatedStage: Stage) =>
			uniqBy(
				updatedStage?.owners
					?.filter((g) => g.type === 'AccountGroup')
					.flatMap(
						(owner) => groups?.find((a) => a._id === owner._id)?.members
					),
				(a) => a?._id
			);
	}, [groups]);

	const selectOptions = React.useMemo(() => {
		// @ts-ignore
		return updatedStage?.owners?.some((a) => a.type === 'AccountGroup')
			? groupOptions(updatedStage)
			: [...(users ? users : []), ...(groups ? groups : [])];
	}, [updatedStage, groupOptions, users, groups]);

	return (
		<FormModal
			isOpen={isModalOpen}
			onClose={onClose}
			cancelText={'Cancel'}
			title={`Edit ${ownerTerm}s`}
			submitText={'Update'}
			form={form}
			onSubmit={updateStageSubmit}
		>
			<ModalBody>
				{ORG && ORG === 'PDL' && (
					<BaseOwnerSelect
						options={selectOptions}
						onChange={(ownable) => setOwners(ownable.payload)}
						owners={updatedStage?.owners as BaseWorkflowOwner[]}
					/>
				)}
				{(!ORG || ORG !== 'PDL') && (
					<BaseOwnerSelect
						onChange={(ownable) => setOwners(ownable.payload)}
						owners={updatedStage?.owners as BaseWorkflowOwner[]}
					/>
				)}
			</ModalBody>
		</FormModal>
	);
};

export default AssignStageOwnerDialog;
