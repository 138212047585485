import React, { useEffect, useState } from 'react';
import Container from '@material-ui/core/Container';
import { useAssetHelper } from './components/helpers/useAssetHelper';
import LoadingIndicator from 'core-ui/components/LoadingIndicator/LoadingIndicator';
import { FullWidthButton } from './components/asset-details-sidebar/asset-details-tab-set.styled-components';
import styled from 'styled-components';
import { useFetching } from 'hooks/useFetching';
import { NotificationsContext } from 'components/notifications';
import RenderWhen from 'components/render-when.component';

const Button = styled<any>(FullWidthButton)`
	white-space: nowrap;
	padding: 0.5rem;
	width: 170px;
`;

const AssetTest = () => {
	const [Assets, setAssets] = useState([]);
	const [deletedAssets, setDeletedAssets] = useState<string[]>([]);
	const [previews, setPreviews] = useState([]);
	const [AlgoliaIndexDeleted, setAlgoliaIndexDeleted] = useState<string[]>([]);
	const [AlgoliaIndexRemaining, setAlgoliaIndexRemaining] = useState<string[]>(
		[]
	);

	const [isLoaded, setIsLoaded] = useState(false);

	const {
		runAssetTest,
		runAssetCompare,
		updateAssetMeta,
		removeIndexes,
		runAssetPreviewReport,
		removeAssetsFromDB,
		deletePreviewFromStorage,
	} = useAssetHelper();
	const { isFetching, beginFetching, finishFetching } = useFetching();
	const { info } = React.useContext(NotificationsContext);

	const clearReport = async () => {
		setAssets([]);
		setDeletedAssets([]);
		setPreviews([]);
		setAlgoliaIndexDeleted([]);
		setAlgoliaIndexRemaining([]);
	};

	const AssetReport = async () => {
		clearReport();
		beginFetching();
		runAssetTest('false')
			.then((e) => setAssets(e.data))
			.finally(finishFetching);

		setIsLoaded(true);
		return true;
	};

	const deletedAssetReport = async () => {
		clearReport();
		beginFetching();
		runAssetTest('true')
			.then((e) => setDeletedAssets(e.data))
			.finally(finishFetching);

		setIsLoaded(true);
		return true;
	};

	const removeDeletedAssetsfromDB = async () => {
		beginFetching();
		removeAssetsFromDB(deletedAssets).finally(finishFetching);

		setIsLoaded(true);
		return true;
	};

	const AssetPreviewReport = async () => {
		clearReport();
		beginFetching();
		runAssetPreviewReport()
			.then((e) => setPreviews(e.data))
			.finally(finishFetching);

		setIsLoaded(true);
		return true;
	};

	const deletePreviewFiles = async () => {
		beginFetching();
		deletePreviewFromStorage(previews).then(finishFetching);

		setIsLoaded(true);
		return true;
	};

	const removeUnusedIndexes = async () => {
		clearReport();
		beginFetching();
		await removeIndexes().finally(finishFetching);
		setIsLoaded(true);
		info('Finished removing unused indexes');
		return true;
	};

	const AlgoliaAssetReport = async () => {
		clearReport();
		beginFetching();
		runAssetCompare()
			.then((e) => {
				setAlgoliaIndexRemaining(e.data.remainingAssets);
				setAlgoliaIndexDeleted(e.data.deletedAssets);
			})
			.finally(finishFetching);

		setIsLoaded(true);
		return true;
	};

	const updateAssetMetadata = async () => {
		clearReport();
		updateAssetMeta();
		info('Finished re-indexing asset metadata');
		return;
	};

	return (
		<Container>
			{isFetching && <LoadingIndicator dismiss={false} />}
			<div>
				<h1>Asset Reports</h1>
				<br />
				<Button onClick={AssetReport}>Run Asset Report</Button>
				<br />
				<span className={'px-2'}>
					Lists paths to assets that have references in database but could not
					be found in azure storage.
				</span>
				<hr />

				<Button onClick={deletedAssetReport}>Run Deleted Asset Report</Button>
				<RenderWhen when={deletedAssets.length > 0}>
					<span className={'px-2'}>
						<Button onClick={removeDeletedAssetsfromDB}>
							Remove Asset Files
						</Button>
					</span>
				</RenderWhen>
				<br />
				<span className={'px-2'}>
					Lists paths to assets that have been deleted by user action where the
					file was removed from azure but references to it still remain in the
					database. <br />
				</span>
				<hr />

				<Button onClick={AssetPreviewReport}>Run Asset Preview Report</Button>
				<RenderWhen when={previews.length > 0}>
					<span className={'px-2'}>
						<Button onClick={deletePreviewFiles}>Delete Preview Files</Button>
					</span>
				</RenderWhen>
				<br />

				<span className={'px-2'}>
					Lists paths to asset previews with no corresponding record in the
					database.
				</span>

				<hr />

				<Button onClick={AlgoliaAssetReport}>Run Algolia Report</Button>
				<br />
				<span className={'px-2'}>
					Lists fileNames for records in Algolia that do not have a
					corresponding reference to any assets in the DAM collection of the
					database. This could have been caused by user deletions where these
					Algolia records were not correctly removed.
				</span>
				<hr />

				<Button onClick={removeUnusedIndexes}>Remove un-used indexes</Button>
				<br />
				<span className={'px-2'}>
					Runs a function that removes such orphaned records listed by the above
					'Algolia Report'
				</span>
				<hr />

				<Button onClick={updateAssetMetadata}>Update Asset Metadata</Button>
				<br />
				<span className={'px-2'}>
					Runs a function that goes through all asset records in the DAM and
					adds a corresponding record to Algolia if one currently does not
					exist.
				</span>
				<hr />

				<div>
					<ol>
						{Assets?.map((path) => (
							<li>{path}</li>
						))}
					</ol>
				</div>

				<div>
					<ol>
						{deletedAssets?.map((path) => (
							<li>{path}</li>
						))}
					</ol>
				</div>

				<div>
					<ol>
						{previews?.map((previewPath) => (
							<li>{previewPath}</li>
						))}
					</ol>
				</div>

				<div hidden={AlgoliaIndexRemaining?.length === 0}>
					Remaining File Names
					<ol>
						{AlgoliaIndexRemaining?.map((fileName) => (
							<li>
								<span>{fileName}</span>
							</li>
						))}
					</ol>
				</div>

				<div hidden={AlgoliaIndexDeleted?.length === 0}>
					Deleted File Names
					<ol>
						{AlgoliaIndexDeleted?.map((fileName) => (
							<li>
								<span>{fileName}</span>
							</li>
						))}
					</ol>
				</div>
			</div>
		</Container>
	);
};

export default AssetTest;
