import React from 'react';
import { useAuthContext, useGroupContext, User } from 'utils/auth';
import styled from 'styled-components';
import Moment from 'react-moment';
import { useTemplateContext } from 'context/useTemplateContext';
import {
	EntityPropLabel,
	EntityPropList,
	EntityPropListItem,
} from '../../../../entity-details.styled-components';
import EntityMetadataFields from '../../../../metadata/components/entity-metadata-fields.component';
import themeStore from '../../../../../core-ui/models/ThemeStore';
import {
	EntityMetadata,
	TemplatePhase,
} from '../../../workflows/types/workflow.types';
import { PhaseIndicator } from '../template-phase-key.styled-components';
import ConfirmationDialog from 'components/modals/confirmation-dialog.component';
import { useModalCloser, useModalCreator } from 'utils/ModalStack';
import { SecondaryButton } from 'components/buttons.styled-components';

const Wrapper = styled.div`
	margin-left: 20px;
	margin-top: 10px;
`;

export const TemplateBaseFinalDetails = () => {
	const { template, saveTemplate } = useTemplateContext();
	const { entities: users, currentUser } = useAuthContext();
	const { entities: groups } = useGroupContext();

	const isCreator =
		typeof template?.createdBy === 'object'
			? (template?.createdBy as User)._id === currentUser._id
			: (template?.createdBy as string) === currentUser._id;

	const listCreator = () => {
		if (!users) return '';
		if (typeof template?.createdBy === 'string') {
			const creator = users.find((m) => m._id === template.createdBy);
			return `${creator?.givenName} ${creator?.familyName}`;
		} else {
			const creator = template?.createdBy as User;
			if (creator && creator.hasOwnProperty('givenName')) {
				return `${creator.givenName} ${creator.familyName}`;
			}
		}
	};

	const listFollowers = React.useMemo(() => {
		const mapUserName = ({ _id, type }: { _id: string; type: string }) =>
			type === 'AccountUser'
				? users?.find((u) => u._id === _id)?.givenName +
				  ' ' +
				  users?.find((u) => u._id === _id)?.familyName
				: groups?.find((g) => g._id === _id)?.title;
		if (template?.followers && template?.followers?.length) {
			return template?.followers.map(mapUserName).join(', ');
		}
		return 'No followers found.';
	}, [template, groups, users]);

	const modalOpener = useModalCreator();
	const modalCloser = useModalCloser();

	const Dialog = () => {
		const confirm = async () => {
			if (template) {
				await saveTemplate({ ...template, editingState: 'draft' });
			}
		};
		return (
			<ConfirmationDialog
				onConfirm={confirm}
				onCancel={() => {
					modalCloser.closeModal();
					return;
				}}
				header="Update template status to draft"
				cancelText="Cancel"
				confirmText="Update template"
			>
				<p>
					Changing the templates status back to draft will also affect
					workflows. Any newly added workflows will not be able to use the
					template until it is finalized again. This action is irreversible.
				</p>
			</ConfirmationDialog>
		);
	};

	const showModal = () => modalOpener.addModal(<Dialog />);

	return (
		<Wrapper>
			<EntityPropList>
				{isCreator && (
					<EntityPropListItem>
						<EntityPropLabel>
							In order to edit the template, you must update its state from
							"finalized" to "draft".
						</EntityPropLabel>
					</EntityPropListItem>
				)}
				{isCreator && (
					<EntityPropListItem>
						<EntityPropLabel>
							<SecondaryButton onClick={showModal}>
								Revert to Draft
							</SecondaryButton>
						</EntityPropLabel>
					</EntityPropListItem>
				)}

				<EntityPropListItem>
					<EntityPropLabel>Created on</EntityPropLabel>
					<p>
						{template && (
							<Moment format="MMM DD, YYYY" date={template.createdAt} />
						)}
					</p>
				</EntityPropListItem>

				<EntityPropListItem>
					<EntityPropLabel>Created by</EntityPropLabel>
					<p>{listCreator()}</p>
				</EntityPropListItem>

				<EntityPropListItem>
					<EntityPropLabel>Template #</EntityPropLabel>
					{template && <p>{template._id}</p>}
				</EntityPropListItem>

				<EntityPropListItem>
					<EntityPropLabel>Followers</EntityPropLabel>
					<p>{listFollowers}</p>
				</EntityPropListItem>

				{template && (
					<EntityMetadataFields
						metadata={template.metadata as EntityMetadata}
					/>
				)}

				{template?.phases && template?.phases?.length ? (
					<EntityPropListItem>
						<EntityPropLabel>{themeStore._.phase}s</EntityPropLabel>
						{template.phases.map((phase: TemplatePhase) => (
							<div key={phase._id} className="d-flex">
								<PhaseIndicator background={phase.color} />
								<p>{phase.title}</p>
							</div>
						))}
					</EntityPropListItem>
				) : null}
			</EntityPropList>
		</Wrapper>
	);
};
