import { keysIn } from 'lodash';
import { AssetVersion } from '../components/workflow/workflows/types/workflow.types';

/**
 * Processes an asset to generate a more usable object with additional properties.
 *
 * @description
 * The function takes an asset as an input, processes it and returns an object with more properties
 * that are easy to use. For instance, it generates a `previewURL` from the given asset's details.
 * It also includes the `metadata` fields, `tags` and other properties. The `createdAt` time is
 * converted to a JavaScript Date object. The function also creates `_id` property by removing the
 * 'DAMAsset_' prefix from the `objectID`.
 *
 * @param asset - The input asset that needs to be processed. It is assumed to have properties like
 * `preview`, `previewURL`, `workflowId`, `account`, `__queryID`, `metadata`, `tags`, `objectID`,
 * and `createdAt`.
 *
 * @returns Returns an object with the original asset properties and additional properties including
 * `previewURL`, `__queryID`, `preview`, `metadata`, `objectID`, `createdAt`, and `_id`.
 */
export const processAsset = (asset: any) => {
	const previewReplacement = asset?.preview
		?.replace('_preview/', '')
		.replace('.jpg', '')
		.replace('.jpeg', '');
	const previewURL =
		asset.previewURL ||
		`${process.env.REACT_APP_ROME_API_ENDPOINT}/preview/${
			asset.workflowId ? 'w' : 'd'
		}/${asset.account}/${previewReplacement}`;

	return {
		...(asset as AssetVersion),
		__queryID: asset.__queryID,
		preview: asset?.preview,
		previewURL,
		metadata: {
			fields: keysIn(asset.metadata),
			values: asset.metadata,
			fieldOptions: {},
			fieldTypes: [],
			tags: asset.tags,
		},
		objectID: asset.objectID,
		createdAt: new Date((asset.createdAt as number) * 1000),
		_id: asset.objectID.replace('DAMAsset_', ''),
	};
};
