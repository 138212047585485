import React, { useEffect, useState } from 'react';
import * as R from 'ramda';
import {
	Workflow,
	WorkflowTemplate,
} from 'components/workflow/workflows/types';
import { WorkflowsIndexView } from 'components/workflow/workflows/WorkflowIndexPage';
import { WorkflowCard, WorkflowsTable } from '../components';
import { useWorkflowContext } from 'context/useWorkflowStore';
import { orderBy } from 'lodash';
import { usePageHistory } from 'context/PageHistoryContext';
import { useAxios } from 'hooks';
import { useQueryStoreContext } from 'context/useQueryStore';

export const AllWorkflows = () => {
	const queryStore = useQueryStoreContext();
	const workflowQuery = queryStore.entities.find((a) => a.type === 'workflows');

	const pageHistory = usePageHistory();
	const {
		entities,
		totalDocs,
		template,
		setPage,
		isFetching,
		fetchAllWorkflows,
		totalPages:wfTotalPages
	} = useWorkflowContext();
	const [chosenTemplate, setChosenTemplate] = useState<string>(
		workflowQuery?.templates ?? ''
	);
	const templateStore = useAxios<WorkflowTemplate>('templates');
	const [templates, setTemplates] = useState<WorkflowTemplate[]>();
	const [workflows, setWorkflows] = useState<Workflow[]>(entities);
	const [ran, setRan] = useState(false);
	const [searchTermState, setSearchTermState] = useState(workflowQuery?.value??'');
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState(wfTotalPages);
	const [sort, setSort] = useState<string>(workflowQuery?.sort ?? '');

	const [defaultFilter, setDefaultFilter] = useState(
		workflowQuery?.filters ?? ['all']
	);

	useEffect(() => {
		setSort(sort)
		setPage({ sort });
	}, [sort]);

	useEffect(() => {
		setPage({ template: chosenTemplate });
	}, [chosenTemplate]);

	useEffect(() => {
		fetchAllWorkflows(currentPage, defaultFilter, sort, chosenTemplate, searchTermState);
	}, [defaultFilter, currentPage, sort, chosenTemplate, searchTermState]);

	useEffect(() => {
		setTotalPages(wfTotalPages);
		setWorkflows(entities);
	}, [entities]);

	useEffect(() => {
		if (!defaultFilter.length && !window.location.hash) {
			setDefaultFilter(['all']);
			handleFilter(['all']);
		}
		//eslint-disable-next-line
	}, [defaultFilter]);

	const options = React.useMemo(() => {
		if (templates) {
			return orderBy(
				templates
					.filter((t) => t.editingState === 'final')
					.map((template) => ({ label: template.title, value: template._id })),
				(a) => a.label,
				'asc'
			);
		}
		return [];
	}, [templates]);

	useEffect(() => {
		templateStore.findAll().then(setTemplates);
		//eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (!entities) return;
		if (!!ran) return;
		handleFilter(defaultFilter);
		setRan(true);

		//eslint-disable-next-line
	}, [entities]);

	useEffect(() => {
		const page = {
			path: window.location.href,
			state: [
				{
					key: 'defaultFilter',
					value: defaultFilter,
				},
			],
		};

		pageHistory.updateState(page, 'update');

		if (!defaultFilter.length && !window.location.hash) {
			setDefaultFilter(['all']);
			handleFilter('all');
		}
		//eslint-disable-next-line
	}, [defaultFilter]);

	// handleSorting
	const sortingOptions = [
		'A - Z',
		'Z - A',
		'Due Date (Newest - Oldest)',
		'Due Date (Oldest - Newest)',
	].map((val) => ({ label: val, value: val }));
	// Sort Handler
	const handleSort = (sorter: string) => {
		setSort(sorter);
		setPage({ sort: sorter });
	};

	// filterOptions
	const filterOptions = [
		['All Workflows', 'all'],
		['Pipeline', 'pipeline'],
		['Active', 'active'],
		['Paused', 'paused'],
		['Cancelled', 'cancelled'],
		['Completed', 'completed'],
		['Healthy', 'healthy'],
		['Overdue', 'overdue'],
		['Roadblocked', 'roadblocked'],
		['Created by me', 'createdBy'],
		['Collaborating', 'collaborating'],
		['Following', 'following'],
	].map(([label, value]) => ({ label, value }));
	// handle Filtering
	const handleFilter = (value: string | string[]) => {
		const filters = Array.isArray(value) ? value : [value];
		setDefaultFilter(filters);
	};

	const handleChosentemplates = (value: string) => {
		setChosenTemplate(value);
	};

	const views = {
		cards: (workflows: Workflow[]) =>
			workflows.map((workflow) => (
				<WorkflowCard key={workflow?._id} workflow={workflow} />
			)),
		table: (workflows: Workflow[]) => (
			<WorkflowsTable workflows={workflows} isFetching={isFetching} />
		),
	};
	const sortingConfig = {
		options: sortingOptions,
		fn: handleSort,
	};
	const filtersConfig = {
		options: filterOptions,
		fn: handleFilter,
	};

	const chosenTemplates = {
		state: chosenTemplate,
		options,
		fn: handleChosentemplates,
	};

	return (
		<WorkflowsIndexView
			template={chosenTemplates}
			defaultFilter={defaultFilter}
			defaultSort={sort}
			title="Workflows"
			collection={workflows}
			sorting={sortingConfig}
			filters={filtersConfig}
			views={views}
			saveQuery
			create
			isFetching={isFetching}
			searchTerm={searchTermState}
			setSearchTerm={setSearchTermState}
			currentPage={currentPage}
			setCurrentPage={setCurrentPage}
			totalPages={totalPages}
			totalDocs={totalDocs}
		/>
	);
};
