import { RouteComponentProps } from '@reach/router';
import { flattenDeep, isArray } from 'lodash';
import React, { useEffect } from 'react';
import { Col, Container, Row } from 'reactstrap';
import CommonAssetVersionDisplay from '../../dam-assets/components/common-asset-version-display.component';
import LoadingAssetState from '../../dam-assets/components/loading-asset-state.component';
import { flattenStages } from './helpers/workflowStage.helpers';
import { InputSlot } from './types/workflow.types';
import { Stage } from './types/workflow.types';
import { AssetVersion, Workflow } from './types/workflow.types';

interface WorkflowAssetVersionDetailsProps extends RouteComponentProps {
	versionId?: string;
	workflow: Workflow;
}

const WorkflowAssetVersionDetailsView = (
	props: WorkflowAssetVersionDetailsProps
) => {
	const { versionId, workflow } = props;

	useEffect(() => {
		const path = window.location.pathname.slice(0,-25);

		window.history.replaceState(
			null,
			document.title,
			path
		);
	}, []);

	const render = () => {
		if (!versionId) {
			return <LoadingAssetState />;
		}

		const version = flattenStages(workflow)
			?.find((stage: Stage | Stage[]) => {
				if (!isArray(stage))
					return stage?.inputSlots?.some((m: InputSlot) =>
						m.versions?.some(
							(version: AssetVersion) => version._id === versionId
						)
					);
				else {
					return stage
						?.map((sub) => sub.inputSlots)
						.some((slot) =>
							flattenDeep(slot)?.some((slotAgain: InputSlot) =>
								slotAgain?.versions?.some(
									(version: AssetVersion) => version._id === versionId
								)
							)
						);
				}
			})
			?.inputSlots?.find((slot: InputSlot) =>
				slot.versions?.some(
					(version: AssetVersion) => version._id === versionId
				)
			)
			?.versions?.find(
				(version: AssetVersion) => version._id === versionId
			) as AssetVersion;

		return (
			<Container>
				<Row className="justify-content-center m-md-4">
					<Col md={12}>
						{version ? (
							<CommonAssetVersionDisplay
								damAsset={version}
								isAssetVersion={false}
								onDelete={() => {
									return;
								}}
								workflowId={workflow._id}
								version={version}
								workflow={workflow}
							/>
						) : (
							<LoadingAssetState />
						)}
					</Col>
				</Row>
			</Container>
		);
	};

	return render();
};

export default WorkflowAssetVersionDetailsView;
