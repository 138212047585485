import { generateID } from 'utils/common';
import storageModel from 'utils/models/StorageModel';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
export type QueryModel = {
	_id: string;
	type: string;
	value: string;
	sort: string;
	templates: string;
	filters: string[];
	createdAt: Date | string;
};

type QueryStoreContextType = {
	entities: QueryModel[];
	clear: () => void;
	numQueries: number;
	allQueries: QueryModel[];
	reverseChronologicalQueries: () => QueryModel[];
	addNew: (
		value: string,
		sort: string,
		filters: string[],
		templates: string,
		type: string,
		saveToStorage: boolean
	) => void;
	add: (q: QueryModel) => void;
	remove: (q: QueryModel) => void;
	update: (q: QueryModel) => void;
};

const initialValue: QueryStoreContextType = {
	entities: [],
	clear: () => {
		return;
	},
	numQueries: 0,
	allQueries: [],
	reverseChronologicalQueries: () => [],
	addNew: () => {
		return;
	},
	add: (q: QueryModel) => {
		return;
	},
	remove: () => {
		return;
	},
	update: () => {
		return;
	},
};
const SAVED_QUERIES = 'savedQueries';

export const QueryStoreContext = React.createContext<QueryStoreContextType>(
	initialValue
);

export const QueryStoreContextProvider = ({
	children,
}: {
	children: React.ReactNode[];
}) => {
	const [savedQueries, setSavedQueries] = useState<QueryModel[]>([]);
	const queriesInLocalStore = storageModel.get(SAVED_QUERIES);
	useEffect(() => {
		const hasNew =
			storageModel.get(SAVED_QUERIES) &&
			JSON.stringify(savedQueries) !== storageModel.get(SAVED_QUERIES);
		if (hasNew) {
			const jsonFromStorageModel = storageModel.get(SAVED_QUERIES) as string;
			const json = JSON.parse(jsonFromStorageModel);
			Object.entries(json).forEach((s: any) => {
				const data = s[1];
				const model: QueryModel = {
					_id: data._id,
					createdAt: moment(data.createdAt).toISOString(),
					type: data.type,
					filters: data.filters,
					templates: data.templates,
					value: data.value,
					sort: data.sort,
				};
				setSavedQueries((savedQueries) => [...savedQueries, model]);
			});
		} else if (!storageModel.get(SAVED_QUERIES)) {
			setSavedQueries([]);
		}
		// eslint-disable-next-line
	}, [queriesInLocalStore]);

	const clear = () => {
		storageModel.remove(SAVED_QUERIES);
		setSavedQueries([]);
	};

	const add = (query: QueryModel) => {
		setSavedQueries((savedQueries) => [...savedQueries, query]);
	};

	const addNew = (
		value: string,
		sort: string,
		filters: string[],
		templates: string,
		type: string,
		saveToStorage: boolean = true
	) => {
		const query: QueryModel = {
			_id: generateID(),
			createdAt: moment(new Date()).toISOString(),
			type: type,
			filters: filters,
			templates: templates,
			value: value,
			sort: sort,
		};
		add(query);

		// TODO: save query to backend instead of localstorage (RRR-120)
		if (saveToStorage) {
			storageModel.set(SAVED_QUERIES, JSON.stringify([...savedQueries, query]));
		}
	};
	const reverseChronologicalQueries = () =>
		savedQueries
			.slice()
			.sort(
				(a, b) =>
					new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
			);

	const remove = (q: QueryModel) => {
		setSavedQueries((savedQueries) => [
			...savedQueries.filter((query) => query._id !== q._id),
		]);
		storageModel.set(
			SAVED_QUERIES,
			JSON.stringify([...savedQueries.filter((query) => query._id !== q._id)])
		);
	};

	const update = (updated: QueryModel) => {
		setSavedQueries((savedQueries) => [
			...savedQueries.filter((query) => query._id !== updated._id),
			updated,
		]);
		storageModel.set(
			SAVED_QUERIES,
			JSON.stringify([
				...savedQueries.filter((query) => query._id !== updated._id),
				updated,
			])
		);
	};

	return (
		<QueryStoreContext.Provider
			value={{
				entities: savedQueries,
				addNew,
				add,
				reverseChronologicalQueries,
				allQueries: savedQueries,
				clear,
				numQueries: savedQueries.length,
				remove,
				update,
			}}
		>
			{children}
		</QueryStoreContext.Provider>
	);
};

export const useQueryStoreContext = () => {
	const context = React.useContext(QueryStoreContext);
	if (context === undefined)
		throw new Error('Expected to be in query store contxt, but was not');

	return context;
};
