import React, { PropsWithChildren, useCallback } from 'react';
import { _logError } from '../utils/common/log';
import { useAssetHelper } from './dam-assets/components/helpers/useAssetHelper';
import { SecondaryButton } from './buttons.styled-components';
import { AssetVersion } from '../components/workflow/workflows/types/workflow.types';
import { RomeSwal } from 'components/alert';
const DownloaderButton = ({
	data = {},
	children,
}: PropsWithChildren<{
	url: string;
	data?: object;
}>) => {
	const assetStore = useAssetHelper();
	const assetData = (data as AssetVersion[])?.filter((a) => !!a && !!a._id).map(({ _id }) => _id); 

	const triggerDownload = useCallback(() => {
		assetStore
			.zipMultiple(assetData)
			.then((response: { url: string }) => window.open(response.url, '_blank'))
			.catch(_logError);
	}, [assetStore, assetData]);

	const showWarningModal = useCallback(()=>
	{
		RomeSwal.fire({
			icon: 'error',
			title: 'An error occurred',
			text: 'This group of assets exceeds the maximum 2 GB allowed for downloading.',
		});
	},[]);

	const zipSize = (data as AssetVersion[])?.map((a:AssetVersion| string) => 
		(a as AssetVersion)?.fileSizeBytes ? (a as AssetVersion)?.fileSizeBytes : 0,0)?.reduce( (acc, curr) => acc + curr,0);

	return (
		<form className="d-inline-block" method="POST" target="_blank">
			<input type="hidden" name="data" value={JSON.stringify(assetData)} />
			<SecondaryButton type="button" onClick={(zipSize>2000000000)?showWarningModal:triggerDownload}>
				{children}
			</SecondaryButton>
		</form>
	);
};

export default DownloaderButton;
