import { DependencyList, useEffect, useState } from 'react';

export function useAsync<T>(
	factory: () => Promise<T>,
	defaultValue: T,
	deps: DependencyList
): [boolean, T, () => Promise<void>] {
	const [fetching, setFetching] = useState(true);
	const [data, setData] = useState<T>(defaultValue);

	async function refresh() {
		setFetching(true);
		setData(await factory());
		setFetching(false);
	}

	useEffect(() => {
		refresh();
	}, deps);

	return [fetching, data, refresh];
}
