import { canView } from 'components/workflow/workflows/helpers';
import { useAxios, useAsync } from 'hooks';
import { useFetching } from 'hooks/useFetching';
import React, { useCallback } from 'react';
import { Nullable } from 'types/globals';
import { useAuthContext, useGroupContext } from 'utils/auth';
import themeStore from '../../../../core-ui/models/ThemeStore';
import { doesNameableMatch } from '../../../../utils/common';
import DownshiftMultiSelect, {
	DownshiftMultiSelectProps,
} from '../../../downshift-select/downshift-multi-select.component';
import {
	Workflow,
	WorkflowCollection,
} from '../../workflows/types/workflow.types';

const CollectionWorkflowSelect = ({
	collection,
	onChange,
}: {
	collection: WorkflowCollection;
	onChange: (wc: WorkflowCollection) => void;
}) => {
	const { currentUser } = useAuthContext();
	const { groupsForCurrentUser } = useGroupContext();

	const workflowCollectionStore = useAxios<WorkflowCollection>('projects');
	const usedWorkflowStore = useAxios<Workflow>(
		`projects/${collection?._id}/workflow-titles`
	);
	const unusedWorkflowStore = useAxios<Workflow>(`projects/unused-workflows`);

	const [collections, setCollections] = React.useState<WorkflowCollection[]>();

	// We set these whenever the user adds or removes workflows,
	// keeping the dropdown updated with accurate options
	const [unusedWorkflows, setUnusedWorkflows] = React.useState<Workflow[]>();
	const [usedWorkflows, setUsedWorkflows] = React.useState<Workflow[]>();

	const { beginFetching, finishFetching, isFetching } = useFetching();

	React.useMemo(async () => {
		if (isFetching || unusedWorkflows || usedWorkflows) return;

		beginFetching();

		await Promise.all([
			unusedWorkflowStore.findAll(),
			usedWorkflowStore.findAll(),
		]).then(([unused, used]) => {
			setUnusedWorkflows(unused);
			setUsedWorkflows(used);
			finishFetching();
		});
	}, [isFetching, unusedWorkflows, usedWorkflows]);

	const [edited, setEdited] = React.useState(collection);

	React.useEffect(() => {
		if (isFetching) return;
		if (!collections) {
			beginFetching();
			workflowCollectionStore
				.findAll()
				.then((coll) => {
					setCollections(coll);
				})
				.finally(finishFetching);
		}
	}, [
		beginFetching,
		isFetching,
		usedWorkflows,
		finishFetching,
		collections,
		workflowCollectionStore,
	]);

	const addWorkflow = useCallback(
		(workflow: Nullable<Workflow>) => {
			if (workflow) {
				const updatedCollection = {
					...edited,
					workflows: edited?.workflows?.length
						? [
								...edited.workflows.filter((m) => m !== workflow._id),
								workflow._id,
						  ]
						: [workflow._id],
				};
				setUsedWorkflows([...usedWorkflows, workflow]);
				setUnusedWorkflows(
					unusedWorkflows?.filter((wf) => {
						return wf._id !== workflow._id;
					})
				);
				setEdited(updatedCollection);
				onChange(updatedCollection);
			}
		},
		[edited, onChange, usedWorkflows, unusedWorkflows]
	);

	const removeWorkflow = useCallback(
		(workflowTitle: string) => {
			const workflow = usedWorkflows?.find(
				// @ts-ignore
				(workflow) => workflow.title === workflowTitle
			);

			if (!workflow) return;

			const updatedCollection = {
				...edited,
				workflows: [
					...edited.workflows.filter((wfId) => wfId !== workflow?._id),
				],
			};

			setUsedWorkflows(usedWorkflows?.filter((x) => x._id !== workflow?._id));
			setUnusedWorkflows([workflow, ...unusedWorkflows]);
			setEdited(updatedCollection);

			onChange(updatedCollection);
		},
		[edited, onChange, usedWorkflows, unusedWorkflows]
	);

	const canBeAdded = (workflow: Workflow) => {
		return (
			!!canView(workflow, currentUser, groupsForCurrentUser) &&
			!edited?.workflows?.some(
				(wf: string) => workflow._id === (wf as string)
			) &&
			!collections?.some((wfCollection) => {
				if (wfCollection.workflows && wfCollection.workflows.length) {
					return wfCollection.workflows?.some((wfId) => wfId === workflow._id);
				}
			})
		);
	};

	const selectProps: DownshiftMultiSelectProps<any, any> = {
		label: `${themeStore._.workflow}s`,
		selectionState: {
			selection: usedWorkflows ? usedWorkflows!.map((wf) => wf.title) : [],
			options: unusedWorkflows?.filter(canBeAdded) as readonly Workflow[],
			searchPredicate: doesNameableMatch,
		},
		selectionActions: {
			select: addWorkflow,
			unselect: removeWorkflow,
		},
	};

	return <DownshiftMultiSelect {...selectProps} />;
};

export default CollectionWorkflowSelect;
