import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PaginationContainer } from 'components/index';
import React, { Suspense, useEffect, useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import themeStore from '../../core-ui/models/ThemeStore';
import { useAxios } from '../../hooks';
import { useAuthContext, User } from '../../utils/auth';
import windowModel from '../../utils/models/WindowModel';
import Loading from '../loading.component';
import { Heading } from '../ui';
import { AssetCollection } from '../workflow/workflows/types/workflow.types';
import { NewCollectionButton } from './components/asset-collections-index.styled-components';

const AssetCollectionListTable = React.lazy(() =>
	import('./components/asset-collection-list-table.component')
);
const PageSubheading = React.lazy(() => import('../page-subheading.component'));
const SaveQueryButton = React.lazy(() =>
	import('../save-query-button.component')
);
const SearchBar = React.lazy(() =>
	import('../search-bar/search-bar.component')
);

// constants
const label = themeStore._.assetCollection;

// component
const AssetCollectionsIndexView = () => {
	const assetCollectionStore = useAxios<AssetCollection>(
		'collections',
		'collections/all'
	);
	const { currentUser } = useAuthContext();

	const [currentPage, setCurrentPage] = useState<number>(1);
	const paginate = (
		array: Array<AssetCollection>,
		page_size: number,
		page_number: number
	) => {
		// human-readable page numbers usually start with 1, so we reduce 1 in the first argument
		return array.slice((page_number - 1) * page_size, page_number * page_size);
	};

	const [collections, setCollections] = React.useState<AssetCollection[]>();

	const [pages, setPages] = useState<PagesState<any>>();

	const currentPageData = React.useMemo(() => {
		if (collections?.length)
			return paginate(
				collections.filter(
					(col) =>
						(col.createdBy as string) === currentUser?._id ||
						(col.createdBy as User)._id === currentUser._id
				) ?? ([] as AssetCollection[]),
				15,
				currentPage
			);
	}, [collections, currentUser, currentPage]);

	const [fetching, setFetching] = React.useState(false);
	useEffect(() => {
		if (fetching) return;
		if (!collections) {
			setFetching(true);
			assetCollectionStore
				.getCustom()
				.then((c: any) => {
					const x = c.filter(
						(col: any) =>
							(col.createdBy as string) === currentUser?._id ||
							(col.createdBy as User)._id === currentUser._id
					);
					setCollections(c);
					setPages({
						currentPage: currentPage,
						setCurrentPage: (currenPage: number) => setCurrentPage(currenPage),
						totalPages:
							x && x?.length && x?.length >= 15 ? Math.ceil(x?.length / 15) : 1,
					});
				})
				.finally(() => setFetching(false));
		}
	}, [collections, fetching, assetCollectionStore, currentPage, currentUser]);

	const [searchTerm, setSearchTerm] = React.useState('');

	useEffect(() => {
		const searchTerm = windowModel.getURLSearchParam('q');
		if (searchTerm) {
			setSearchTerm(searchTerm);
		}
	}, []);

	return (
		<Suspense fallback={<Loading />}>
			<Container>
				<Row>
					<Col lg={2} md={3} className="mb-md-0 mb-4">
						<PageSubheading text={themeStore._.assetBase} />
						<Heading>{label}s</Heading>

						<NewCollectionButton to="./new">
							<FontAwesomeIcon icon={faPlus} className="mr-2" />
							New {label?.toLowerCase()}
						</NewCollectionButton>
					</Col>

					<Col lg={10} md={9}>
						<Row className="mb-4">
							<Col md={9}>
								<SearchBar
									placeholder={`Search all ${label?.toLowerCase()}s...`}
									onChange={(evt) => setSearchTerm(evt.target.value)}
								/>
							</Col>
							<Col md={3} className="mt-md-0 mt-3">
								<SaveQueryButton
									className="float-md-right float-none"
									searchTerm={searchTerm}
									type={'projects'}
								/>
							</Col>
						</Row>

						<hr />

						<AssetCollectionListTable
							onDelete={(collection: AssetCollection) =>
								setCollections(
									collections?.filter((a) => a._id !== collection._id)
								)
							}
							collections={currentPageData as AssetCollection[]}
							noResultsLabel="No results"
						/>
						<PaginationContainer
							currentPage={pages?.currentPage || (1 as number)}
							setCurrentPage={setCurrentPage}
							totalPages={pages?.totalPages || (1 as number)}
						/>
					</Col>
				</Row>
			</Container>
		</Suspense>
	);
};

export default AssetCollectionsIndexView;
