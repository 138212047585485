import {
	faAngleDoubleLeft,
	faAngleDoubleRight,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BackLink from 'components/back-link/back-link.component';
import PathIndicators from 'components/workflow/workflow-templates/svg/PathIndicators';
import { RoleContext } from 'context/PermissionsContext';
import React, { useEffect, useState } from 'react';
import { Col, Container } from 'reactstrap';
import { useTemplateContext } from '../../../../../context/useTemplateContext';
import { LoadingRow } from '../../../../loading.component';
import { SelectedTemplate } from '../../template.contexts';
import { StageEditor } from './StageEditor';
import {
	StageEditorWrapper,
	TemplatePageWrapper,
	TemplateSidebar,
	ToggleSidebarButton,
} from './Template.styles';
import { TemplateEditor } from './TemplateEditor';
type Props = {
	workflowTemplateId: string;
};

export const EditTemplatePage = ({ workflowTemplateId }: Props) => {
	const [isSidebarMinimized, setIsSidebarMinimized] = useState(false);
	const { loadTemplate, template } = useTemplateContext();
	const [templateEdited, setTemplateEdited] = useState(template);
	const [fetching, setFetching] = useState(false);
	const permissions = React.useContext(RoleContext);

	let editable: any =
		!!permissions.roles?.length &&
		permissions.canViewByRole('wfTemplatesEditable') &&
		template &&
		!['archived', 'final'].some((a) => a === template.editingState);

	editable = !!editable as boolean;

	useEffect(() => {
		if (!template && !fetching) {
			setFetching(true);
			loadTemplate(workflowTemplateId)
				.then(setTemplateEdited)
				.finally(() => setFetching(false));
		}
	}, [template, loadTemplate, fetching, workflowTemplateId]);

	if (!template) {
		return (
			<Container>
				<LoadingRow />
			</Container>
		);
	}

	return (
		<Container
			fluid
			className="p-0 template-container"
			style={{ background: '#ebebeb' }}
		>
			<BackLink link=".." title="Templates" />
			<TemplatePageWrapper>
				<SelectedTemplate.Provider value={[templateEdited, setTemplateEdited]}>
					{/* Sidebar */}

					<Col className="p-0">
						<TemplateSidebar
							className={`template-sidebar ${
								isSidebarMinimized ? 'collapsed' : ''
							}`}
						>
							<ToggleSidebarButton
								className="toggle-sidebar cy-wf-template-sidebar"
								title={isSidebarMinimized ? 'show sidebar' : 'hide sidebar'}
								onClick={() => {
									const isMinimized = !isSidebarMinimized;
									setIsSidebarMinimized(isMinimized);
								}}
								style={{ zIndex: 9 }}
							>
								<FontAwesomeIcon
									icon={
										isSidebarMinimized ? faAngleDoubleRight : faAngleDoubleLeft
									}
								/>
							</ToggleSidebarButton>

							{!isSidebarMinimized && (
								<TemplateEditor
									isSidebarMinimized={isSidebarMinimized}
									toggleSidebar={() => setIsSidebarMinimized((b) => !b)}
								/>
							)}
						</TemplateSidebar>
					</Col>

					<Col xl={isSidebarMinimized ? 12 : 8}>
						{/* Stage Editor */}
						<StageEditorWrapper className="stage-editor">
							<StageEditor editable={editable} />
							{/* <BackingSvg /> */}
						</StageEditorWrapper>
						<div className="position-relative">
							<PathIndicators />
						</div>
					</Col>
				</SelectedTemplate.Provider>
			</TemplatePageWrapper>
		</Container>
	);
};
