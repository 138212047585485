import {
	faCheckSquare,
	faFileUpload,
	faImage,
	faProjectDiagram,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { navigate } from '@reach/router';
import { AssetUploadDialog } from 'components/dam-assets/components/asset-upload-dialog.component';
import React from 'react';
import { Button, Col, Container, Row } from 'reactstrap';
import { useAuthContext } from 'utils/auth';
import { useModalCloser, useModalCreator } from 'utils/ModalStack';
import { RoleContext } from '../../context/PermissionsContext';
import RenderWhen from '../render-when.component';

const WorkflowDashboard = () => {
	// hooks
	const { currentUser } = useAuthContext();

	/* try to insert upload modal here too? duplicating admin nav modal. feels dirty */
	const modalStack = useModalCreator();
	const modalCloser = useModalCloser();
	const { canViewByRole } = React.useContext(RoleContext);

	const afterSubmit = React.useCallback(() => {
		modalCloser.closeModal();
		return navigate('/admin/dam/assets/edit-custom-upload');
	}, [modalCloser]);

	const openUploadDialog = React.useCallback(() => {
		modalStack.addModal(<AssetUploadDialog afterSubmit={afterSubmit} />);
	}, [modalStack, afterSubmit]);

	/**
	 * Handle a tile nav click event
	 * @param tile
	 */
	const onTileClick = (tile: any) => {
		switch (tile.label) {
			case 'Upload':
				openUploadDialog();
				break;
			default:
				navigate(tile.link);
		}
	};

	/**
	 * Temporary tile button navigation
	 */
	const linkTiles = [
		{
			label: 'My Assignments',
			link: '/admin/workflow/assignments',
			icon: faCheckSquare,
		},
		{
			label: 'Workflows',
			link: '/admin/workflow/workflows',
			icon: faProjectDiagram,
		},
		{
			label: 'Assets',
			link: '/admin/dam/assets',
			icon: faImage,
		},
		{
			label: 'Upload',
			link: '/admin',
			icon: faFileUpload,
		},
	];
	return (
		<Container>
			<Row>
				<Col className="p-2">
					<div className="dashboard-link-tile welcome">
						<p className="greeting label">
							Welcome, <br />
							{currentUser.givenName} {currentUser.familyName}
						</p>
					</div>
				</Col>
			</Row>
			<Row>
				{linkTiles.map((tile, index) => (
					<RenderWhen
						key={index}
						when={
							!(
								tile.label === 'Workflows' &&
								!['wfReadonly', 'wfEditable'].some(canViewByRole)
							) &&
							!(
								tile.label === 'Upload' &&
								!['damUploadable'].some(canViewByRole)
							) &&
							!(
								tile.label === 'My Assignments' &&
								!['canViewAssignments'].some(canViewByRole)
							)
						}
					>
						<Col className="p-2" key={`${tile.label}_${index}`}>
							<Button
								className="dashboard-link-tile cy-dashboard-link-tile-dashboard"
								onClick={() => onTileClick(tile)}
								key={`navtile-${index}`}
							>
								<FontAwesomeIcon icon={tile.icon} className="icon" />
								<span className="label">{tile.label}</span>
							</Button>
						</Col>
					</RenderWhen>
				))}
			</Row>
		</Container>
	);
};

export default WorkflowDashboard;
