import { getUserRoleName } from 'components/accounts/models/UserRole.model';
import { UserGroup } from 'components/accounts/types';
import React from 'react';
import Moment from 'react-moment';
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';
import { useAxios } from '../../hooks/useAxios';
import { useAuthContext, useGroupContext, User } from '../../utils/auth';
import { camelCaseToSentenceCase } from '../../utils/common';
import { _logError } from '../../utils/common/log';
import BackLink from '../back-link/back-link.component';
import GroupAvatar from '../group-avatar.component';
import { LoadingRow } from '../loading.component';
import { NotificationsContext } from '../notifications';
import {
	CardBodyFlex,
	UserAvatar,
} from './components/user-details.styled-components';
import EditUserForm from './components/users/EditUserForm';
import { useRoleContext } from 'context/PermissionsContext';

interface UserDetailsViewProps {
	userId: string;
}

function userDetailsReducer(
	state: {
		userGroups: UserGroup[];
		user: any;
	},
	action: {
		type: 'setGroups' | 'setUser';
		payload: { groups?: UserGroup[]; user?: any };
	}
) {
	switch (action.type) {
		case 'setGroups':
			return { ...state, userGroups: action.payload.groups as UserGroup[] };
		case 'setUser':
			return { ...state, user: action.payload.user as User };
		default:
			return state;
	}
}
const UserDetailsView = (props: UserDetailsViewProps) => {
	const { info, error } = React.useContext(NotificationsContext);
	const { roles } = useRoleContext();
	const { userId } = props;
	const userStore = useAxios<User>('users');
	const { refreshEntity, entities } = useAuthContext();
	const { entities: userGroups } = useGroupContext();
	const [state, dispatch] = React.useReducer(userDetailsReducer, {
		userGroups: userGroups,
		user: null,
	});

	React.useEffect(() => {
		if (!state.user && entities) {
			dispatch({
				type: 'setUser',
				payload: {
					user: entities.find((e) => e._id === userId),
					groups: userGroups,
				},
			});
		}
	}, [userId, entities, state.user, userGroups]);

	const { user } = state;
	const onSubmit = async (userSnapshot: any) => {
		try {
			const updated = await userStore.updateOne(userSnapshot._id, userSnapshot);
			refreshEntity(updated as User);
			info('User saved!');
		} catch (e) {
			_logError(e);
			error(`An issue occurred while updating user. Please try again later.`);
		}
	};

	const groups = React.useMemo(
		() =>
			state && state?.userGroups && state.userGroups?.length
				? state.userGroups.filter((group) =>
						group.members.some((m) => m._id === userId)
				  )
				: [],
		[userId, state]
	);

	const userDetail = () =>
		user ? (
			<Row>
				<Col xs={12} className="mb-2">
					<BackLink link="/admin/dashboard" title="Admin Dashboard" />
				</Col>
				<Col md={4} className="mt-4 mt-md-0">
					<Card className="h-100">
						<CardBodyFlex>
							<UserAvatar user={user as User} />
							<h1>{`${user.givenName} ${user.familyName}`}</h1>
							<p>
								{camelCaseToSentenceCase(
									getUserRoleName(
										roles.find((role) => role.roleId === (user as User).roleId)!
									)
								)}
							</p>
						</CardBodyFlex>
					</Card>
				</Col>
				<Col md={8} className="mt-4 mt-md-0">
					<Card className="h-100">
						<CardHeader>
							<h2 className="mb-0">User details</h2>
						</CardHeader>
						<CardBody>
							<EditUserForm
								showChangePassword={false}
								showRole={true}
								selectedUser={user as User}
								onSubmit={onSubmit}
							/>
						</CardBody>
					</Card>
				</Col>
				<Col md={6} className="mt-4">
					<Card className="h-100">
						<CardHeader>
							<h2 className="mb-0">User groups</h2>
						</CardHeader>
						<CardBody>
							{groups.map((g) => (
								<div className="mb-2" key={g._id}>
									<GroupAvatar size="lg" group={g} />
									<p className="d-inline ml-3">{g.title}</p>
								</div>
							))}
						</CardBody>
					</Card>
				</Col>
				<Col md={6} className="mt-4">
					<Card className="h-100">
						<CardHeader>
							<h2 className="mb-0">User activity</h2>
						</CardHeader>
						<CardBody>
							<ul>
								<li>
									<p>
										Added{' '}
										<Moment
											format="MMM DD, YYYY"
											date={(user as User).createdAt}
										/>
									</p>
								</li>
							</ul>
						</CardBody>
					</Card>
				</Col>
			</Row>
		) : (
			<LoadingRow label="Loading user..." />
		);

	return <Container>{userDetail()}</Container>;
};

export default UserDetailsView;
