import React, { useCallback, useState } from 'react';
import { faPlus, faShare, faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { navigate, RouteComponentProps } from '@reach/router';
import Moment from 'react-moment';
import { Col, Container, Row, ButtonGroup } from 'reactstrap';
import BackLink from '../back-link/back-link.component';
import CircleButton from '../circle-button.component';
import CollectionActionDropdown from '../collection-action-dropdown';
import DownloaderButton from '../downloader-button.component';
import {
	EntityPropLabel,
	EntityPropList,
	EntityPropListItem,
} from '../entity-details.styled-components';
import ConfirmationDialog from '../modals/confirmation-dialog.component';
import { Divider, Heading } from '../ui';
import EntityMetadataFields from '../metadata/components/entity-metadata-fields.component';
import { NotificationsContext } from '../notifications';
import { useModalCreator } from '../../utils/ModalStack';
import themeStore from '../../core-ui/models/ThemeStore';
import AddAssetsDialog from './components/add-assets-dialog.component';
import { ShareAssetButton } from '../dam-assets/components/asset-details.styled-components';
import ShareSingleAssetDialog from '../dam-assets/components/share-dialogs/share-single-asset-dialog.component';
import ShareMultipleAssetsDialog from '../dam-assets/components/share-dialogs/share-multiple-assets-dialog.component';
import Loading from '../loading.component';
import RenderWhen from '../render-when.component';
import {
	AssetCollection,
	AssetVersion,
	EntityMetadata,
} from '../workflow/workflows/types/workflow.types';
import { useAxios } from '../../hooks';
import { useAssetHelper } from '../dam-assets/components/helpers/useAssetHelper';
import { useAuthContext, User } from '../../utils/auth';
import { _logError } from 'utils/common/log';
import { RequiresFeatureFlag } from 'utils/core/FeatureFlags';
import AssetSelectionToolbar, {
	AssetSelectionTool,
	AssetSelectionToolbarClickEvent,
} from 'components/dam-assets/components/asset-selection-toolbar.component';
import { orderBy } from 'lodash';
import AssetCardGrid from 'components/dam-assets/components/asset-card-grid.component';
import { AlgoliaAssetVersion } from 'components/dam-assets/components/asset-infinite-hits.component';
import { RomeSwal } from 'components/alert';

const tools = [
	AssetSelectionTool.VIEW,
	AssetSelectionTool.COMPARE,
	AssetSelectionTool.DOWNLOAD,
	AssetSelectionTool.SHARE,
];
// constants
const assetTerm = themeStore._.asset.toLowerCase();
const assetCollectionTerm = themeStore._.assetCollection;

// component
const AssetCollectionDetailsView = (
	props: RouteComponentProps<{ collectionId: string }>
) => {
	const { info, error } = React.useContext(NotificationsContext);
	const collectionStore = useAxios<AssetCollection>('collections');
	const assetHelper = useAxios<AssetVersion>('assets');
	const { entities: users, currentUser } = useAuthContext();
	const { canEditAssetCollection } = useAssetHelper();
	const [fetching, setFetching] = useState(false);
	const [collection, setCollection] = useState<AssetCollection>();
	const [assets, setAssets] = useState<AssetVersion[]>([]);
	const assetStore = useAssetHelper();
	const [sortProperty, setSortProperty] = useState('');
	const [sortDirection, setSortDirection] = useState('');
	const [selectingAssets, setSelectingAssets] = useState(false);
	const [selectedAssets, setSelectedAssets] = useState<AssetVersion[]>([]);

	const assetSelectionCallBack = useCallback(
		(asset: AlgoliaAssetVersion) => {
			const index = selectedAssets.indexOf(asset);
			if (index > -1) {
				// remove from selection list
				setSelectedAssets((selectedAssets) =>
					selectedAssets.filter((a) => a._id !== asset._id)
				);
			} else {
				// add to selection list
				setSelectedAssets((selectedAssets) => selectedAssets.concat(asset));
			}
		},
		[selectedAssets]
	);

	React.useEffect(() => {
		if (!!collection?.assets?.length && assets.length === 0) {
			collection.assets.forEach(async (asset) => {
				const found = (await assetStore.findOne(asset)) as AssetVersion;
				setAssets((assets) => [...assets, found]);
			});
		}
		// eslint-disable-next-line
	}, [collection]);
	React.useEffect(() => {
		if (fetching) return;
		if (!collection) {
			setFetching(true);
			collectionStore
				.findOne(props.collectionId as string)
				.then(setCollection)
				.finally(() => setFetching(false));
		}
	}, [
		fetching,
		collection,
		collectionStore,
		props.collectionId,
		assets,
		assetHelper,
	]);

	const [owner, setOwner] = useState<User>();

	React.useEffect(() => {
		if (owner) return;
		if (collection && !!collection.createdBy) {
			if (typeof collection.createdBy === 'object')
				setOwner(collection.createdBy as User);
			if (typeof collection.createdBy === 'string') {
				const owner = users?.find(
					(m) => m._id === (collection?.createdBy as string)
				);
				setOwner(owner);
			}
		}
	}, [collection, users, owner]);

	const modalStack = useModalCreator();
	const [isLoading] = useState(false);
	// callbacks
	const deleteCollection = useCallback(
		async (collection: AssetCollection) => {
			try {
				await collectionStore.deleteOne(collection._id);
				info(`${assetCollectionTerm} deleted!`);
				return navigate(`/admin/dam/collections`);
			} catch (e) {
				_logError(e);
				error('Failed to delete collection. Please try again later.');
			}
		},
		[collectionStore, error, info]
	);

	const showAddAssetsModal = useCallback(
		(collection: AssetCollection, assets: AssetVersion[]) => {
			modalStack.addModal(
				<AddAssetsDialog
					selectedCollection={collection}
					onCollectionUpdated={(updatedCollection) => {
						const currentAssetIds = new Set(updatedCollection.assets);
						const previousAssetIds = new Set(assets.map((a) => a._id));

						// Store the updated collection
						setCollection(updatedCollection);

						// Remove assets that have been removed
						setAssets((assets) =>
							assets.filter((a) => currentAssetIds.has(a._id))
						);

						// Fetch and add newly added assets
						updatedCollection.assets
							.filter((a) => !previousAssetIds.has(a))
							.forEach(async (asset) => {
								const found = (await assetStore.findOne(asset)) as AssetVersion;
								setAssets((assets) => [...assets, found]);
							});
					}}
				/>
			);
		},
		[modalStack, assetStore, setCollection, setAssets]
	);

	const showDeleteCollectionModal = useCallback(
		(collection: AssetCollection) => {
			modalStack.addModal(
				<ConfirmationDialog
					header={`Deleting "${collection.title}"`}
					onConfirm={() => deleteCollection(collection)}
				>
					<p>
						Are you sure you want to delete this{' '}
						{assetCollectionTerm.toLowerCase()}? This action cannot be undone.
					</p>
				</ConfirmationDialog>
			);
		},
		[modalStack, deleteCollection]
	);

	// render methods
	const renderActionDropdown = (collection?: AssetCollection) => {
		if (!collection) return null;
		if (!canEditAssetCollection(collection, currentUser)) {
			return null;
		}

		return (
			<CollectionActionDropdown
				collection={collection as AssetCollection}
				collectionItems={assets}
				collectionTerm={assetCollectionTerm.toLowerCase()}
				editLink={`/admin/dam/collections/${collection?._id}/edit`}
				showDeleteModal={showDeleteCollectionModal}
				extraClasses="d-inline ml-2"
			/>
		);
	};

	const zipSize = assets?.map((a:AssetVersion| string) => 
		(a as AssetVersion)?.fileSizeBytes ? (a as AssetVersion)?.fileSizeBytes : 0,0)?.reduce( (acc, curr) => acc + curr,0);

	const modalCreator = useModalCreator();
	const showShareModal = useCallback(
		(collection?: AssetCollection) => {
			if(zipSize<2000000000)
			{
				if (collection?.assets.length === 1) {
					// share single asset
					assetHelper
						.findOne(collection.assets[0] as string)
						.then((assetVersion: AssetVersion) =>
							modalCreator.addModal(
								<ShareSingleAssetDialog asset={assetVersion} />
							)
						);
				} else {
					modalCreator.addModal(<ShareMultipleAssetsDialog assets={assets} />);
				}
			}
			else
			{
				RomeSwal.fire({
					icon: 'error',
					title: 'An error occurred',
					text: 'This group of assets exceeds the maximum 2 GB allowed for filesharing.',
				});
			}
		},
		//eslint-disable-next-line
		[modalCreator, assetStore]
	);

	const toolbarCallback = (event: AssetSelectionToolbarClickEvent) => {
		if (event.tool === AssetSelectionTool.SELECTION) {
			const isSelecting: boolean = event.value;
			setSelectingAssets(isSelecting);
			if (!isSelecting) {
				// empty selected assets if selecting is turned off
				setSelectedAssets([]);
			}
		}
	};

	return (
		<Container>
			<RenderWhen when={!!fetching}>
				<Loading label={'Loading asset collection..'} />
			</RenderWhen>
			<RenderWhen when={!!isLoading}>
				<Loading label={'Generating Sharable Link'} />
			</RenderWhen>
			<RenderWhen when={!isLoading}>
				<Row>
					<Col
						xs={12}
						className="d-flex justify-content-between align-items-center"
					>
						<div>
							<BackLink link=".." title={`${assetCollectionTerm}s`} />
							<Heading>{collection?.title}</Heading>
						</div>
						<ButtonGroup>
							<ShareAssetButton
								className="pr-2"
								onClick={() => showShareModal(collection)}
							>
								<FontAwesomeIcon className="mr-2" icon={faShare} />
								Share Collection
							</ShareAssetButton>
							<DownloaderButton
								url="assets/zip"
								data={assets
									}
							>
								<FontAwesomeIcon className="mr-2" icon={faDownload} />
								Download zip
							</DownloaderButton>

							{renderActionDropdown(collection)}
						</ButtonGroup>
					</Col>

					<Col xs={12}>
						<Divider />
					</Col>

					<Col md={3}>
						<EntityPropList className="mt-4">
							<EntityPropListItem>
								<EntityPropLabel>Created on</EntityPropLabel>
								<p>
									<Moment format="MMM DD, YYYY" date={collection?.createdAt} />
								</p>
							</EntityPropListItem>
							<EntityPropListItem>
								<EntityPropLabel>Created by</EntityPropLabel>
								<p>{owner?.givenName + ' ' + owner?.familyName || 'N/A'}</p>
							</EntityPropListItem>
							<EntityMetadataFields
								metadata={collection?.metadata as EntityMetadata}
							/>
						</EntityPropList>
					</Col>

					<Col md={9}>
						<Row>
							<AssetSelectionToolbar
								dispatchCurrentSort={(sort) => {
									setSortDirection(sort.type);
									setSortProperty(sort.payload);
								}}
								selectedAssets={selectedAssets}
								onClick={toolbarCallback}
								tools={tools}
							/>
						</Row>
						<Row className="mt-4">
							{sortProperty && sortDirection ? (
								<AssetCardGrid
									onDelete={(asset) => {
										setAssets(assets.filter((a) => a._id !== asset._id));
										setCollection({
											...collection!,
											assets: collection!.assets.filter((a) => a !== asset._id),
										});
									}}
									onRemoveFromCollection={(asset) => {
										setAssets(assets.filter((a) => a._id !== asset._id));
										setCollection({
											...collection!,
											assets: collection!.assets.filter((a) => a !== asset._id),
										});
									}}
									assets={
										orderBy(
											assets.filter((a) => !!a && !!a?._id),
											(a) => a[sortProperty as keyof AssetVersion],
											sortDirection as 'asc' | 'desc'
										) as AlgoliaAssetVersion[]
									}
									areLinks={!selectingAssets}
									collectionId={collection?._id}
									isSelectionAvailable={true}
									onAssetSelected={assetSelectionCallBack}
									selectedAssets={selectedAssets as AlgoliaAssetVersion[]}
								/>
							) : (
								<AssetCardGrid
									onDelete={(asset) => {
										setAssets(assets.filter((a) => a._id !== asset._id));
										setCollection({
											...collection!,
											assets: collection!.assets.filter((a) => a !== asset._id),
										});
									}}
									onRemoveFromCollection={(asset) => {
										setAssets(assets.filter((a) => a._id !== asset._id));
										setCollection({
											...collection!,
											assets: collection!.assets.filter((a) => a !== asset._id),
										});
									}}
									assets={
										assets.filter(
											(a) => !!a && !!a?._id
										) as AlgoliaAssetVersion[]
									}
									areLinks={!selectingAssets}
									collectionId={collection?._id}
									isSelectionAvailable={true}
									onAssetSelected={assetSelectionCallBack}
									selectedAssets={selectedAssets as AlgoliaAssetVersion[]}
								/>
							)}
						</Row>
					</Col>
				</Row>
			</RenderWhen>
		</Container>
	);
};

export default AssetCollectionDetailsView;
