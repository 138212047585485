import { UploadResult, Uppy, UppyFile } from '@uppy/core';
import { Maybe } from 'types/globals';
import { AuthProvider } from '../../context/AuthProvider';

export class FileUploadProvider {
	private readonly endpoint: string;
	public readonly uppy: Uppy;

	constructor(endpoint: string, private readonly authProvider: AuthProvider) {
		this.endpoint = endpoint + '/uploads';

		this.uppy = new Uppy({
			id: 'rome-assets',
			meta: { type: 'file' },
			restrictions: { maxNumberOfFiles: 1 },
			autoProceed: true,
		});

		this.uppy.on('complete', (result) => {});
	}
}

export function toFile(uf: UppyFile): File {
	if (uf.data instanceof File) {
		return uf.data;
	}
	return new File([uf.data], uf.name, { type: uf.type });
}

export function resultToFile(ur?: UploadResult): Maybe<File> {
	if (ur) {
		const [uploadedFile] = ur.successful;
		if (uploadedFile) {
			return toFile(uploadedFile);
		}
	}
	return undefined;
}
