import { dateFromId } from '../common';
import moment from 'moment';

export type Creatable = Identifiable & {
	createdAt?: string | number | Date;
};

export const getCreationDateOf = (creatable: Creatable): Date =>
	moment(creatable?.createdAt).isValid()
		? new Date(creatable?.createdAt?.toString() as string)
		: dateFromId(creatable._id);

export const getCreationTimestamp = (creatable: Creatable): number =>
	getCreationDateOf(creatable).valueOf();
export const getCreationDateStr = (creatable: Creatable): string =>
	getCreationDateOf(creatable).toLocaleString();

export const compareCreationDates = (a: Creatable, b: Creatable) =>
	getCreationTimestamp(a) - getCreationTimestamp(b);
