import {
	faCloudUploadAlt,
	faCopy,
	faDownload,
	faFileImage,
	faPlusSquare,
	faShare,
	faSpinner,
	faTimes,
	faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RomeSwal } from 'components/alert';
import { useRoleContext } from 'context/PermissionsContext';
import { useSiteSettings } from 'hooks/useSiteSettings';
import React, { useCallback, useEffect, useState } from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import styled from 'styled-components';
import windowModel from 'utils/models/WindowModel';
import themeStore from '../../../core-ui/models/ThemeStore';
import { buildClassList } from '../../../utils/common';
import { useModalCreator } from '../../../utils/ModalStack';
import AddAssetToCollectionDialog from '../../dam-asset-collections/components/add-asset-collection-dialog.component';
import DropDownToggleMenu from '../../forms/dropdown-menu.component';
import { NotificationsContext } from '../../notifications';
import { AssetVersion } from '../../workflow/workflows/types/workflow.types';
import { useViewer } from '../services/viewer.service';
import CompareAssetsDialog from './compare-assets-dialog.component';
import { useAssetHelper } from './helpers/useAssetHelper';
import ShareMultipleAssetsDialog from './share-dialogs/share-multiple-assets-dialog.component';
import ShareSingleAssetDialog from './share-dialogs/share-single-asset-dialog.component';

const { colors } = themeStore.selectedTheme;
const assetTerm = themeStore._.asset.toLowerCase();
const pluralAssetsTerm = `${assetTerm}s`;

export enum AssetSelectionTool {
	VIEW = 'view',
	COMPARE = 'compare',
	DOWNLOAD = 'download',
	SELECTION = 'selection',
	SHARE = 'share',
	TRASH = 'trash',
}

export const defaultAssetSelectionTools = [
	AssetSelectionTool.VIEW,
	AssetSelectionTool.COMPARE,
	AssetSelectionTool.DOWNLOAD,
	AssetSelectionTool.SHARE,
	AssetSelectionTool.TRASH,
];

// interfaces
export interface AssetSelectionToolbarClickEvent {
	tool: AssetSelectionTool;
	value?: any;
}

interface Props {
	isUsingAlgoliaSort?: boolean;
	AssetAlgoliaSortby?: any;
	tools: readonly AssetSelectionTool[];
	onDelete?: (assetVersions: AssetVersion[]) => void;
	className?: string;
	onClick?: (event: AssetSelectionToolbarClickEvent) => void;
	selectedAssets: AssetVersion[];
	dispatchCurrentSort: React.Dispatch<{
		type: 'asc' | 'desc';
		payload: 'fileName' | 'createdAt' | 'fileSizeBytes';
	}>;
}

// styled components
export const Toolbar = styled.div`
	display: inline-flex;
`;

// TODO: extract to reusable component (see also IndexViewToggler)
export const ToolbarButton = styled.button`
	background: transparent;
	border-radius: 3px;
	border: none;
	color: var(--darkGrey);
	margin-left: 6px;

	&.active {
		color: ${colors.darkestGrey};
	}

	&:hover {
		color: ${colors.darkestGrey};
	}
`;

// component
const AssetSelectionToolbar = (props: Props) => {
	const { error, info } = React.useContext(NotificationsContext);
	const { downloadAssetFile, deleteOne, zipMultiple } = useAssetHelper();
	const permissions = useRoleContext();
	const {
		isUsingAlgoliaSort = false,
		AssetAlgoliaSortby,
		tools,
		className,
		onClick,
		selectedAssets,
		onDelete,
		dispatchCurrentSort,
	} = props;
	const [currentSort, setCurrentSort] = useState<{
		fileName: 'A-z' | 'Z-a';
		createdAt: 'A-z' | 'Z-a';
		fileSizeBytes: 'A-z' | 'Z-a';
	}>({ fileName: 'A-z', createdAt: 'A-z', fileSizeBytes: 'A-z' });

	// state actions
	const [isDownloading, setisDownloading] = useState(false);
	const [isSelectingAssets, setSelectingAssets] = useState(false);
	const [isSharing, setIsSharing] = useState(false);
	const [isDeleting, setIsDeleting] = useState(false);
	const [isLaunching, setIsLaunching] = useState(false);

	const appSettings = useSiteSettings();
	const { settings: siteSettings } = appSettings;
	const modalCreator = useModalCreator();
	const assetViewer = useViewer();

	// permission methods
	const isAddToCollectionToolAvailable = () => {
		return (
			['damReadOnly', 'damEditable'].some(permissions.canViewByRole) &&
			isSelectingAssets &&
			selectedAssets.length > 0
		);
	};

	const isViewToolAvailable = () => {
		return (
			['damReadOnly', 'damEditable'].some(permissions.canViewByRole) &&
			!!siteSettings['assetSelectionToolbar.view' as keyof Object] &&
			tools.includes(AssetSelectionTool.VIEW) &&
			selectedAssets.length === 1 &&
			isSelectingAssets
		);
	};

	/** Note: commenting this out until CloudFlow is understood **/
	const isCompareToolAvailable = () => {
		return false;
		return (
			['damReadOnly', 'damEditable'].some(permissions.canViewByRole) &&
			siteSettings['assetSelectionToolbar.compare' as keyof Object] &&
			tools.includes(AssetSelectionTool.COMPARE) &&
			selectedAssets.length === 2 &&
			isSelectingAssets
		);
	};

	const isDownloadToolAvailable = () => {
		// tool not available in props => quick exit
		if (!tools.includes(AssetSelectionTool.DOWNLOAD)) {
			return false;
		}

		if (isSelectingAssets) {
			if (selectedAssets.length === 1) {
				// downloading single asset (prevent falsy)
				return (
					['damReadOnly', 'damEditable'].some(permissions.canViewByRole) &&
					siteSettings['assetSelectionToolbar.downloadSingle' as keyof Object]
				);
			} else if (selectedAssets.length > 1) {
				// downloading multiple assets (prevent falsy)
				return siteSettings[
					'assetSelectionToolbar.downloadMultiple' as keyof Object
				];
			}
			return false;
		} else {
			return false;
		}
	};

	const isShareToolAvailable = () => {
		// tool not available in props => quick exit
		if (!tools.includes(AssetSelectionTool.SHARE)) {
			return false;
		}

		if (isSelectingAssets) {
			if (selectedAssets.length === 1) {
				// sharing single asset (prevent falsy)
				return siteSettings[
					'assetSelectionToolbar.shareSingle' as keyof Object
				];
			} else if (selectedAssets.length > 1) {
				// sharing multiple assets (prevent falsy)
				return siteSettings[
					'assetSelectionToolbar.shareMultiple' as keyof Object
				];
			}
			return false;
		} else {
			return false;
		}
	};

	const isTrashToolAvailable = () => {
		return (
			['damEditable'].some(permissions.canViewByRole) &&
			siteSettings['assetSelectionToolbar.trash' as keyof Object] &&
			tools.includes(AssetSelectionTool.TRASH) &&
			selectedAssets.length &&
			isSelectingAssets
		);
	};

	// callbacks
	const dispatchClick = useCallback(
		(event: AssetSelectionToolbarClickEvent) => {
			if (onClick) {
				onClick(event);
			}
		},
		[onClick]
	);

	const toggleSelectingState = useCallback(
		(isSelecting: boolean) => {
			setSelectingAssets(isSelecting);
			dispatchClick({
				tool: AssetSelectionTool.SELECTION,
				value: isSelecting,
			});
		},
		[dispatchClick, setSelectingAssets]
	);

	const dropdownItems = [
		{ label: 'A - Z', value: 'fileName-asc' },
		{ label: 'Z - A', value: 'fileName-desc' },
		{ label: 'Newest - Oldest', value: 'createdAt-desc' },
		{ label: 'Oldest - Newest', value: 'createdAt-asc' },
		{ label: 'Smallest - Largest', value: 'fileSize-asc' },
		{ label: 'Largest - Smallest', value: 'fileSize-desc' },
	];

	/**
	 * This method only sorts locally loaded results, sorting should be driven by the backend
	 * @deprecated
	 */
	const toggleSortAssets = (event: any) => {
		const payload = event.target.attributes.eventkey.value.includes('createdAt')
			? 'createdAt'
			: event.target.attributes.eventkey.value.includes('fileSize')
			? 'fileSizeBytes'
			: event.target.attributes.eventkey.value.includes('fileName')
			? 'fileName'
			: '';
		if (payload === '') return;
		const sortDirection = event.target.attributes.eventkey.value.includes('asc')
			? 'asc'
			: 'desc';
		let updatedSortState = currentSort;
		updatedSortState[payload] = sortDirection === 'asc' ? 'A-z' : 'Z-a';
		setCurrentSort({ ...updatedSortState });
		dispatchCurrentSort({ payload, type: sortDirection });
	};

	const showAddAssetToCollectionModal = useCallback(() => {
		modalCreator.addModal(
			<AddAssetToCollectionDialog
				toggleSelectingState={toggleSelectingState}
				assets={selectedAssets as AssetVersion[]}
			/>
		);
	}, [selectedAssets, modalCreator, toggleSelectingState]);

	const launchAssetViewer = useCallback(async () => {
		if (!isLaunching) {
			setIsLaunching(true);
			await assetViewer
				.viewSingle(selectedAssets[0])
				.finally(() => setIsLaunching(false));
			dispatchClick({ tool: AssetSelectionTool.VIEW });
		} else
			RomeSwal.fire({
				title: 'The detailed viewer is launching',
				text: 'Please wait..',
				timer: 2000,
			});
	}, [assetViewer, isLaunching, dispatchClick, selectedAssets]);

	const showCompareModal = useCallback(() => {
		modalCreator.addModal(<CompareAssetsDialog assets={selectedAssets} />);
		dispatchClick({ tool: AssetSelectionTool.COMPARE });
	}, [dispatchClick, modalCreator, selectedAssets]);

	const downloadAssets = useCallback(async () => {
		const zipSize = selectedAssets
			?.map(
				(a: AssetVersion | string) =>
					(a as AssetVersion)?.fileSizeBytes
						? (a as AssetVersion)?.fileSizeBytes
						: 0,
				0
			)
			?.reduce((acc, curr) => acc + curr, 0);
		if (zipSize < 2000000000) {
			if (!isDownloading) {
				setisDownloading(true);
				if (selectedAssets.length === 1) {
					// download single asset
					const asset: AssetVersion = selectedAssets[0];
					await downloadAssetFile(asset).catch(() => {
						error(
							`An issue occurred while downloading the ${assetTerm}. Please try again later.`
						);
					});
				} else {
					const response = await zipMultiple(selectedAssets.map((a) => a._id));
					windowModel.openInNewWindow(response.url, 'asset_download');
				}
				setisDownloading(false);
				dispatchClick({ tool: AssetSelectionTool.DOWNLOAD });
			} else
				RomeSwal.fire({
					title: 'Download in progress',
					text: 'An existing download is already processing',
					timer: 2000,
				});
		} else {
			RomeSwal.fire({
				icon: 'error',
				title: 'An error occurred',
				text:
					'This group of assets exceeds the maximum 2 GB allowed for downloading.',
			});
		}

		//eslint-disable-next-line
	}, [dispatchClick, isDownloading, selectedAssets, downloadAssetFile, error]);

	const showShareModal = useCallback(async () => {
		const zipSize = selectedAssets
			?.map(
				(a: AssetVersion | string) =>
					(a as AssetVersion)?.fileSizeBytes
						? (a as AssetVersion)?.fileSizeBytes
						: 0,
				0
			)
			?.reduce((acc, curr) => acc + curr, 0);
		if (zipSize < 2000000000) {
			setIsSharing(true);
			if (selectedAssets.length === 1) {
				// share single asset
				const asset: AssetVersion = selectedAssets[0];
				modalCreator.addModal(<ShareSingleAssetDialog asset={asset} />);
			} else {
				// share multiple assets
				modalCreator.addModal(
					<ShareMultipleAssetsDialog assets={selectedAssets} />
				);
			}
			dispatchClick({ tool: AssetSelectionTool.SHARE });
			setIsSharing(false);
		} else {
			RomeSwal.fire({
				icon: 'error',
				title: 'An error occurred',
				text:
					'This group of assets exceeds the maximum 2 GB allowed for filesharing.',
			});
		}
		//eslint-disable-next-line
	}, [dispatchClick, modalCreator, selectedAssets]);

	const deleteAssets = useCallback(async () => {
		if (!isDeleting) {
			setIsDeleting(true);
			// TODO: trash selected assets (RRR-224)
			const { isConfirmed } = await RomeSwal.fire({
				icon: 'question',
				title: 'Are you sure?',
				text: 'Deleting the assets cannot be reversed.',
				confirmButtonText: 'Delete',
				cancelButtonText: 'Cancel',
				showCancelButton: true,
			});
			if (isConfirmed) {
				selectedAssets.forEach(async (asset) => {
					await deleteOne(asset._id);
				});
				info('Successfully removed ' + selectedAssets.length + ' assets.');
				if (onDelete) onDelete(selectedAssets);
			}
			dispatchClick({ tool: AssetSelectionTool.TRASH });
			setIsDeleting(false);
		}
		//eslint-disable-next-line
	}, [dispatchClick, selectedAssets, error]);

	const onKeyUp = useCallback(
		(event: KeyboardEvent) => {
			if (event.key === 'Escape') {
				toggleSelectingState(false);
			}

			if (event.key === 'Delete') {
				deleteAssets();
			}
		},
		//eslint-disable-next-line
		[toggleSelectingState]
	);

	useEffect(() => {
		// set up
		document.addEventListener('keyup', onKeyUp);

		return () => {
			// clean up
			document.removeEventListener('keyup', onKeyUp);
		};
	}, [onKeyUp]);

	// render methods
	const renderSelectAssetsButton = () => (
		<>
			<ToolbarButton
				id="selectAssetsButton"
				onClick={() => toggleSelectingState(true)}
			>
				<FontAwesomeIcon icon={faPlusSquare} />
			</ToolbarButton>
			<UncontrolledTooltip target="selectAssetsButton">
				Select {pluralAssetsTerm}
			</UncontrolledTooltip>
		</>
	);

	const renderAddToCollection = () => (
		<>
			<ToolbarButton
				id="addToCollectionButton"
				onClick={showAddAssetToCollectionModal}
			>
				<FontAwesomeIcon icon={faFileImage} />
			</ToolbarButton>
			<UncontrolledTooltip target="addToCollectionButton">
				Add to collection
			</UncontrolledTooltip>
		</>
	);
	const renderDropdownToggle = () => (
		<div
			style={{
				background: 'transparent',
				borderRadius: 3,
				color: '#afafaf',
				marginLeft: 6,
			}}
		>
			<span id="sortAssetsButton">
				<img
					alt="Sort Assets"
					src={'/images/sort.svg'}
					style={{ height: '20px', filter: 'contrast(0.15)' }}
				/>
			</span>
			<UncontrolledTooltip target="sortAssetsButton">
				Sort {pluralAssetsTerm}
			</UncontrolledTooltip>
		</div>
	);
	const renderSortAssetsButton = () => (
		<>
			{isUsingAlgoliaSort ? (
				<AssetAlgoliaSortby
					defaultRefinement={`${process.env.REACT_APP_ALGOLIA_DAM_INDEX}`}
					items={[
						{
							value: `${process.env.REACT_APP_ALGOLIA_DAM_INDEX}_alpha-asc`,
							label: 'A - Z',
						},
						{
							value: `${process.env.REACT_APP_ALGOLIA_DAM_INDEX}_alpha-desc`,
							label: 'Z - A',
						},
						{
							value: `${process.env.REACT_APP_ALGOLIA_DAM_INDEX}_new-old`,
							label: 'Newest to Oldest',
						},
						{
							value: `${process.env.REACT_APP_ALGOLIA_DAM_INDEX}_old-new`,
							label: 'Oldest to Newest',
						},
						{
							value: `${process.env.REACT_APP_ALGOLIA_DAM_INDEX}_sm-lg`,
							label: 'Smallest to Largest',
						},
						{
							value: `${process.env.REACT_APP_ALGOLIA_DAM_INDEX}_lg-sm`,
							label: 'Largest to Smallest',
						},
					]}
				/>
			) : (
				<DropDownToggleMenu
					items={dropdownItems}
					onClick={(e) => toggleSortAssets(e)}
					label={renderDropdownToggle()}
					direction={'down'}
				/>
			)}
		</>
	);

	const renderCancelSelectionButton = () => (
		<>
			<ToolbarButton
				id="cancelStateButton"
				onClick={() => toggleSelectingState(false)}
			>
				<FontAwesomeIcon icon={faTimes} />
			</ToolbarButton>
			<UncontrolledTooltip target="cancelStateButton">
				Cancel selecting {pluralAssetsTerm}
			</UncontrolledTooltip>
		</>
	);

	const renderViewerButton = () => (
		<>
			<ToolbarButton id="launchViewerButton" onClick={launchAssetViewer}>
				<FontAwesomeIcon
					className={isLaunching ? 'fa-spin' : ''}
					icon={isLaunching ? faSpinner : faCloudUploadAlt}
				/>
			</ToolbarButton>
			<UncontrolledTooltip target="launchViewerButton">
				Launch {assetTerm} in detailed viewer
			</UncontrolledTooltip>
		</>
	);

	const renderCompareButton = () => (
		<>
			<ToolbarButton id="compareAssetsButton" onClick={showCompareModal}>
				<FontAwesomeIcon icon={faCopy} />
			</ToolbarButton>
			<UncontrolledTooltip target="compareAssetsButton">
				Compare {pluralAssetsTerm}
			</UncontrolledTooltip>
		</>
	);

	const renderDownloadButton = () => (
		<>
			<ToolbarButton id="downloadAssetsButton" onClick={downloadAssets}>
				<FontAwesomeIcon
					className={isDownloading ? 'fa-spin' : ''}
					icon={isDownloading ? faSpinner : faDownload}
				/>
			</ToolbarButton>
			<UncontrolledTooltip target="downloadAssetsButton">
				Download {pluralAssetsTerm}
			</UncontrolledTooltip>
		</>
	);

	const renderShareButton = () => (
		<>
			<ToolbarButton id="shareAssetsButton" onClick={showShareModal}>
				<FontAwesomeIcon
					className={isSharing ? 'fa-spin' : ''}
					icon={isSharing ? faSpinner : faShare}
				/>
			</ToolbarButton>
			<UncontrolledTooltip target="shareAssetsButton">
				Share {pluralAssetsTerm}
			</UncontrolledTooltip>
		</>
	);

	const renderTrashButton = () => (
		<>
			<ToolbarButton id="deleteAssetsButton" onClick={deleteAssets}>
				<FontAwesomeIcon
					className={isDeleting ? 'fa-spin' : ''}
					icon={isDeleting ? faSpinner : faTrash}
				/>
			</ToolbarButton>
			<UncontrolledTooltip target="deleteAssetsButton">
				Delete {pluralAssetsTerm}
			</UncontrolledTooltip>
		</>
	);

	const render = () => (
		<Toolbar className={buildClassList('assets-toolbar', className)}>
			{isViewToolAvailable() ? renderViewerButton() : null}
			{isCompareToolAvailable() ? renderCompareButton() : null}
			{isDownloadToolAvailable() ? renderDownloadButton() : null}
			{isShareToolAvailable() ? renderShareButton() : null}
			{isTrashToolAvailable() ? renderTrashButton() : null}
			{!isSelectingAssets ? renderSelectAssetsButton() : null}
			{isAddToCollectionToolAvailable() ? renderAddToCollection() : null}
			{isSelectingAssets ? renderCancelSelectionButton() : null}
			{renderSortAssetsButton()}
		</Toolbar>
	);

	return render();
};

export default AssetSelectionToolbar;
