import { navigate } from '@reach/router';
import { useWorkflowContext } from 'context/useWorkflowStore';
import React, { FormEvent, useState } from 'react';
// @ts-ignore
import { useAxios } from '../../../../hooks';
import { buildEmptyAssetMetadata } from '../../../dam-assets/components/helpers/useAssetHelper';
import { Workflow, WorkflowTemplate } from '../types/workflow.types';
import EditWorkflowForm from './edit-workflow-form.component';
import { NotificationsContext } from '../../../../components/notifications';

// Adding this here because TS is a stupid bitch and seems to think the module doesn't exist when trying to import it
function generateID() {
	const hex = (x: number) => (~~x).toString(16);
	const randomByte = () => hex(Math.random() * 16);
	return `${hex(Date.now() / 1000)}${' '.repeat(16).replace(/./g, randomByte)}`;
}

function addWorkflowReducer(
	state: { editedWorkflow: Workflow; fetched: boolean },
	action: { type: 'set' | 'update'; payload: any[] | Workflow }
) {
	switch (action.type) {
		case 'set':
			return { ...state, templates: action.payload as any[], fetched: true };
		case 'update':
			return { ...state, editedWorkflow: action.payload as Workflow };
		default:
			return state;
	}
}
const AddWorkflowForm = () => {
	const workflowStore = useAxios<Workflow>('workflows');
	const templateStore = useAxios<WorkflowTemplate>('templates');
	const { error } = React.useContext(NotificationsContext);
	const workflowContext = useWorkflowContext();
	// eslint-disable-next-line
	const [, setFetching] = useState(false);
	const [templateUsed, setTemplateUsed] = React.useState<WorkflowTemplate>();
	const emptyWorkflow = (templateUsed: string) =>
		({
			_id: generateID(),
			templateUsed: templateUsed,
			title: '',
			metadata: buildEmptyAssetMetadata,
		} as Workflow);
	const [workflowRef, setWf] = React.useState(emptyWorkflow(''));
	const [templates, setTemplates] = React.useState<WorkflowTemplate[]>();
	const [, dispatch] = React.useReducer(addWorkflowReducer, {
		fetched: false,
		editedWorkflow: workflowRef,
	});

	const onChange = (updated: Workflow) => {
		setWf(updated);
	};

	React.useEffect(() => {
		if (!templates) {
			setFetching(true);
			templateStore
				.findAll()
				.then(setTemplates)
				.finally(() => setFetching(false));
		}
		//eslint-disable-next-line
	}, []);

	React.useEffect(() => {
		const hash = window.location.hash.replace('#', '');
		if (hash && templates) {
			window.history.replaceState(
				null,
				document.title,
				window.location.pathname
			);
			const resolvedTemplate = hash;
			if (resolvedTemplate) {
				setTemplateUsed(templates.find((m) => m._id === resolvedTemplate));
				setWf(() => {
					return { ...workflowRef, templateUsed: resolvedTemplate };
				});
			}
		}
	}, [templates, workflowRef]);

	const addWorkflow = async (event: FormEvent & { data: Workflow }) => {
		try {
			const createdWorkflow = (await workflowStore.createOne({
				...event.data,
				_id: generateID(),
			})) as Workflow;
			workflowContext.addToContext(createdWorkflow);
			return navigate(
				`/admin/workflow/workflows/${createdWorkflow?._id}/stages`
			);
		} catch (err) {
			// @ts-ignore
			error(
				err?.message
					? err.message
					: 'Failed to create new workflow. Please try again later'
			);
		}
	};

	return (
		<EditWorkflowForm
			templates={templates}
			setWf={dispatch}
			workflow={workflowRef}
			onSubmit={async (evt) => await addWorkflow(evt)}
			onChange={onChange}
			templateUsed={templateUsed?._id as string}
		/>
	);
};
export default AddWorkflowForm;
