import { faSun } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect } from 'react';
import ThemedToggleSwitch from 'components/user-preferences/components/ThemeToggleSwitch';

export const ThemeToggle = ({ themeSwitch = (f: any) => f }) => {
	const [isDarkMode, setIsDarkMode] = useState(false);
	return (
		<div style={{ position: 'absolute', top: 30, right: 400, zIndex: 1 }}>
			<ThemedToggleSwitch
				customSvg={
					isDarkMode ? (
						<svg
							style={{
								height: '1.5rem',
								width: '1.5rem',
								position: 'absolute',
								top: '5px',
								right: 30,
							}}
							className="MuiSvgIcon-root Navigation_MoonIcon__3vodC"
							focusable="false"
							viewBox="0 0 24 24"
							aria-hidden="true"
						>
							<path
								fill={isDarkMode ? '#fff' : '#222'}
								d="M9 2c-1.05 0-2.05.16-3 .46 4.06 1.27 7 5.06 7 9.54 0 4.48-2.94 8.27-7 9.54.95.3 1.95.46 3 .46 5.52 0 10-4.48 10-10S14.52 2 9 2z"
							></path>
						</svg>
					) : (
						<FontAwesomeIcon
							style={{
								height: '1.5rem',
								width: '1.5rem',
								position: 'absolute',
								color: '#222',
								top: '5px',
								right: 30,
							}}
							icon={faSun}
						/>
					)
				}
				className="themeToggle"
				type="button"
				onClick={themeSwitch}
				onStateChanged={(state: boolean) => setIsDarkMode(state)}
				aria-label="change theme color"
			/>
		</div>
	);
};
export const Layout = ({ children }: { children: any }) => {
	const [lightMode, setLightMode] = useState('default');

	useEffect(() => {
		const localTheme = window.localStorage.getItem('lightMode');
		setLightMode(localTheme as string);
		if (localTheme === 'default' || !localTheme) {
			document.body.classList.add('is_inverted');
		} else {
			document.body.classList.remove('is_inverted');
		}
	}, [lightMode]);

	/**
	 * TODO: put core-ui toggle in feature flag block
	 */

	return (
		<div className="layout">
			{/* <ThemeToggle themeSwitch={toggleTheme} /> */}
			{children}
		</div>
	);
};
