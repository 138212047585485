import { Maybe } from 'types/globals';

export * from './array.utils';
export {
	getDifferentPastel,
	getRandomDarkColor,
	getRandomPastel,
	getRGBfromHEX,
} from './color.utils';
export * from './enum.utils';
export * from './fn.utils';
export * from './hooks';
export * from './math';
export * from './messages';
export * from './mobx.utils';
export * from './NullableContext';
export * from './react.utils';
export * from './storage';
export * from './string.utils';

export const loadingValue = '$loading';

export const isProd = process.env.NODE_ENV === 'production';

export const requiredValue = <T>(v: Maybe<T>): T => {
	if (!v) {
		throw new Error('Missing required environment variable');
	}
	return v;
};

export const stampUrl = (url: string, updatedAt: Date | undefined | '') => {
	if (url.startsWith('blob') || url.startsWith('data') || !updatedAt) {
		return url;
	}
	const timestamp =
		updatedAt instanceof Date ? updatedAt.toISOString() : updatedAt;
	return `${url}?ts=${encodeURIComponent(timestamp)}`;
};

export const requiredProdValue = <T>(v: Maybe<T>): Maybe<T> => {
	if (isProd) {
		return requiredValue(v);
	}
	return v;
};
