import { StageType } from 'components/workflow/workflow-templates/models';
import { BaseWorkflowOwner } from 'components/workflow/workflows/types/workflow.types';
import { useWorkflowContext } from 'context/useWorkflowStore';
import React, { ReactElement, useState } from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import themeStore from '../../../../../core-ui/models/ThemeStore';
import { sanitizeForDOMElementId } from '../../../../../utils/common';
import OwnerAvatarList from '../../../../owner-avatar-list.component';
import { OwnerButton } from './assign-stage-owner-button.styled-components';
import AssignStageOwnerDialog from './assign-stage-owner-dialog.component';
interface Props {
	children?: ReactElement;
}

export const AssignStageOwnerButton = (props: Props) => {
	const [isOpen, setIsOpen] = useState(false);
	const { stage } = useWorkflowContext();
	const buildButtonId = (title: string) => {
		if (!title) return `button`;
		return `button${sanitizeForDOMElementId(title)}`;
	};

	return (
		<>
			<AssignStageOwnerDialog
				isOpen={isOpen}
				onClose={() => setIsOpen(false)}
			/>
			<div>
				<UncontrolledTooltip target={buildButtonId(stage?.title as string)}>
					Click to edit {themeStore._.owner.toLowerCase()} list
				</UncontrolledTooltip>
				<OwnerButton
					onClick={(e: any) => {
						e.preventDefault();
						e.stopPropagation();
						setIsOpen((b) => !b && stage?.type !== StageType.parallel);
					}}
					id={buildButtonId(stage?.title as string)}
				>
					<OwnerAvatarList
						displayNames={false}
						owners={stage?.owners as BaseWorkflowOwner[]}
						className="align-self-center"
					/>
					{props.children ?? 'Unassigned'}
				</OwnerButton>
			</div>
		</>
	);
};
