import { Menu } from '../../Menu';
import styled, { css } from 'styled-components';
import { labelStyles } from '../Input';
import { ffTextBody, fsNormal } from '../../../core-ui/ui/typography';
import { Transition } from '../../Animations';

const prettyScrollbar = css`
	/* width */
	::-webkit-scrollbar {
		width: 10px;
	}

	/* Track */
	::-webkit-scrollbar-track {
		background: #f1f1f1;
	}

	/* Handle */
	::-webkit-scrollbar-thumb {
		background: #888;
	}

	/* Handle on hover */
	::-webkit-scrollbar-thumb:hover {
		background: #555;
	}
`;

export const SelectWrapper = styled.div`
	width: 100%;
	position: relative;
	display: flex;
	font-size: 14px;
	flex-flow: column wrap;
	padding: 0px;
	border: 1px solid var(--lightGrey);
	.label {
		${labelStyles}
		background: transparent;
		margin-top: -20px;
	}

	.error {
		color: red;
		margin: 0px;
		margin-left: 3px;
		padding: 0px;
		padding-top: 2px;
	}

	.field-button {
		${prettyScrollbar};
		padding-left: 2%;
		overflow-y: auto;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		min-height: 30px;
		width: 100%;
		${ffTextBody};
		${fsNormal};
		border-radius: 4px;
		padding: 8px 12px;
		position: relative;
		z-index: 0;
		border: 1px solid var(--lightGrey);

		:active,
		:focus {
			border-bottom: none;
		}
	}

	.dropdown-icon {
		position: absolute;
		right: 15px;
	}

	.options-menu-container {
		max-height: 300px;
		display: flex;
		flex-direction: column;
		overflow-y: auto;
		${prettyScrollbar}
	}

	${Menu} {
		width: 100%;
		display: flex;
		flex-direction: column;
	}
`;

export const OptionLabelsList = styled.div`
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	${prettyScrollbar};
	overflow-y: auto;
`;

export const SelectionTag = styled(Transition)`
	background: #416c65;
	color: white;
	padding: 5px 10px;
	margin: 2px 3px;
	display: flex;
	width: auto;
	justify-content: center;
	align-items: center;
	border-radius: 20px;
	height: fit-content;

	.icon {
		margin-left: 5px;
		color: white;
		transition: all 0.2s ease-in-out;

		:hover {
			transform: scale(1.1);
			background: #6c757d;
			color: white;
		}
	}
`;

export const Placeholder = styled.div`
	display: flex;
	justify-content: flex-start;
	text-align: start;
	color: #767676;
`;
