export const SET_STAGE_START = 'STAGE_REDUCER_START';
export const SET_STAGE_SUCCESS = 'STAGE_REDUCER_SUCCESS';
export const SET_STAGE_FAILURE = 'STAGE_REDUCER_FAILURE';

export const SET_WORKFLOW_START = 'WORKFLOW_START';
export const SET_WORKFLOW_SUCCESS = 'WORKFLOW_SUCCESS';
export const SET_WORKFLOW_FAILURE = 'WORKFLOW_FAILURE';

export const workflowDefaultFilters = {
	totalPages: 1,
	totalDocs: 1,
	currentPage: 1,
	sort: '',
	template: '',
	filters: [],
	searchTerm: '',
};

export const stagesDefaultFilters = {
	stagesTotalPages: 1,
	stagesTotalDocs: 1,
	stagesCurrentPage: 1,
	stagesSort: '',
	stagesTemplate: '',
	stagesFilters: [],
	stagesSearchTerm: '',
};

export const templateDefaultFilters = {
	templatesTotalPages: 1,
	templatesTotalDocs: 1,
	templatesCurrentPage: 1,
	templatesSort: '',
	templatesFilters: [],
	templatesSearchTerm: '',
};
