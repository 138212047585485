import { damAssetRefinementSettings } from '../dam-asset-refinements';
import { useAccounts } from 'context/useAccounts';
import { processAsset, filterByMetadataAndPermissions } from '../../../utils';
import { ToggleCollectionView } from 'components/ToggleCollectionView/ToggleCollectionView';
import { SelectionWarning } from 'components/alert';
import { PermissionsObject } from '../../accounts/types';
import { orderBy } from 'lodash';
import React, { useCallback, useState } from 'react';
import { InfiniteHitsProvided } from 'react-instantsearch-core';
import { connectInfiniteHits } from 'react-instantsearch-dom';
import { Col, Row } from 'reactstrap';
import styled from 'styled-components';
import { CustomClearRefinements } from '../../algolia/clear-refinements';
import { SortOrder } from '../../dam-asset-collections/components/facet-tree-custom-hits.component';
import SubmitButton from '../../forms/submit-button.component';
import Loading from '../../loading.component';
import RenderWhen from '../../render-when.component';
import { AssetVersion } from '../../workflow/workflows/types/workflow.types';
import AssetCardGrid from './asset-card-grid.component';
import AssetListTable from './asset-list-table.component';
import { useSiteSettings } from 'hooks/useSiteSettings';
import AssetSelectionToolbar, {
	AssetSelectionTool,
	AssetSelectionToolbarClickEvent,
	defaultAssetSelectionTools,
} from './asset-selection-toolbar.component';
import * as H from '../../../components/workflow/workflows/helpers/index';
import AssetAlgoliaSortby from './asset-algolia-sortby';

const CustomRefinementList = React.lazy(() =>
	import('../../algolia/refinement-list.component')
);

interface SiteSettings {
	permissionFields?: any[];
}

interface AssetHits extends InfiniteHitsProvided<AlgoliaAssetVersion> {
	isRefining: boolean;
	isRefiningCallback: (isRefining: boolean) => void;
	availableRefinements: string[];
	permissionsObject?: PermissionsObject;
	searchHistory?: any;
	setSearchHistory?: any;
}

export interface AlgoliaAssetVersion extends AssetVersion {
	__queryID?: string;
	objectID: string;
	metadata: any;
	tags: any;
}

export type CollectionID = string;

const Tools = styled(Col)`
	display: flex;
	justify-content: flex-end;
`;

const NoAssetResults = () => (
	<Col lg={12}>
		<p>No results</p>
	</Col>
);

const AssetInfiniteHits = (props: AssetHits) => {
	const appSettings = useSiteSettings();
	const [isSelectingAssets, setSelectingAssets] = useState(false);
	const [selectedAssets, setSelectedAssets] = useState<
		Array<AlgoliaAssetVersion>
	>([]);
	const [isSelecting, setIsSelecting] = useState<boolean>(false);
	const { defaultAct } = useAccounts();
	const siteSettings = appSettings.settings as SiteSettings;
	const permissionFields = Array.isArray(siteSettings['permissionFields'])
		? siteSettings['permissionFields']
		: [];
	const toolbarCallBack = useCallback(
		(event: AssetSelectionToolbarClickEvent) => {
			if (event.tool === AssetSelectionTool.SELECTION) {
				const isSelecting: boolean = event.value;
				setIsSelecting(isSelecting);
				setSelectingAssets(isSelecting);
				if (!isSelecting) {
					// empty selected assets if selecting is turned off
					setSelectedAssets([]);
				}
			}
		},
		[setSelectingAssets, setIsSelecting]
	);

	const assetSelectionCallBack = useCallback(
		(asset: AlgoliaAssetVersion) => {
			const index = selectedAssets.indexOf(asset);
			if (index > -1) {
				// remove from selection list
				setSelectedAssets((selectedAssets) =>
					selectedAssets.filter((a) => a._id !== asset._id)
				);
			} else {
				// add to selection list
				setSelectedAssets((selectedAssets) => selectedAssets.concat(asset));
			}
		},
		[selectedAssets]
	);

	const renderSelectionWarning = () => {
		const mimeTypes = ['BMP', 'JPEG', 'PDF', 'PNG', 'SVG', 'TIF'].join(', ');
		return (
			<div style={{ marginTop: '0.11rem' }}>
				<SelectionWarning
					message={`Asset comparison is enabled for the the following types: ${mimeTypes} `}
				/>
			</div>
		);
	};

	// render methods
	const renderAssetCardGrid = (assets: AlgoliaAssetVersion[]) => (
		<AssetCardGrid
			onDelete={(asset) =>
				setAssetList({
					type: 'SET',
					payload: assetList.assets.filter((a) => a._id !== asset._id),
				})
			}
			assets={assets}
			areLinks={!isSelectingAssets}
			isSelectionAvailable={true}
			onAssetSelected={assetSelectionCallBack}
			selectedAssets={selectedAssets}
		/>
	);

	const renderAssetList = (assets: AlgoliaAssetVersion[]) => (
		<AssetListTable
			onDelete={(a) =>
				setAssetList({
					type: 'SET',
					payload: assetList.assets.filter((asset) => a._id !== asset._id),
				})
			}
			assets={assets}
			isSelectingAssets={isSelectingAssets}
			onAssetSelected={assetSelectionCallBack}
			selectedAssets={selectedAssets}
			metadataProperties={[]}
		/>
	);

	/**
	 * This method renders the assets in a list or grid based on the users current view selection
	 * @param assets the assets to be rendered
	 * @param viewType the users current view type
	 */
	const renderAssets = (assets: AlgoliaAssetVersion[], viewType = 'cards') => {
		if (!assets?.length) {
			return !isLoading ? <NoAssetResults /> : null;
		}

		return viewType === 'cards'
			? renderAssetCardGrid(assets)
			: renderAssetList(assets);
	};

	/**
	 * this reducer handles sorting of the assets
	 */
	const [currentSort, dispatchCurrentSort] = React.useReducer(function (
		currentSortOrder: SortOrder,
		action: { type: 'asc' | 'desc'; payload?: string }
	) {
		switch (action.type) {
			case 'asc':
				return { sort: action.type, field: action.payload } as SortOrder;
			case 'desc':
				return { sort: action.type, field: action.payload } as SortOrder;
		}
	},
	{} as SortOrder);

	/**
	 * This method takes the users available permissions and passes them into the refinement list component
	 * @param attribute facet to be filtered on
	 */

	const getPermissionsForAttribute = (attribute: string): string[] => {
		switch (attribute) {
			case 'metadata.Brand':
				return props.permissionsObject?.brand as string[];
			case 'metadata.Sub Brand':
				return props.permissionsObject?.subBrand as string[];
			case 'metadata.Country':
				return props.permissionsObject?.country as string[];
			case 'metadata.Size':
				return props.permissionsObject?.size as string[];
			case 'metadata.Category':
				return props.permissionsObject?.category as string[];
			case 'metadata.Printer':
				return props.permissionsObject?.printer as string[];
			case 'type':
				return props.permissionsObject?.filetype as string[];
			case 'metadata.Variety':
				return props.permissionsObject?.variety as string[];
			case 'metadata.Die':
				return props.permissionsObject?.die as string[];
			case 'metadata.Asset Type':
				return props.permissionsObject?.assetType as string[];
			case 'metadata.Label Type':
				return props.permissionsObject?.labelType as string[];
			case 'metadata.Product Category':
				return props.permissionsObject?.productCategory as string[];
			case 'metadata.Package Type':
			case 'metadata.Asset Type':
			case 'metadata.Win The Day Materials':
				return props.permissionsObject?.packageType as string[];
			case 'archived':
				return props.permissionsObject?.archived as string[];
			default:
				return [];
		}
	};

	function assetListReducer(
		state: { assets: AlgoliaAssetVersion[] },
		action: { type: 'SET' | 'CLEAR'; payload?: AlgoliaAssetVersion[] }
	) {
		switch (action.type) {
			case 'SET':
				return {
					...state,
					assets: props.isRefining
						? (action.payload as AlgoliaAssetVersion[])
						: [],
				};
			case 'CLEAR':
				return { ...state, assets: [] };
			default:
				return state;
		}
	}

	/**
	 * This function filters assets based on user permissions and maps them from AlgoliaAssetVersion to DAM Assets.
	 * It also handles unavailable refinements due to permissions.
	 *
	 * @param algoliaAssets - The list of assets returned from Algolia.
	 */
	const filterAssetsByUserPermissions = (
		algoliaAssets: AlgoliaAssetVersion[]
	) => {
		// Set loading state to true while filtering assets
		setIsLoading(true);

		/**
		 * This function checks if an individual asset should be included based on the user's permissions.
		 *
		 * @param asset - The asset to check.
		 * @returns Whether the asset should be included.
		 */
		const shouldIncludeAsset = (asset: AssetVersion): boolean => {
			// If account is using DAM user group permissions,
			// filter the asset based on metadata and permissions.
			// Otherwise, include the asset by default.
			return defaultAct?.useDAMUserGroupPermissions
				? filterByMetadataAndPermissions(
						asset.metadata?.values as Record<string, string>,
						permissionFields,
						props.permissionsObject
				  )
				: true;
		};

		// Process all assets and filter them based on the user's permissions.
		const processedAssets = algoliaAssets.map(processAsset);
		const filteredAssetList = processedAssets.filter(shouldIncludeAsset);

		// Set loading state to false after filtering assets
		setIsLoading(false);

		return filteredAssetList;
	};
	//eslint-disable-next-line
	const [assetList, setAssetList] = React.useReducer(assetListReducer, {
		assets: Array<AlgoliaAssetVersion>(),
	});

	const [isLoading, setIsLoading] = useState(false);

	React.useEffect(() => {
		if (!props.isRefining) {
			setAssetList({ type: 'CLEAR' });
		}
	}, [props.isRefining]);

	React.useEffect(() => {
		const assets = filterAssetsByUserPermissions(props.hits);
		const filtered = assets;
		if (currentSort && currentSort.sort && currentSort.field) {
			if (currentSort.field === 'fileName') {
				setAssetList({
					type: 'SET',
					payload: [
						...H.alphaSortByWorkflowTitle(
							filtered,
							currentSort.sort === 'desc'
						),
					],
				});
			} else if (
				currentSort.field === 'fileSizeBytes' ||
				currentSort.field === 'createdAt'
			) {
				setAssetList({
					type: 'SET',
					payload: [
						...orderBy(
							filtered as AlgoliaAssetVersion[],
							(i) => i[currentSort.field] ?? i.title,
							currentSort.sort
						),
					],
				});
			}
		} else {
			setAssetList({
				type: 'SET',
				payload: [...(filtered as AlgoliaAssetVersion[])],
			});

			// eslint-disable-next-line
		}
	}, [props.hits]);

	const [viewPref, setViewPref] = useState<'cards' | 'list'>('cards');

	const render = () => {
		return (
			<>
				<Row className="align-items-center">
					<Col sm={9} xs={8} className="my-3">
						<Row>
							{damAssetRefinementSettings?.map((refinementSettings) =>
								refinementSettings.attribute !== 'archived' ? (
									<CustomRefinementList
										showMoreLimit={500}
										showMore
										// @ts-ignore
										availableRefinements={getPermissionsForAttribute(
											refinementSettings.attribute
										)}
										isRefining={props.isRefining}
										isRefiningCallback={props.isRefiningCallback}
										key={refinementSettings.attribute}
										label={refinementSettings.label}
										attribute={refinementSettings.attribute}
										searchable={refinementSettings.searchable}
										searchHistory={props.searchHistory}
										setSearchHistory={props.setSearchHistory}
									/>
								) : (
									<CustomRefinementList
										defaultRefinement={['false']}
										showMoreLimit={500}
										showMore
										// @ts-ignore
										availableRefinements={getPermissionsForAttribute(
											refinementSettings.attribute
										)}
										isRefining={props.isRefining}
										isRefiningCallback={props.isRefiningCallback}
										key={refinementSettings.attribute}
										label={refinementSettings.label}
										attribute={refinementSettings.attribute}
										searchable={refinementSettings.searchable}
										searchHistory={props.searchHistory}
										setSearchHistory={props.setSearchHistory}
									/>
								)
							)}

							<CustomClearRefinements
								// @ts-ignore
								setSearchHistory={props.setSearchHistory}
								clearRefiningCallback={props.isRefiningCallback}
							/>
						</Row>
					</Col>
					<Tools sm={3} className="p-0" xs={4}>
						<AssetSelectionToolbar
							isUsingAlgoliaSort={true}
							AssetAlgoliaSortby={AssetAlgoliaSortby}
							onDelete={(deletedAssets: AssetVersion[]) => {
								setAssetList({
									type: 'SET',
									payload: assetList.assets.filter(
										(a) => !deletedAssets.some((b) => b._id === a._id)
									),
								});
								setSelectedAssets([]);
							}}
							tools={defaultAssetSelectionTools}
							className="asset-index-toolbar"
							onClick={toolbarCallBack}
							selectedAssets={selectedAssets}
							dispatchCurrentSort={dispatchCurrentSort}
						/>
						<ToggleCollectionView
							toggle={() => {
								setViewPref((v) => (v === 'cards' ? 'list' : 'cards'));
							}}
							cards={true}
							currentView={viewPref}
						/>
					</Tools>
				</Row>
				<Row
					style={{ marginBottom: '10px', justifyContent: 'center' }}
					className="align-items-center"
				>
					{
						/* temporarily commenting out */ false &&
							isSelecting &&
							renderSelectionWarning()
					}
				</Row>
				<Row>
					{renderAssets(
						assetList.assets,
						viewPref === 'cards' ? 'cards' : 'list'
					)}
					{isLoading && <Loading label={'Loading assets..'} />}
					<RenderWhen when={props.hasMore && props.isRefining}>
						<Col xs={12} className="text-center">
							<SubmitButton label="Show more" onClick={props.refineNext} />
						</Col>
					</RenderWhen>
				</Row>
			</>
		);
	};
	return render();
};

const CustomAssetHits = connectInfiniteHits(AssetInfiniteHits);
export default CustomAssetHits;
