import { PermissionsObject } from '../../components/accounts/types';

/**
 * Filters out assets based on their metadata and permissions.
 *
 * @param metadata - The metadata of the asset to check.
 * @param permissions - The permissions associated with the assets.
 * @returns A boolean indicating whether the asset should be included (true) or excluded (false).
 */
export const filterByMetadataAndPermissions = (
	metadata: Record<string, string>,
	fields: string[],
	permissions?: PermissionsObject
): boolean => {
	if (!metadata || !permissions) return false;

	for (const field of fields) {
		// Iterate through each possible field and retrieve from metadata
		const metadataField =
			field === 'type'
				? metadata.type
				: metadata[field.charAt(0).toUpperCase() + field.slice(1)];

		if (permissions[field]?.length && metadataField) {
			// If the metadataField is an array, get the first
			// value, otherwise get the metadataField value itself
			const value = Array.isArray(metadataField)
				? metadataField[0]
				: metadataField;

			const normalizedValue =
				typeof value === 'string' ? value.toLowerCase().trim() : value;

			// Check if the normalized permission value matches the
			// normalized metadata value
			const passingPerms = permissions[field].some((e: any) => {
				// Normalize the permission value to
				// a lowercase string if it is a string
				const eNormalized = typeof e === 'string' ? e.toLowerCase().trim() : e;
				// console.debug(field, eNormalized, normalizedValue);
				return eNormalized === normalizedValue;
			});

			return passingPerms;

			// If the field exists in the metadata, but there is no
			// corresponding permission, return false
		} else if (doesExist(metadataField)) {
			return false;
		}
	}

	return true;
};

/**
 * Function to check if the given item exists.
 * It treats an empty array as non-existent and a non-empty array as existent
 * only if it contains at least one truthy value.
 * For non-array items, it simply checks if the item is truthy.
 *
 * @param {any} item - The item to check.
 * @returns {boolean} - Returns true if the item exists, false otherwise.
 */
const doesExist = (item: any): boolean => {
	if (!item) {
		return false;
	}

	if (Array.isArray(item)) {
		if (item.length === 0) {
			return false;
		}

		if (!item.some((a: any) => !!a)) {
			return false;
		}
	}

	return true;
};
