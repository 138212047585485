import { faDownload, faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { navigate, useMatch } from '@reach/router';
import { RomeSwal } from 'components/alert';
import { isArray } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import Moment from 'react-moment';
import { Badge, Col, Container, Row } from 'reactstrap';
import styled from 'styled-components';
import { Maybe } from 'types/globals';
import { useAuthContext } from 'utils/auth';
import { humanReadableFileSizeSI } from 'utils/common';
import { RoleContext } from '../../../../context/PermissionsContext';
import { useWorkflowContext } from '../../../../context/useWorkflowStore';
import themeStore from '../../../../core-ui/models/ThemeStore';
import { useAxios } from '../../../../hooks';
import { _logError } from '../../../../utils/common/log';
import { useModalCloser, useModalCreator } from '../../../../utils/ModalStack';
import { SecondaryButton } from '../../../buttons.styled-components';
import {
	EntityPropLabel,
	EntityPropList,
	EntityPropListItem,
} from '../../../entity-details.styled-components';
import { NotificationsContext } from '../../../notifications';
import {
	emptyMetadataTemplate,
	flattenStages,
} from '../../../workflow/workflows/helpers/workflowStage.helpers';
import {
	AssetVersion,
	EntityMetadata,
	InputSlot,
	Stage,
	Workflow,
	WorkflowTemplate,
} from '../../../workflow/workflows/types/workflow.types';
import DeleteAssetDialog from '../delete-asset-dialog.component';
import RemoveAssetDialog from '../remove-asset-dialog.component';
import { useAssetHelper } from '../helpers/useAssetHelper';
import { AssetTabsProps } from './asset-details-tab-set.component';
import { FullWidthButton } from './asset-details-tab-set.styled-components';
import AssetMetadataProps, {
	SortMetadataBy,
} from './asset-metadata-props.component';
import AssetWorkflowProps from './asset-workflow-props.component';
import RenderWhen from '../../../render-when.component';
import { forceReload } from 'utils/common/forceReload.util';

const DownloadButton = styled<any>(FullWidthButton)`
	white-space: nowrap;
	padding: 0.5rem;
	width: 150px;
`;

const assetTerm = themeStore._.asset.toLowerCase();
function workflowsReducer(
	state: { workflows: Workflow[]; templates: WorkflowTemplate[] },
	action: {
		type: 'setWorkflows' | 'setTemplates';
		payload: Workflow[] | WorkflowTemplate[];
	}
) {
	switch (action.type) {
		case 'setWorkflows':
			return { ...state, workflows: action.payload as Workflow[] };
		case 'setTemplates':
			return {
				...state,
				templates: action.payload as WorkflowTemplate[],
			};
		default:
			return state;
	}
}
export const findVersionInWorkflow = (
	asset: AssetVersion | string,
	workflow: Workflow
) => {
	const assetId = typeof asset === 'string' ? asset : asset._id;
	return flattenStages(workflow)?.find(
		(stage) =>
			stage.inputSlots &&
			stage?.inputSlots?.some(
				(slot) =>
					slot &&
					slot.versions &&
					slot.versions?.some(
						(version) => version._id.toString() === assetId.toString()
					)
			)
	);
};
const InfoPanel = ({
	asset: assetVersion,
	collectionId,
	workflowOwner,
	isAssetVersion,
	isEditedByCreatorOnly,
	damAsset,
	onDelete,
	onRemoveFromCollection,
}: AssetTabsProps) => {
	const asset = React.useMemo(() => assetVersion ?? damAsset, [
		assetVersion,
		damAsset,
	]);

	const wfMatch = useMatch(
		'/admin/workflow/workflows/:workflowId/assets/:assetId'
	);
	const { workflow: assetWorkflow } = useWorkflowContext();
	const [workflow, setWorkflow] = React.useState<Workflow>();
	React.useEffect(() => {
		if (wfMatch) {
			setWorkflow(assetWorkflow as Workflow);
		}
	}, [wfMatch, assetWorkflow]);

	const { info, error: showError } = React.useContext(NotificationsContext);
	const { canViewByRole } = React.useContext(RoleContext);
	const { allTemplates } = useWorkflowContext();
	const {
		deleteOne: deleteAsset,
		downloadAssetFile,
		findOne,
		doesPathExist,
		removeAssetFromCollection,
	} = useAssetHelper();
	const { updateOne, entities } = useWorkflowContext();
	const [state, dispatch] = React.useReducer(workflowsReducer, {
		workflows: [],
		templates: [],
	});
	const [archived] = useState(damAsset?.archived ?? asset?.archived ?? false);
	const modalStack = useModalCreator();
	const modalCloser = useModalCloser();
	const wfAssetMatch = useMatch('/admin/workflow/workflows/:workflowId/assets');
	const [metadata] = useState(
		// @ts-ignore
		(damAsset?.metadata || asset?.metadata) ?? emptyMetadataTemplate
	);

	const [pathExists, setExists] = useState(false);
	React.useEffect(() => {
		if (!state.templates.length && allTemplates) {
			dispatch({ type: 'setTemplates', payload: allTemplates });
		}
	}, [state.templates.length, allTemplates]);

	useEffect(() => {
		doesPathExist(asset.path).then((res) => setExists(!!res));
	}, [asset]);

	const downloadAsset = async () => {
		await downloadAssetFile(asset).catch(() => {
			showError(
				`An issue occurred while downloading the ${assetTerm}. Please try again later.`
			);
		});
	};

	const routeToEdit = (damAsset: AssetVersion) => {
		modalCloser.closeModal();
		const href = window.location.href;
		if (href.includes('workflows')) {
			const wfId = href.slice(
				href.indexOf('workflows') + 10,
				href.indexOf('assets') - 1
			);
			return navigate(
				`/admin/dam/assets/${damAsset._id}/workflow/${wfId}/edit`
			);
		}

		return navigate(`/admin/dam/assets/${damAsset._id}/edit`);
	};

	const renderEditLink = (damAsset?: AssetVersion) => {
		if (damAsset && canViewByRole('damEditable') && !isAssetVersion) {
			const routeToEditCb = (e: any) => routeToEdit(damAsset);
			return (
				<Col className={'col-xl-6 mt-2'}>
					<SecondaryButton onClick={routeToEditCb}>
						<FontAwesomeIcon icon={faEdit} className="mr-2" />
						Edit asset
					</SecondaryButton>
				</Col>
			);
		} else {
			return null;
		}
	};

	const assetStore = useAxios<AssetVersion>('assets');
	const workflowStore = useAxios<Workflow>('workflows');

	/**
	 * Removes the relevant asset from the collection in the DB
	 * @param wf: Workflow to delete asset from
	 */
	const removeAssetFromCollectionWithoutWorkflowSubmit = useCallback(
		async (asset: AssetVersion) => {
			const result = await removeAssetFromCollection(asset._id, collectionId!);

			if (result.success) {
				if (onRemoveFromCollection) onRemoveFromCollection();
				modalCloser.closeModal();
				modalCloser.closeModal();
			}
		},
		[]
	);

	/**
	 * Deletes the relevant asset from both algolia and the database
	 * by utilizing the asset store which hits the API
	 * @param wf: Workflow to delete asset from
	 */
	const deleteAssetWithoutWorkflowSubmit = useCallback(
		async (asset: AssetVersion) => {
			try {
				await assetStore.deleteOne(
					asset.versionId ? (asset.versionId as string) : asset._id
				);
				if (onDelete) onDelete();
				modalCloser.closeModal();
				modalCloser.closeModal();
				if (!onDelete)
					info(
						`${themeStore._.asset} ${asset.title} deleted from DAM succesfully.`
					);
				return;
			} catch (error) {
				_logError(error);

				showError(
					`An issue occurred while deleting ${assetTerm.toLowerCase()} ${
						asset.title
					}. Please try again.`
				);
			}
			return false;
		},
		[modalCloser, showError, assetStore, info, onDelete]
	);
	/**
	 * Callback function that will open up the delete modal
	 * allowing user chance to confirm before calling the API to delete asset
	 */
	const openDeleteForNonWorkflowAsset = useCallback(() => {
		modalStack.addModal(
			<DeleteAssetDialog
				asset={asset}
				onDelete={async () => await deleteAssetWithoutWorkflowSubmit(asset)}
			/>
		);
	}, [asset, modalStack, deleteAssetWithoutWorkflowSubmit]);

	const openCollectionRemovalForNonWorkflowAsset = useCallback(() => {
		modalStack.addModal(
			<RemoveAssetDialog
				asset={asset}
				collection={null}
				onRemove={async () =>
					await removeAssetFromCollectionWithoutWorkflowSubmit(asset)
				}
			/>
		);
	}, [asset, modalStack, deleteAssetWithoutWorkflowSubmit]);

	const insertStage = (arr: Array<Stage>, index: number, newItem: any) =>
		[...arr.slice(0, index), newItem, ...arr.slice(index)] as Stage[];

	/**
	 * Callback function that will open up the delete modal
	 * allowing user chance to confirm before calling the API to delete asset
	 */
	const openDeleteModal = useCallback(
		(wf?: Workflow) => {
			if (isEditedByCreatorOnly && workflowOwner !== currentUser._id) {
				RomeSwal.fire({
					icon: 'warning',
					title: 'Permission Denied',
					text: 'You do not have permission to delete this asset.',
				});
				return;
			}

			const onDelete = async () => {
				const workflow =
					assetWorkflow && (await workflowStore.findOne(assetWorkflow._id));

				const assetStage: Maybe<Stage> = findVersionInWorkflow(
					asset,
					workflow as Workflow
				);

				try {
					// in the event that they are deleting an asset from a workflow,
					// this fine one checks to see if the asset is a DAM Asset (in the database),
					// or not

					await deleteAsset(asset._id, assetWorkflow?._id, asset.md5);
				} catch (e) {
					showError(
						`An issue occurred while deleting ${assetTerm.toLowerCase()} ${
							asset.title
						}. Please try again.`
					);
				}
				modalCloser.closeModal();
				modalCloser.closeModal();

				const route = `/admin/workflow/workflows/${workflow?._id}/stages`;
				navigate(route);
				forceReload(route);
				info(
					`${themeStore._.asset} ${asset.title} deleted from Stage: ${
						assetStage && assetStage.title
					} successfully`
				);
				return;
			};

			modalStack.addModal(
				<DeleteAssetDialog
					asset={asset as AssetVersion}
					onDelete={async () => await onDelete()}
				/>
			);
		},
		//eslint-disable-next-line
		[
			asset,
			modalStack,
			deleteAsset,
			findOne,
			info,
			modalCloser,
			showError,
			updateOne,
		]
	);
	const { currentUser, entities: users } = useAuthContext();
	const getCreatorName = (createdBy: any) => {
		if (typeof createdBy !== 'string')
			return createdBy.givenName + ' ' + createdBy.familyName;
		const creator = users?.find(({ _id }) => _id === createdBy);
		return creator?.givenName + ' ' + creator?.familyName;
	};
	const renderComponent = (wf?: Workflow) => {
		const archivedClassName = archived ? 'badge-danger' : 'badge-info';
		return (
			<>
				<EntityPropLabel>
					Asset Status
					<Badge className={`mb-2 ml-2 ${archivedClassName}`}>
						{archived ? 'Archived' : 'Active'}
					</Badge>
				</EntityPropLabel>
				<EntityPropList>
					<EntityPropListItem>
						<EntityPropLabel>File name</EntityPropLabel>
						<p>{asset?.fileName}</p>
					</EntityPropListItem>

					{asset?.title ? (
						<EntityPropListItem>
							<EntityPropLabel>Display name</EntityPropLabel>
							<p>{asset?.title}</p>
						</EntityPropListItem>
					) : null}

					{asset?.createdBy ? (
						<EntityPropListItem>
							<EntityPropLabel>Added by</EntityPropLabel>
							<p>{getCreatorName(asset.createdBy)}</p>
						</EntityPropListItem>
					) : null}

					<EntityPropListItem>
						<EntityPropLabel>Added on</EntityPropLabel>
						<Moment format="MM/DD/YYYY" date={asset?.createdAt} />
					</EntityPropListItem>

					<AssetWorkflowProps workflow={wf} asset={asset} />

					<EntityPropListItem>
						<EntityPropLabel>File size</EntityPropLabel>
						<p>{humanReadableFileSizeSI(asset?.fileSizeBytes)}</p>
					</EntityPropListItem>

					<EntityPropListItem>
						<EntityPropLabel>File type</EntityPropLabel>
						<p>{asset?.type}</p>
					</EntityPropListItem>

					<AssetMetadataProps
						sortBy={
							asset.id ? SortMetadataBy.SortByField : SortMetadataBy.SortByValue
						}
						metadata={metadata as EntityMetadata}
					/>
				</EntityPropList>

				<Row className="mt-1 py-3">
					<RenderWhen when={pathExists}>
						<Col className={'col-xl-6 mt-2'}>
							<DownloadButton onClick={downloadAsset}>
								<FontAwesomeIcon icon={faDownload} className="mr-2" />
								Download {assetTerm}
							</DownloadButton>
						</Col>
					</RenderWhen>
					{renderEditLink(damAsset)}
					{canViewByRole('damEditable') &&
						!window.location.href.includes('dam') && (
							<Col className={'col-xl-6 mt-2'}>
								<SecondaryButton onClick={() => openDeleteModal(wf)}>
									Delete
								</SecondaryButton>
							</Col>
						)}

					{collectionId && window.location.href.includes('dam') && (
						<Col className="mt-2">
							<SecondaryButton
								onClick={() => openCollectionRemovalForNonWorkflowAsset()}
							>
								Remove from Collection
							</SecondaryButton>
						</Col>
					)}

					{canViewByRole('damEditable') &&
						window.location.href.includes('dam') && (
							<Col className="mt-2">
								<SecondaryButton
									onClick={() => openDeleteForNonWorkflowAsset()}
								>
									Delete
								</SecondaryButton>
							</Col>
						)}
				</Row>
			</>
		);
	};
	const extractWorkflow = () => {
		if (workflow) return workflow;
		if (asset && asset.path && asset.path.includes('workflow')) {
			const wfId = asset.path
				.substring(
					asset.path.indexOf('workflow_'),
					asset.path.indexOf('/stage_')
				)
				.replace('workflow_', '');
			// @ts-ignore
			const wf = (entities || []).find((m) => m._id === wfId);
			return wf;
		}
		if (!!entities?.length) {
			return state.workflows.find(
				(workflow: Workflow) =>
					!!flattenStages(workflow)?.find((stage: Stage | Stage[]) =>
						isArray(stage)
							? !!stage.some((sub) =>
									sub?.substages?.length
										? !!sub.substages
												.flatMap((s) => s)
												?.some(
													(substage: Stage) =>
														!!substage.inputSlots?.some(
															(slot: InputSlot) =>
																!!slot?.versions?.some(
																	(version: AssetVersion) =>
																		version.fileName === asset.fileName
																)
														)
												)
										: !!sub.inputSlots?.some(
												(slot: InputSlot) =>
													!!slot.versions?.some(
														(version) => version.fileName === asset.fileName
													)
										  )
							  )
							: stage.inputSlots?.some(
									(slot: InputSlot) =>
										!!slot.versions?.some(
											(version) => version.fileName === asset.fileName
										)
							  )
					)
			) as Workflow;
		}
	};
	const render = () => {
		// TODO: make this less ghetto
		const wf = extractWorkflow();
		return <Container className="p-md-4 py-3">{renderComponent(wf)}</Container>;
	};
	return render();
};

export default InfoPanel;
