import { BaseWorkflowOwner } from 'components/workflow/workflows/types';
import { User } from '../../../utils/auth';
export type GroupMember = BaseWorkflowOwner | User;
export type UserGroup = {
	_id: string;
	title: string;
	members: Array<GroupMember>;
	color: string;
	account?: string;
	viewableDAMFolders: string[];
} & PermissionsTypes;
export const getViewableDamFolders = (group: UserGroup) => {
	return [
		...group.AssetTypePermissions,
		...group.ArchivedPermissions,
		...group.BrandPermissions,
		...group.SubBrandPermissions,
		...group.CategoryPermissions,
		...group.CountPermissions,
		...group.CountryPermissions,
		...group.DielinePermissions,
		...group.FileTypePermissions,
		...group.PackageTypePermissions,
		...group.LabelTypePermissions,
		...group.PrinterPermissions,
		...group.ProductCategoryPermissions,
		...group.VarietyPermissions,
	];
};

export type PermissionsTypes = {
	AssetTypePermissions: string[];
	ArchivedPermissions: string[];
	CountPermissions: string[];
	CountryPermissions: string[];
	BrandPermissions: string[];
	SubBrandPermissions: string[];
	LabelTypePermissions: string[];
	CategoryPermissions: string[];
	DielinePermissions: string[];
	FileTypePermissions: string[];
	PackageTypePermissions: string[];
	PrinterPermissions: string[];
	ProductCategoryPermissions: string[];
	VarietyPermissions: string[];
};

export interface PermissionsObject {
	[key: string]: any;
	assetType: string[];
	brand: string[];
	subBrand: string[];
	labelType: string[];
	country: string[];
	size: string[];
	category: string[];
	die: string[];
	printer: string[];
	packageType: string[];
	productCategory: string[];
	variety: string[];
	filetype: string[];
	archived: string[];
}
