import React, { useEffect, useState } from 'react';
import { WorkflowTemplate } from 'components/workflow/workflows/types';
import { WorkflowsIndexView } from '../WorkflowIndexPage';
import { WorkflowTemplatesTable } from '../components/tables/WorkflowTemplatesTable';
import { useAuthContext } from 'utils/auth';
import { isEqual, orderBy, uniqBy } from 'lodash';
import { useAxios } from 'hooks';
import * as H from '../helpers';
import { useQueryStoreContext } from 'context/useQueryStore';
import { useWorkflowContext } from 'context/useWorkflowStore';

export const WorkflowTemplates = () => {
	const queryStore = useQueryStoreContext();
	const tempQuery = queryStore.entities.find((a) => a.type === 'templates');

	const { setTemplatePageState,
		templatesTotalPages:totalPages,
		templatesTotalDocs,
		templates: templateDocs,
		isFetching,
		fetchAllTemplates
		 } = useWorkflowContext();

	const [allTemplates, setAllTemplates] = useState<WorkflowTemplate[]>([]);
	const [templates, setTemplates] = useState<WorkflowTemplate[]>(templateDocs);
	const templateStore = useAxios<WorkflowTemplate>('templates');
	const [defaultFilter, setDefaultFilter] = useState(
		tempQuery?.filters ?? []
	);
	const { currentUser } = useAuthContext();
	const [sort, setSort] = useState<string>(tempQuery?.sort ?? '');
	const [searchTermState, setSearchTermState] = useState(tempQuery?.value??'');
	const [templatesCurrentPage, setTemplatesCurrentPage] = useState(1);
	const [	templatesTotalPages, setTemplatesTotalPages] = useState(totalPages);

	useEffect(() => {
		fetchAllTemplates(templatesCurrentPage, defaultFilter, sort, searchTermState);
	}, [ defaultFilter,templatesCurrentPage, sort, searchTermState]);

	useEffect(() => {
		setTemplates(templateDocs);
		setTemplatesTotalPages(totalPages)
	}, [templateDocs]);

	const onDelete = React.useCallback((wt: WorkflowTemplate) => {
		setAllTemplates((a) => [
			...a?.filter((template) => template._id !== wt._id),
		]);
		setTemplates((t) => [
			...(t || [])?.filter((template) => template._id !== wt._id),
		]);
	}, []);

	const onUpdate = React.useCallback((wt: WorkflowTemplate) => {
		setAllTemplates((a) => [
			...a?.map((template) =>
				template._id === wt._id
					? { ...template, editingState: wt.editingState }
					: template
			),
		]);
		setTemplates((t) => [
			...(t || [])?.map((template) =>
				template._id === wt._id
					? { ...template, editingState: wt.editingState }
					: template
			),
		]);
	}, []);

	// handleSorting
	const sortingOptions = [
		'A - Z',
		'Z - A',
		'Newest - Oldest',
		'Oldest - Newest',
	].map((val) => ({
		label: val,
		value: val,
	}));

	// Sort Handler
	const handleSort = (sorter: string) => {
		setSort(sorter);
	};

	// prettier-ignore
	const filterOptions = ['Archived', 'Final', 'Draft', 'Created By Me'].map((option) => ({
		label: option,
		value: option,
	}));

	const handleFilter = (filter: string | string[]) => {
		if (filter.length > 0&&Array.isArray(filter)) {
			setDefaultFilter(filter);
		}
		else{
			setDefaultFilter([]);
		} 
	};

	const views = {
		table: (templates: WorkflowTemplate[]) => (
			<WorkflowTemplatesTable
				onUpdate={onUpdate}
				onDelete={onDelete}
				templates={templates}
				isFetching={isFetching}
			/>
		),
	};
	const sorting = {
		options: sortingOptions,
		fn: handleSort,
	};
	const filtersConfig = {
		options: filterOptions,
		fn: handleFilter,
	};

	{
		/* Note: This "old" component doesn't have the updated pagination/filtering  */
	}
	return (
		<WorkflowsIndexView
			title="Templates"
			collection={templates as WorkflowTemplate[]}
			views={views}
			filters={filtersConfig}
			defaultSort={sort}
			sorting={sorting}
			defaultFilter={defaultFilter}
			create
			isFetching={isFetching}
			searchTerm={searchTermState}
			setSearchTerm={setSearchTermState}
			currentPage={templatesCurrentPage}
			setCurrentPage={setTemplatesCurrentPage}
			totalPages={templatesTotalPages}
			totalDocs={templatesTotalDocs}
		/>
	);
};
