import {
	faCodeBranch,
	faFileImage,
	faShare,
	faSync,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { stampUrl } from '../../../utils/common';
import { PaddedBadge } from 'components/dashboard.component/components/workflow-health-flag.component';
import { SubmitButton } from 'components/index';
import { NotificationsContext } from 'components/notifications';
import { fetchingReducer } from 'components/workflows/models/useWorkflowStore';
import { RoleContext } from 'context/PermissionsContext';
import { useThemeContext } from 'context/useThemeContext';
import { isEqual } from 'lodash';
import React, { useCallback, useContext, useEffect } from 'react';
import { Col, Row } from 'reactstrap';
import { useWorkflowContext } from '../../../context/useWorkflowStore';
import themeStore from '../../../core-ui/models/ThemeStore';
import { useModalCloser, useModalCreator } from '../../../utils/ModalStack';
import BackLink from '../../back-link/back-link.component';
import AddAssetToCollectionDialog from '../../dam-asset-collections/components/add-asset-collection-dialog.component';
import { EntityPropLabel } from '../../entity-details.styled-components';
import RenderWhen from '../../render-when.component';
import { Divider, Heading } from '../../ui';
import {
	AssetVersion,
	Workflow,
} from '../../workflow/workflows/types/workflow.types';
import { SupportedMimetypes } from './asset-card-thumbnail.component';
import { useExtractPreview } from './asset-card.component';
import { CardTitleLine, StyledLink } from './asset-card.styled-components';
import AssetDetailsTabSet, {
	DetailsTabs,
} from './asset-details-sidebar/asset-details-tab-set.component';
import { ShareAssetButton } from './asset-details.styled-components';
import DetailedViewButton from './asset-preview/detail-view-button.component';
import ZoomComponent from './asset-preview/Zoom.component';
import { useAssetHelper } from './helpers/useAssetHelper';
import LoadingAssetState from './loading-asset-state.component';
import RelatedAssetsRow from './related-assets-row';
import ShareSingleAssetDialog from './share-dialogs/share-single-asset-dialog.component';
import { ZippedAssetsRow } from './zip-assets-row.component';

const assetTerm = themeStore._.asset;

interface CommonAssetVersionDisplayProps {
	version: AssetVersion;
	collectionId?: string;
	damAsset?: AssetVersion;
	hideBackLink?: boolean;
	workflowId?: string;
	isAssetVersion?: boolean;
	workflowOwner?: string;
	isEditedByCreatorOnly?: boolean;
	workflow?: Workflow;
	onDelete: () => void;
	onRemoveFromCollection?: () => void;
	setAsset?: (asset: AssetVersion) => void;
}

const CommonAssetVersionDisplayReducer = function (
	state: { workflow: Workflow | null },
	action: { type: 'set'; payload: Workflow }
) {
	switch (action.type) {
		case 'set':
			return { ...state, workflow: action.payload };
		default:
			return state;
	}
};

const CommonAssetVersionDisplay = (props: CommonAssetVersionDisplayProps) => {
	const {
		version,
		collectionId,
		damAsset,
		hideBackLink,
		workflowId,
		workflow,
		isAssetVersion,
		onDelete,
		onRemoveFromCollection,
	} = props;
	const { extractPreviewURL } = useExtractPreview();
	const { findOne: findWorkflow } = useWorkflowContext();
	const [state, dispatch] = React.useReducer(CommonAssetVersionDisplayReducer, {
		workflow: workflow as Workflow,
	});
	const [fetchState] = React.useReducer(fetchingReducer, {
		isFetching: false,
	});
	const [fileSource, setFileSource] = React.useState(
		(version as any) ?? damAsset
	);

	const { canViewByRole } = useContext(RoleContext);

	useEffect(() => {
		if (version && !isEqual(fileSource, version)) {
			setFileSource(version);
			setPreviewURL(extractPreviewURL(version));
		} else if (damAsset && !isEqual(fileSource, damAsset)) {
			setFileSource(damAsset);
			setPreviewURL(extractPreviewURL(damAsset));
		}
		//eslint-disable-next-line
	}, [version, damAsset]);

	useEffect(() => {
		const fetchWorkflow = async (id: string) => {
			const workflow = await findWorkflow(id);

			dispatch({
				type: 'set',
				// @ts-ignore
				payload: workflow,
			});
		};
		if (fileSource) {
			let workflowIdentifier = workflowId;
			if (!workflowId) {
				if (fileSource.workflowId) {
					workflowIdentifier = fileSource.workflowId;
				} else if (fileSource?.workflow?.objectID) {
					workflowIdentifier = fileSource?.workflow?.objectID?.replace(
						'Workflow_',
						''
					);
				} else {
					const type = fileSource?.path?.split('/')[1];
					if (type) {
						const typeOfAsset = type.split('_')[0];
						if (typeOfAsset === 'workflow')
							workflowIdentifier = type.split('_')[1];
					}
				}
			}
			if (workflowIdentifier) {
				fetchWorkflow(workflowIdentifier);
			}
		}
		//eslint-disable-next-line
	}, [fileSource]);
	const modalStack = useModalCreator();
	const modalCloser = useModalCloser();
	const showAddToCollectionModal = useCallback(() => {
		modalCloser.closeModal();
		modalStack.addModal(
			<AddAssetToCollectionDialog assets={[fileSource] as AssetVersion[]} />
		);
	}, [modalStack, modalCloser, fileSource]);

	const showShareModal = useCallback(() => {
		modalStack.addModal(
			<ShareSingleAssetDialog asset={fileSource as AssetVersion} />
		);
	}, [fileSource, modalStack]);
	const apiClient = useAssetHelper();
	const { warn } = useContext(NotificationsContext);
	const [, setPreviewURL] = React.useState(
		extractPreviewURL(version)
			? extractPreviewURL(version)
			: extractPreviewURL(damAsset as AssetVersion)
	);

	const refreshPreview = async () => {
		if (
			SupportedMimetypes.some((type) => fileSource.type === type) ||
			fileSource.customPreviewURL
		) {
			const prefix = fileSource?.workflowId ? 'w' : 'd';
			const updatedURL = await apiClient.refreshPreview(fileSource, prefix);
			setPreviewURL(updatedURL);
		} else {
			warn(
				'The asset you are trying to refresh is not previewable, or has no custom preview associated with it.'
			);
		}
	};

	const defaults = useThemeContext();
	const updatedAt = damAsset?.updatedAt || '';

	return (
		<Row>
			{!hideBackLink ? (
				<Col xs={12} className="d-flex justify-content-between">
					<div>
						<BackLink link="/admin/dam/assets" title="Assets" />
						<Heading>{fileSource?.title ?? fileSource?.fileName}</Heading>
					</div>
				</Col>
			) : (
				<></>
			)}
			<Col style={{ overflow: 'auto' }} md={8}>
				<Divider />
				<Row className="my-2 justify-content-between">
					<Col md={8}>
						<CardTitleLine>
							<EntityPropLabel className="mr-4">
								ID:
								{fileSource && fileSource.hasOwnProperty('_id')
									? fileSource?._id
									: ''}
							</EntityPropLabel>
						</CardTitleLine>
					</Col>
					{!isAssetVersion && (
						<Col className="text-right">
							<Row>
								<ShareAssetButton
									className="mr-2"
									onClick={showAddToCollectionModal}
								>
									<FontAwesomeIcon icon={faFileImage} className="mr-2" />
									Add {assetTerm.toLowerCase()} to collection
								</ShareAssetButton>
								<ShareAssetButton onClick={showShareModal}>
									<FontAwesomeIcon icon={faShare} className="mr-2" />
									Share {assetTerm.toLowerCase()}
								</ShareAssetButton>
							</Row>
						</Col>
					)}
					{isAssetVersion && (
						<Col>
							<StyledLink to={`../../${damAsset?._id}`}>
								<PaddedBadge color="info" className={'d-block'}>
									<FontAwesomeIcon
										style={{ color: defaults?.active }}
										icon={faCodeBranch}
									/>{' '}
									VERSION OF {damAsset?.fileName}
								</PaddedBadge>
							</StyledLink>
						</Col>
					)}
				</Row>

				{isAssetVersion && extractPreviewURL(version) && (
					<>
						<ZoomComponent
							className="w-100 img-fluid"
							alt={version?.title}
							src={stampUrl(extractPreviewURL(version), updatedAt)}
						/>
					</>
				)}

				{damAsset && extractPreviewURL(damAsset) && !isAssetVersion && (
					<>
						<Row className="d-flex align-items-baseline my-2 p-0">
							<Col>
								<DetailedViewButton asset={fileSource} />
								<RenderWhen
									when={['damReadOnly', 'damEditable', 'damUploadable'].some(
										canViewByRole
									)}
								>
									<SubmitButton
										label={`Refresh ${themeStore._.asset.toLowerCase()} preview`}
										icon={faSync}
										onClick={refreshPreview}
										className="mx-4 my-2"
									/>
								</RenderWhen>
							</Col>
						</Row>
						<ZoomComponent
							className="w-100 img-fluid"
							alt={fileSource?.title}
							src={stampUrl(extractPreviewURL(damAsset), updatedAt)}
						/>
					</>
				)}
				{fileSource && fileSource?.type === 'application/zip' && (
					<ZippedAssetsRow asset={damAsset} setAsset={() => {}} />
				)}
				{fileSource && state?.workflow && (
					<RelatedAssetsRow
						setAsset={(asset: AssetVersion) => {
							if (props.setAsset) props.setAsset(asset);
						}}
						workflow={state?.workflow as Workflow}
						asset={(fileSource as unknown) as AssetVersion}
					/>
				)}
			</Col>
			<Col md={4}>
				<AssetDetailsTabSet
					workflowOwner={state.workflow?.createdBy?._id}
					isAssetVersion={isAssetVersion}
					isEditedByCreatorOnly={state.workflow?.isEditedByCreatorOnly}
					onDelete={onDelete}
					onRemoveFromCollection={onRemoveFromCollection}
					wf={state.workflow as Workflow}
					asset={version as AssetVersion}
					collectionId={collectionId}
					damAsset={damAsset as AssetVersion}
					tabs={DetailsTabs}
				/>
				<RenderWhen when={fetchState.isFetching}>
					<LoadingAssetState />
				</RenderWhen>
			</Col>
		</Row>
	);
};

export default CommonAssetVersionDisplay;
