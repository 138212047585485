import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { Col, Row } from 'reactstrap';
import {
	buildTemplateUrl,
	buildWorkflowCollectionUrl,
} from '../../../../../utils/common/links';

import {
	EntityPropLabel,
	EntityPropList,
	EntityPropListItem,
} from '../../../../entity-details.styled-components';
import EntityMetadataFields from '../../../../metadata/components/entity-metadata-fields.component';
import themeStore from '../../../../../core-ui/models/ThemeStore';
import {
	EntityMetadata,
	Flattenable,
	Workflow,
	WorkflowCollection,
	WorkflowTemplate,
} from '../../types/workflow.types';

import DetailsPanelEditForm from './details-panel-edit-form.component';

import {
	StyledPropLink,
	WorkflowStatusPillLg,
} from './details-panel.styled-components';
import { dateFromId } from '../../../../../utils/common/mobx.utils';
import { WorkflowDueDate } from '../WorkflowDueDate';
import { RoleContext } from '../../../../../context/PermissionsContext';
import { useAuthContext, User } from '../../../../../utils/auth';
import { useGroupContext } from '../../../../../utils/auth';
import { useWorkflowContext } from 'context/useWorkflowStore';
import { useWorkflowCollectionContext } from 'components/workflow/workflows/hooks/useWorkflowCollectionStore';
import { flattenStages } from 'components/workflow/workflows/helpers';
import { first } from 'lodash';

type Props = {
	workflow?: Workflow | null;
	dispatch: React.Dispatch<{ type: 'set'; payload: Workflow }>;
};
const WorkflowDetailPanel = (props: Props) => {
	const { currentUser } = useAuthContext();
	const { canViewByRole } = React.useContext(RoleContext);
	const { entities: projects } = useWorkflowCollectionContext();
	const { groupsForCurrentUser } = useGroupContext();
	const { canOwnerActOnWorkflow, workflow } = useWorkflowContext();
	const { dispatch } = props;
	const label = themeStore._.workflow;

	const canEditWf =
		canViewByRole('wfEditable') &&
		canOwnerActOnWorkflow(currentUser, groupsForCurrentUser);

	return (
		<>
			{canEditWf ? (
				<DetailsPanelEditForm
					dispatchWf={dispatch}
					workflow={workflow as Workflow}
				/>
			) : (
				<Row className="px-3">
					<Col md={12}>
						<EntityPropList>
							<EntityPropListItem>
								<EntityPropLabel>{label} #</EntityPropLabel>
								<p>{workflow!._id}</p>
							</EntityPropListItem>

							<EntityPropListItem>
								<EntityPropLabel>{label} Template used</EntityPropLabel>
								{workflow?.templateUsed && (
									<StyledPropLink
										to={
											['wfTemplatesReadonly', 'wfTemplatesEditable'].some(
												canViewByRole
											)
												? buildTemplateUrl(
														(workflow?.templateUsed as WorkflowTemplate)?._id
												  )
												: ''
										}
									>
										{(workflow?.templateUsed as WorkflowTemplate)?.title}
									</StyledPropLink>
								)}
							</EntityPropListItem>

							<EntityPropListItem>
								<EntityPropLabel>Created on</EntityPropLabel>
								<p>
									{new Date(
										workflow?.createdAt as string
									).toLocaleDateString() ??
										new Date(
											dateFromId(workflow?._id as string)
										).toLocaleDateString()}
								</p>
							</EntityPropListItem>

							<EntityPropListItem>
								<EntityPropLabel>Created by</EntityPropLabel>
								<p>
									{!!workflow?.createdBy && (
										<span>
											{(workflow?.createdBy as User).givenName}
											{(workflow?.createdBy as User)?.familyName}
										</span>
									)}
								</p>
							</EntityPropListItem>

							{
								// @ts-ignore
								first(flattenStages(workflow as Flattenable))?.status ===
									'active' && (
									<EntityPropListItem>
										<EntityPropLabel>Due date</EntityPropLabel>
										<WorkflowDueDate workflow={workflow!} />
									</EntityPropListItem>
								)
							}

							{workflow?.workflowCollection ? (
								<EntityPropListItem>
									<EntityPropLabel>
										{themeStore._.workflowCollection}
									</EntityPropLabel>
									<StyledPropLink
										to={buildWorkflowCollectionUrl(
											workflow?.workflowCollection as WorkflowCollection
										)}
									>
										{typeof workflow.workflowCollection === 'string'
											? projects.find(
													(m) => m._id === workflow.workflowCollection
											  )?.title
											: (workflow?.workflowCollection as WorkflowCollection)
													.title}
									</StyledPropLink>
								</EntityPropListItem>
							) : null}

							<EntityPropListItem>
								<EntityPropLabel>Status</EntityPropLabel>
								{workflow?.status && (
									<WorkflowStatusPillLg status={workflow?.status} />
								)}
							</EntityPropListItem>

							{workflow?.statusMsg && (
								<EntityPropListItem>
									<EntityPropLabel>Current status message</EntityPropLabel>
									{workflow?.statusMsg}
								</EntityPropListItem>
							)}

							<EntityMetadataFields
								metadata={{
									...(workflow?.metadata as EntityMetadata),
								}}
							/>
						</EntityPropList>
					</Col>
				</Row>
			)}
		</>
	);
};

export default WorkflowDetailPanel;
