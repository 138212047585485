import { UserGroup } from '../../components/accounts/types';
import { EntityMetadataTemplate } from '../../components/workflow/workflows/types/workflow.types';

interface Mappings {
	[key: string]: string;
}

const mappings: Mappings = {
	Size: 'Count',
};

const supportedMimetypes = [
	'image/jpeg',
	'image/png',
	'image/gif',
	'image/bmp',
	'image/tiff',
	'application/pdf',
	'application/eps',
	'application/zip',
	'image/vnd.adobe.photoshop',
	'application/postscript',
];

/**
 * Syncs the group's permissions with the provided field options.
 * It replaces the existing permissions with the options from the field options if they exist.
 * If a group property doesn't exist in the field options, it remains unchanged.
 *
 * @param group - The user group to be synced.
 * @param fieldOptions - The field options from which permissions will be synced.
 *
 * @returns - Returns a new user group with the synced permissions.
 */
const syncGroupToFieldOptions = (
	group: UserGroup,
	fieldOptions: any
): UserGroup => {
	const newGroup = Object.assign({}, group);

	for (let prop in fieldOptions) {
		const cleanName = prop.replace(/ /g, '');
		const groupProp = ((mappings[cleanName] || cleanName) +
			'Permissions') as keyof Object;

		if (newGroup[groupProp] && fieldOptions[prop].options) {
			newGroup[groupProp] = fieldOptions[prop].options;
		}
	}

	return { ...newGroup, FileTypePermissions: supportedMimetypes };
};

// 'image/jpeg',
// 'image/png',
// 'image/gif',
// 'image/bmp',
// 'image/tiff',
// 'application/pdf',
// 'application/eps',
// 'image/vnd.adobe.photoshop',
// 'application/postscript',

/**
 * Hydrate ALL group with templates based on the primary template.
 *
 * @param groups - A list of user groups to be hydrated.
 * @param templates - The templates that could potentially be used for hydration.
 * @param primaryTemplate - The primary template that should be used for hydration if it exists.
 *
 * @returns - Returns a new list of user groups with the 'All' group hydrated.
 */
export const hydrateALLGroup = (
	groups: UserGroup[],
	templates: EntityMetadataTemplate[],
	primaryTemplate: any
): UserGroup[] => {
	const ourTemplate = templates.find((t) => t.title === primaryTemplate);
	if (!ourTemplate) return groups;

	return groups.map((group) => {
		if (group.title.toLowerCase() !== 'all') return group;

		return syncGroupToFieldOptions(group, ourTemplate.fieldOptions);
	});
};
