import React from 'react';

type MaterialIconSvgProps = {
	icon: string;
};

export enum AvailableSvgIconsEnum {
	account_tree = 'account_tree',
	photo_library = 'photo_library',
	description = 'description',
	account_circle = 'account_circle',
}

type AvailableSvgIcons = {
	[key: string]: JSX.Element;
};

const AVAILABLE_SVG_ICONS: AvailableSvgIcons = {
	account_tree: (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			height="48px"
			viewBox="0 0 24 24"
			width="48px"
			fill="#FFF"
			style={{ transform: 'scale(0.5) translate3d(0, 0, 0)' }}
		>
			<path d="M0 0h24v24H0z" fill="none" />
			<path d="M22 11V3h-7v3H9V3H2v8h7V8h2v10h4v3h7v-8h-7v3h-2V8h2v3z" />
		</svg>
	),
	photo_library: (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			height="48px"
			viewBox="0 0 24 24"
			width="48px"
			fill="#FFF"
			style={{ transform: 'scale(0.5) translate3d(0, 0, 0)' }}
		>
			<path d="M0 0h24v24H0z" fill="none" />
			<path d="M22 16V4c0-1.1-.9-2-2-2H8c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2zm-11-4l2.03 2.71L16 11l4 5H8l3-4zM2 6v14c0 1.1.9 2 2 2h14v-2H4V6H2z" />
		</svg>
	),
	description: (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			height="48px"
			viewBox="0 0 24 24"
			width="48px"
			fill="#FFF"
			style={{ transform: 'scale(0.5) translate3d(0, 0, 0)' }}
		>
			<path d="M0 0h24v24H0z" fill="none" />
			<path d="M14 2H6c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6zm2 16H8v-2h8v2zm0-4H8v-2h8v2zm-3-5V3.5L18.5 9H13z" />
		</svg>
	),
	account_circle: (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			enable-background="new 0 0 24 24"
			height="48px"
			viewBox="0 0 24 24"
			width="48px"
			fill="#FFF"
			style={{ transform: 'scale(0.5) translate3d(0, 0, 0)' }}
		>
			<g>
				<rect fill="none" height="24" width="24" />
			</g>
			<g>
				<path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 4c1.93 0 3.5 1.57 3.5 3.5S13.93 13 12 13s-3.5-1.57-3.5-3.5S10.07 6 12 6zm0 14c-2.03 0-4.43-.82-6.14-2.88C7.55 15.8 9.68 15 12 15s4.45.8 6.14 2.12C16.43 19.18 14.03 20 12 20z" />
			</g>
		</svg>
	),
};

export default function MaterialIconSvg({ icon }: MaterialIconSvgProps) {
	if (icon && AVAILABLE_SVG_ICONS[icon]) {
		return (
			<span className="svg-icon-container">{AVAILABLE_SVG_ICONS[icon]}</span>
		);
	} else {
		console.error(`That icon isn't available as an svg.`);
		return null;
	}
}
