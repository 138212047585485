import * as R from 'ramda';

const MAX_ITEMS_PER_PAGE = 12;

export const getItemsOnPage = (items: any[], page: number, searchTerm = '') => {
	const indexesToShow = R.range(
		page * MAX_ITEMS_PER_PAGE - MAX_ITEMS_PER_PAGE,
		page * MAX_ITEMS_PER_PAGE
	);
	if (!items) return [];
	return items.filter((_, i) => indexesToShow.includes(i));
};

// Workflows pagination is such: I'm on page 5 and no slice of the results is needed,
// as they're all to render no page 5 (still respecting MAX_ITEMS_PER_PAGE to be sure.
// Versus `getItemsOnPage()` which is when _all results_ are fetched
export const getWorkflowsOnPage = (items: any[], page: number) => {
	const indexesToShow = R.range(0, MAX_ITEMS_PER_PAGE);
	if (!items) return [];
	return items.filter((_, i) => indexesToShow.includes(i));
};

export const showPagination = (workflows: any[], totalItems: number) =>
	totalItems > workflows.length;

export const getTotalPages = (workflows: any[], maxItems?: number) =>
	Math.ceil(workflows.length / (maxItems ?? MAX_ITEMS_PER_PAGE));
