import React, { FormEvent } from 'react';
import { ModalBody, ModalFooter, ButtonGroup } from 'reactstrap';

import { useModalCloser } from '../../../utils/ModalStack';
import DialogModal from '../../modals/dialog-modal.component';
import { ModalTitle } from '../../modals/dialog-modal.styled-components';
import { SubmitButton } from '../../forms';
import {
	AssetVersion,
	AssetCollection,
} from '../../workflow/workflows/types/workflow.types';
interface RemoveAssetDialogProps {
	asset: AssetVersion;
	collection: AssetCollection | null;
	onRemove: (event: FormEvent) => void;
}
const RemoveAssetDialog = (props: RemoveAssetDialogProps) => {
	const modalCloser = useModalCloser();
	return (
		<DialogModal header={`Remove ${props.asset.fileName}`}>
			<ModalBody>
				<ModalTitle>
					Are you sure you want to remove this asset from the collection?
				</ModalTitle>
			</ModalBody>
			<ModalFooter>
				<ButtonGroup>
					<SubmitButton label="Remove" onClick={props.onRemove}>
						Delete
					</SubmitButton>
					<SubmitButton
						size={'sm'}
						className="ml-2"
						label="Cancel"
						onClick={modalCloser.closeModal}
					>
						Cancel
					</SubmitButton>
				</ButtonGroup>
			</ModalFooter>
		</DialogModal>
	);
};

export default RemoveAssetDialog;
