import pLimit from 'p-limit';
import { Nullable } from 'types/globals';
import { requiredValue } from '../common';
import { _logError } from '../common/log';
import LoggerService, { LogLevel } from './LoggingProvider';

const JSON_MIMETYPE = 'application/json';
const API_CLIENT_VERSION = 0;

type HttpMethod = 'HEAD' | 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';
type HttpBodyInit = object | FormData;

function doesMethodTakeBody(method: HttpMethod) {
	return method === 'POST' || method === 'PUT' || method === 'PATCH';
}

interface AuthSource {
	readonly accessToken: Nullable<string>;
	readonly isAuthenticated: boolean;
}

const CONCURRENCY = 5;
export class APIClient {
	private readonly callQueue = pLimit(CONCURRENCY);

	private readonly endpoint = requiredValue(
		process.env.REACT_APP_ROME_API_ENDPOINT
	).replace(/\/+$/g, '');

	constructor(
		private readonly authSource: AuthSource,
		private readonly loggerService: LoggerService
	) {}

	public get(path: string) {
		return this.doRequest(path, 'GET');
	}

	public post(path: string, body?: object) {
		return this.doRequest(path, 'POST', body);
	}

	public postFile(path: string, file: File, data?: object) {
		const formBody = new FormData();

		formBody.set('file', file, file.name);
		if (data) {
			formBody.set('data', JSON.stringify(data));
		}

		return this.doRequest(path, 'POST', formBody);
	}

	public async getForRedirect(path: string): Promise<string> {
		const response: Response = await fetch(
			this.buildPath(path),
			this.buildRequest('GET')
		);

		const locationHeader = response.headers.get('Location');

		if (locationHeader) {
			return locationHeader;
		} else {
			const text = await response.text();
			try {
				return new URL(text).toJSON();
			} catch (err) {
				_logError(err);
			}
		}

		throw new Error('Getting for a redirect, but none was provided');
	}

	/**
	 * Gets a short-lived auth cookie to send with form POSTs.
	 *
	 * Almost all API calls will use Authorization header.
	 * The main exception is for downloading files (XHR/Fetch cannot trigger downloads).
	 *
	 * In order to use native browser form POSTs, we have to send a cookie with the request since we cannot modify headers.
	 * This calls the backend to get a `Set-Cookie` response with a temporary auth cookie (1 minute) to send in a subsequent form POST.
	 */
	public async refreshAuthCookie(): Promise<unknown> {
		return fetch(this.buildPath('session'), {
			...this.buildRequest('GET'),
			// Make sure we get an `Access-Control-Allow-Credentials` response header.
			mode: 'cors',
			// Allows setting a cookie from a different domain.
			credentials: 'include',
		});
	}

	public put(path: string, body?: object) {
		return this.doRequest(path, 'PUT', body);
	}

	public patch(path: string, body: object) {
		return this.doRequest(path, 'PATCH', body);
	}

	public delete(path: string) {
		return this.doRequest(path, 'DELETE');
	}

	private async doRequest(
		path: string,
		method: HttpMethod = 'GET',
		body?: HttpBodyInit
	) {
		const response: Response = await this.callQueue(
			fetch,
			this.buildPath(path),
			this.buildRequest(method, body)
		);

		if (response.status >= 400) {
			const error = await response.json();
			this.loggerService.logToCloudWatch(
				`HTTP Error occurred when making API call for ${path}. See more error details: ${error.message}`,
				LogLevel.error
			);
			throw Error(error.message);
		}

		if (response.headers.get('Content-Type')?.includes(JSON_MIMETYPE)) {
			return response.json();
		} else {
			return response.text();
		}
	}

	private buildRequest(method: HttpMethod, body?: HttpBodyInit): RequestInit {
		const { headers } = this;
		if (
			localStorage.getItem('rome_auth') &&
			JSON.parse(localStorage.getItem('rome_auth') as string)?.accessToken
		) {
			const token = JSON.parse(localStorage.getItem('rome_auth') as string)
				?.accessToken;
			headers.Authorization = `Bearer ${token}`;
		}

		const requestOptions: RequestInit = { method, headers };

		requestOptions.cache = 'default';

		if (body && doesMethodTakeBody(method)) {
			if (body instanceof FormData) {
				requestOptions.body = body;
			} else {
				headers['Content-Type'] = JSON_MIMETYPE;
				requestOptions.body = JSON.stringify(body);
			}
		}

		return requestOptions;
	}

	private get headers(): Record<string, string> {
		const token = JSON.parse(localStorage.getItem('rome_auth') as string)
			?.accessToken;
		return {
			Accept: `${JSON_MIMETYPE}; rome-api-version=${API_CLIENT_VERSION}`,
			Authorization: `Bearer ${token}`,
		};
	}

	/**
	 * Avoid double slashes.
	 * @param path Path relative to the API root.
	 */
	public buildPath(path: string) {
		return `${this.endpoint}/${path
			.replace(this.endpoint, '')
			.replace(/^\//, '')}`;
	}
}
