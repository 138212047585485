import moment from 'moment';
import { isArray } from 'lodash';
import * as T from '../types/workflow.types';
import { StageStatus } from '../../../../utils/models/StageStatusModel';
import { flattenStages } from './workflowStage.helpers';

const sumExpectedDurationHours = (stages: T.Stage[]) =>
	!stages
		? 0
		: stages
				.map((stage) => stage.expectedDurationHrs || 0)
				.reduce((acc, curr) => acc + curr);

export const getActivationDate = (workflow: T.Workflow) =>
	workflow?.stages &&
	workflow?.stages[0]?.events
		?.filter((s) => s.newStatus === 'active')
		.map((s) => s.createdAt)[0];

const getFirstActivatedStage = (workflow: T.Workflow, flatStages: T.Stage[]) =>
	flatStages?.filter((stage: T.Stage) => stage.status === 'active' && stage.type!=='parallel')[0];

export const getOriginalTargetDueDate = (workflow: T.Workflow) => {
	if (!workflow) return null;
	if (workflow.activeDueDate)
		return moment(workflow.activeDueDate).toISOString().substring(0, 10);
	const flatStages = flattenStages(workflow as T.Flattenable) ?? [];
	const firstActiveStage = getFirstActivatedStage(workflow, flatStages);
	if (!firstActiveStage) return;
	if (flatStages) {
		const dueDate = moment(getActivationDate(workflow)).add(
			sumExpectedDurationHours(flatStages),
			'hours'
		);

		return dueDate.toISOString().substring(0, 10);
	}
};

export const getDueDateBasedOnCurrentStages = (workflow: T.Workflow) => {
	const flatStages = flattenStages(workflow as T.Flattenable) ?? [];
	const firstActiveStage = getFirstActivatedStage(workflow, flatStages);
	if (!firstActiveStage) return;

	const event = firstActiveStage.events?.filter(e=>e.newStatus==='active')[firstActiveStage?.events?.length-1];
	const stagesToSum = flatStages.filter(s=>s.status!=='completed'&&s.type!=='parallel');


	if (stagesToSum) {
		const startDate = event?.createdAt??new Date();
		const dueDate = moment(startDate).add(
			sumExpectedDurationHours(stagesToSum),
			'hours'
		);
		return dueDate.toISOString().substring(0, 10);
	}
};

export const isOverdue = (workflow: T.Workflow) => {
	if (!workflow) return false;
	if (workflow.isOverdue) return workflow.isOverdue;
	return (
		!isRoadblocked(workflow) &&
		workflow?.status === 'active' &&
		moment().isAfter(
			workflow.activeDueDate || getOriginalTargetDueDate(workflow)
		)
	);
};

export const isRoadblocked = (workflow: T.Workflow) => {
	if (!workflow) return false;
	if (workflow.isRoadblocked) return workflow.isRoadblocked;
	return (
		workflow.status === 'active' &&
		!!flattenStages(workflow)?.some((stage: T.Stage[] | T.Stage) =>
			isArray(stage)
				? stage.some((substage) => substage.status === 'roadblocked')
				: stage.status === 'roadblocked'
		)
	);
};

export const isPipeline = (workflow: T.Workflow) => {
	if (!workflow) return false;
	if (
		workflow.status === 'pipeline' &&
		flattenStages(workflow)?.every((stages: T.Stage[] | T.Stage) =>
			isArray(stages)
				? stages.every(({ status }) => status === 'queue')
				: stages.status === 'queue'
		)
	)
		return true;
	return false;
};

export const isCompleted = (workflow: T.Workflow) =>
	workflow.status === 'completed';

export const isCancelled = (workflow: T.Workflow) =>
	workflow.status === 'cancelled';

export const isPaused = (workflow: T.Workflow) => workflow.status === 'paused';

export const isHealthy = (workflow: T.Workflow) => {
	if (workflow.isHealthy) return workflow.isHealthy;
	return (
		workflow.status === 'active' &&
		workflow.dueDate != null &&
		!isOverdue(workflow) &&
		!isRoadblocked(workflow)
	);
};

export const hasActivatedStage = (workflow: T.Workflow) =>
	workflow?.stages && workflow?.stages[0]?.status === StageStatus.active;

export const isActive = (workflow: T.Workflow) =>
	!isCompleted(workflow) && workflow.status === 'active';
