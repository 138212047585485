import { faAsterisk } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { generateID } from 'utils/common';
import { OutlineButton } from 'components/buttons.styled-components';
import { ConfirmationDialog, Modal } from 'components/Modal';
import OwnerAvatarList from 'components/owner-avatar-list.component';
import { NotificationsContext } from 'components/notifications';
import * as R from 'ramda';
import React, { useContext, useState } from 'react';
import { Alert, FormFeedback, UncontrolledTooltip } from 'reactstrap';
import { useTemplateContext } from 'context/useTemplateContext';
import {
	flattenStages,
	hasBackwardTransition,
	hasSubstages,
	isInitialStage,
} from 'components/workflow/workflows/helpers';
import {
	Flattenable,
	Stage,
	WorkflowTemplate,
} from '../../../workflows/types/workflow.types';
import { AddStageButton } from './AddStageButton';
import { EditAttachmentSlots } from './EditAttachmentSlots';
import * as H from './Stage.helpers';
import { StageForm } from './StageForm';
import { StageTaskDialog } from './StageTaskDialog';
import { CardTab, StageCard } from './template-stage-card.styled-components';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import Attachment from '@mui/icons-material/Attachment';

// interfaces
export interface TemplateStageCardProps<T extends Stage> {
	stage: T;
	editable: boolean;
	template: WorkflowTemplate;
	peeking?: boolean;
	className?: string;
	isSubstage?: boolean;
	id?: string;
}

export const defaultStage = (
	type: 'single' | 'parallel' | 'substage' | 'sideTask'
): Stage => ({
	_id: generateID(),
	title: 'Untitled Stage',
	type: type,
	expectedDurationHrs: 24,
	inputSlots: [],
	owners: [],
	events: [],
	instructions: '',
	transitions: [],
	tasks: [],
});

export const TemplateStageCard = (props: TemplateStageCardProps<Stage>) => {
	const { template, saveTemplate } = useTemplateContext();
	const { info } = useContext(NotificationsContext);
	const [isEditing, setIsEditing] = useState(false);
	const [isAddingSubstage, setIsAddingSubstage] = useState(false);
	const [isAddingParallel, setIsAddingParallel] = useState(false);
	const [isInserting, setIsInserting] = useState(false);
	const [isDeleting, setIsDeleting] = useState(false);
	const [isAddingAttachmentSlots, setIsAddingAttachmentSlots] = useState(false);
	const [isEditingTasks, setIsEditingTasks] = useState(false);

	const getPhaseColor = (template?: WorkflowTemplate) => {
		if (!template?.phases.length) return '';
		if (!props.stage.phase) return '';
		return template.phases?.find((phase) => phase._id === props.stage.phase)
			?.color;
	};

	const updateStage = async (updateData: Stage) => {
		if (!template) return;
		if (props.stage.type === 'sideTask') {
			await saveTemplate({
				...template,
				sideTasks: template?.sideTasks
					? [
							...template.sideTasks.map((sideTask) =>
								sideTask._id === props.stage._id ? updateData : sideTask
							),
					  ]
					: [],
			});
		} else {
			await saveTemplate({
				...template,
				stages: H.updateStage(props.stage._id, updateData, template.stages),
			});
		}
		info('Stage Has Been Updated!');
		setIsEditing(false);
	};

	const insertStage = async (newStageData: Stage) => {
		if (!template) return;

		const newStage: Stage = {
			...defaultStage('single'),
			...newStageData,
		};
		if (props.stage.type === 'sideTask') {
			await saveTemplate({
				...template,
				sideTasks: H.insertSideTask(newStage, template.sideTasks || []),
			});
		} else {
			await saveTemplate({
				...template,
				stages: H.insertStage(props.stage, newStage, template.stages),
			});
		}
		setIsInserting(false);
	};

	const addSubstage = async (newStageData: Partial<Stage>) => {
		if (!template) return;
		const updatedStage = {
			...props.stage,
			type: 'parallel',
			substages: [
				[
					{
						...defaultStage('substage'),
						...newStageData,
					},
				],
			],
		};

		await saveTemplate({
			...template,
			stages: H.updateStage(props.stage._id, updatedStage, template.stages),
		});
		setIsAddingSubstage(false);
	};

	const addParallelSubstage = async (newStageData: Partial<Stage>) => {
		if (!template) return;
		const newParallelStage = {
			...defaultStage('substage'),
			...newStageData,
		};

		const updatedStage = {
			...props.stage,
			substages: [...(props.stage.substages || []), [newParallelStage]],
		};

		await saveTemplate({
			...template,
			stages: H.updateStage(props.stage._id, updatedStage, template.stages),
		});
		setIsAddingParallel(false);
	};

	const removeStage = async () => {
		if (!template) return;

		if (props.stage.type === 'sideTask') {
			await saveTemplate({
				...template,
				sideTasks: (template?.sideTasks || []).filter(
					(a) => a._id !== props.stage._id
				),
				stages: H.deleteStage(props.stage, template.stages),
			});
		} else {
			await saveTemplate({
				...template,
				stages: H.deleteStage(props.stage, template.stages),
			});
		}
		setIsDeleting(false);
	};

	const { editable } = props;

	const getStageForInsert = () => {
		return props.stage.type === 'sideTask'
			? defaultStage('sideTask')
			: defaultStage('single');
	};

	return (
		<>
			<StageCard
				style={{ background: getPhaseColor(template) as string }}
				className={`stage-card_${props.stage._id} ${props.stage.type} rome-card`}
				id={`stage-card_${props.stage._id}`}
			>
				<div className="title">
					<h2>
						{!!props.stage.isRequired && (
							<>
								<UncontrolledTooltip target={`required-${props.stage._id}`}>
									This stage is required to be completed before the workflow can
									be completed.
								</UncontrolledTooltip>
								<FontAwesomeIcon
									id={`required-${props.stage._id}`}
									className={'text-danger'}
									icon={faAsterisk}
									style={{
										width: '0.7rem',
										verticalAlign: 'super',
										display: 'inline-block',
										marginRight: '.2rem',
									}}
								/>
							</>
						)}
						{props.stage.title}
						{/* <div className="text-small">{props.stage._id}</div> */}
					</h2>

					{/* Show Expected Duration Hours As long as there are no substages */}
					{!props.stage.substages && (
						<p className="hours">
							{props.stage?.expectedDurationHrs + ' '} hours
						</p>
					)}

					{/* If the user has added an input(attachement) slot for the stage, then show the paperclip in the top right*/}
					{props?.stage.inputSlots?.length > 0 &&
						!R.isEmpty(props.stage?.inputSlots) && (
							<>
								<UncontrolledTooltip
									target={`attachmentTab${props.stage?._id}`}
								>
									{props.stage?.inputSlots?.every((slot) => slot.optional) && (
										<>Attachment optional</>
									)}
									{props.stage?.inputSlots?.every((slot) => slot.optional) ===
										false && <>Attachment required</>}
								</UncontrolledTooltip>
								<CardTab id={`attachmentTab${props.stage?._id}`}>
									<Attachment />
								</CardTab>
							</>
						)}
				</div>

				<div className="owners">
					<OwnerAvatarList displayNames={false} owners={props.stage?.owners} />
				</div>

				{props.stage.type === 'sideTask' &&
					flattenStages(template as Flattenable, true).every(
						(stage) =>
							!stage.transitions.some((a) => a.targetStage === props.stage._id)
					) && (
						<Alert
							color={'danger'}
							className="justify-content-between d-flex align-items-center px-2"
							style={{ maxWidth: '90%', margin: 'auto' }}
						>
							<WarningRoundedIcon />
							<FormFeedback
								className="text-danger"
								style={{ display: 'block' }}
							>
								To utilize this side task, update a stage to activate the side
								task on completion.{' '}
							</FormFeedback>
						</Alert>
					)}

				{/* If the Card has substages, display them recursively */}
				<div className="card-content">
					{props.stage.substages && (
						<div className="substages">
							{props.stage.substages.map((substage, i) => (
								<div
									key={`substage_${i}_${substage[0]._id}`}
									className="substage"
								>
									{substage.map((stage, subI) => (
										<div
											key={`substage-card-${i}-${subI}-${stage._id}`}
											id={stage._id}
										>
											<TemplateStageCard
												key={stage._id}
												template={props.template}
												stage={stage}
												editable={editable}
												isSubstage
											/>
										</div>
									))}
								</div>
							))}
						</div>
					)}

					{/* Show 'Add Substages' when there are no substages, show 'Add Parallel Substages' when a set of substages already exists */}
					<div className="card-buttons">
						{props.stage.type !== 'sideTask' &&
							template &&
							!props.isSubstage &&
							!isInitialStage(props.stage, template.stages) && (
								<>
									<UncontrolledTooltip
										target={`substageButton${props.stage?._id}`}
									>
										{!props.stage.substages || R.isEmpty(props.stage.substages)
											? `Add additional Stage(s) that must be completed before the workflow can move forward`
											: `Add additional Stage(s) that must be completed before the workflow can move forward, but can be worked on in parallel to the other substages in '${props.stage.title}'`}
									</UncontrolledTooltip>
									{
										<OutlineButton
											disabled={!editable || hasBackwardTransition(props.stage)}
											id={`substageButton${props.stage?._id}`}
											onClick={() =>
												!props.stage.substages ||
												R.isEmpty(props.stage.substages)
													? setIsAddingSubstage(true)
													: setIsAddingParallel(true)
											}
										>
											{!props.stage.substages ||
											R.isEmpty(props.stage.substages)
												? 'Add Substages'
												: 'Add Parallel Substages'}
										</OutlineButton>
									}
								</>
							)}
						{!hasSubstages(props.stage) && (
							<OutlineButton
								disabled={!editable}
								onClick={() => setIsAddingAttachmentSlots(true)}
							>
								Edit Attachment Slots ({props.stage.inputSlots?.length || 0})
							</OutlineButton>
						)}
						{!hasSubstages(props.stage) && (
							<OutlineButton
								disabled={!editable}
								onClick={() => setIsEditingTasks(true)}
							>
								Edit Tasks ({props.stage?.tasks?.length || 0})
							</OutlineButton>
						)}
					</div>
				</div>

				{/* Edit and Delete Buttons */}
				<div className="actions">
					<button
						className="button"
						onClick={() => setIsEditing(true)}
						disabled={!editable}
					>
						Edit
					</button>
					<button
						className="button"
						// Disable button for the first stage item
						disabled={template?.stages[0]._id === props.stage._id || !editable}
						onClick={() => setIsDeleting(true)}
					>
						Delete
					</button>
				</div>
				<p className="text-small text-right mt-2 mb-0">{props.stage._id}</p>
			</StageCard>

			{/* This is the Button that allows you to add a new stage.
					This component also contains the arrows/lines between
					the stages */}
			{props.stage.type !== 'sideTask' && props.stage.type !== 'substage' &&(
				<AddStageButton
					title={`Add New ${
						props.stage.type === 'sideTask' ? 'Side Task' : 'Stage'
					}`}
					editable={editable}
					openCreateStageModal={() => setIsInserting(true)}
					isLastStage={
						template && H.isLastStageInList(props.stage, template?.stages)
					}
					phaseColor={
						typeof props.stage.phase === 'string'
							? undefined
							: props.stage.phase?.color
					}
				/>
			)}

			{isEditingTasks && (
				<StageTaskDialog
					onClose={() => setIsEditingTasks(false)}
					isOpen={isEditingTasks}
					stage={props.stage}
				/>
			)}

			{/* Modal for editing the current Stage */}
			{isEditing && (
				<StageForm
					title="Edit Stage"
					isOpen={isEditing}
					onSubmit={updateStage}
					closeModal={() => setIsEditing(false)}
					stage={props.stage}
					calling_stage={props.stage}
				/>
			)}

			{/* Modal for Adding a Substage */}
			{isAddingSubstage && (
				<StageForm
					title="Add Substage"
					isOpen={isAddingSubstage}
					onSubmit={addSubstage}
					closeModal={() => setIsAddingSubstage(false)}
					stage={defaultStage('substage')}
					calling_stage={props.stage}
				/>
			)}

			{/* Modal for Adding a Parallel Substage */}
			{isAddingParallel && (
				<StageForm
					title="Add Parallel Substage"
					isOpen={isAddingParallel}
					onSubmit={addParallelSubstage}
					closeModal={() => setIsAddingParallel(false)}
					stage={defaultStage('substage')}
					calling_stage={props.stage}
				/>
			)}

			{/* Modal For Inserting a Stage After This One */}
			{isInserting && (
				<StageForm
					title={`Insert ${
						props.stage.type === 'sideTask' ? 'Side Task' : 'Stage'
					} after ${props.stage.title}`}
					isOpen={isInserting}
					onSubmit={insertStage}
					closeModal={() => setIsInserting(false)}
					stage={getStageForInsert()}
					calling_stage={props.stage}
				/>
			)}

			{/* Modal for deletion */}
			{isDeleting && (
				<ConfirmationDialog
					title={`Delete ${props.stage.title}?`}
					isOpen={isDeleting}
					onConfirm={removeStage}
					onCancel={() => setIsDeleting(false)}
				/>
			)}

			{isAddingAttachmentSlots && (
				<Modal
					style={{ maxWidth: 750, overflowX: 'hidden' }}
					isOpen={isAddingAttachmentSlots}
					title="Edit Attachment Slots"
					onClose={() => setIsAddingAttachmentSlots(false)}
				>
					<EditAttachmentSlots stage={props.stage} />
				</Modal>
			)}
		</>
	);
};

export default TemplateStageCard;
