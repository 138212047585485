import { navigate } from '@reach/router';
import { buildEmptyAssetMetadata } from 'components/dam-assets/components/helpers/useAssetHelper';
import { useWorkflowCollectionContext } from 'components/workflow/workflows/hooks/useWorkflowCollectionStore';
import { useWorkflowContext } from 'context/useWorkflowStore';
import React, { FormEvent, useCallback } from 'react';
import { useAxios } from '../../../../hooks';
import { useAuthContext } from '../../../../utils/auth';
import { generateID } from '../../../../utils/common';
import {
	Workflow,
	WorkflowCollection,
} from '../../workflows/types/workflow.types';
import WorkflowCollectionForm from './workflow-collection-form.component';
import { NotificationsContext } from '../../../../components/notifications';

const AddWorkflowCollectionForm = () => {
	const collectionStore = useAxios<WorkflowCollection>('projects');
	const { error } = React.useContext(NotificationsContext);
	const { updateContext } = useWorkflowCollectionContext();
	const { currentUser } = useAuthContext();
	const { entities: workflows } = useWorkflowContext();
	const [collectionRef, setCollectionRef] = React.useState<WorkflowCollection>({
		_id: generateID(),
		createdBy: currentUser,
		metadata: buildEmptyAssetMetadata,
	} as WorkflowCollection);
	const workflowStore = useAxios<Workflow>('workflows');

	const addCollection = useCallback(
		async (event: FormEvent) => {
			event.preventDefault();
			try {
				const createdCollection = (await collectionStore.createOne(
					collectionRef
				)) as WorkflowCollection;

				createdCollection.workflows.forEach(async (wf: string | Workflow) => {
					// @ts-ignore
					const flow = workflows.find((m) => m._id === (wf as string));
					const edited = {
						...flow,
						workflowCollection: createdCollection,
					} as Workflow;
					await workflowStore.updateOne(edited?._id, edited);
				});
				updateContext(createdCollection);
				return navigate(`/admin/workflow/projects/${createdCollection._id}`);
			} catch (err) {
				// @ts-ignore
				error(
					err?.message
						? err.message
						: 'Failed to create new project. Please try again later'
				);
			}
		},
		//eslint-disable-next-line
		[collectionStore, workflowStore, workflows, collectionRef]
	);

	return (
		<WorkflowCollectionForm
			onChange={(updated) => setCollectionRef(updated)}
			selectedCollection={collectionRef}
			onSubmit={addCollection}
		/>
	);
};
export default AddWorkflowCollectionForm;
