import {
	AssetVersion,
	Workflow,
	WorkflowCollection,
	WorkflowTemplate,
} from '../../components/workflow/workflows/types/workflow.types';
import { _logWarning } from './log';
import { User } from '../auth';
import { QueryModel } from 'context/useQueryStore';

const queryBuilder = (prefix: string) => (q: string) =>
	`${prefix}?q=${encodeURIComponent(q)}`;

const buildAssetQueryUrl = queryBuilder('/admin/dam/assets');
const buildAssetCollectionQueryUrl = queryBuilder('/admin/dam/collections');
const buildWorkflowsQueryUrl = queryBuilder('/admin/workflow/workflows');
const buildWorkflowCollectionQueryUrl = queryBuilder(
	'/admin/workflow/projects'
);

export const buildUserProfileUrl = (user: User) =>
	`/admin/profile/${user?._id}`;

export function buildComparisonUrl(
	versionA: AssetVersion,
	versionB: AssetVersion,
	workflowA: { _id: string },
	workflowB: { _id: string }
) {
	return `/admin/dam/assets/comparison/${workflowA._id}/${versionA._id}/${workflowB._id}/${versionB._id}`;
}

export function buildWorkflowUrl(workflow: Workflow) {
	return `/admin/workflow/workflows/${workflow?._id}`;
}

export function buildSavedQueryUrl({ type, value }: QueryModel) {
	switch (type) {
		case 'assets' || 'assets':
			return buildAssetQueryUrl(value);

		case 'collections':
			return buildAssetCollectionQueryUrl(value);

		case 'workflows':
			return buildWorkflowsQueryUrl(value);

		case 'workflow_collections' || 'projects':
			return buildWorkflowCollectionQueryUrl(value);

		default:
			_logWarning(`The query type "${type}" is not handled.`);
			return '';
	}
}

export function buildStageUrl(workflow?: Workflow | string, stage?: any) {
	const workflowId = typeof workflow === 'string' ? workflow : workflow?._id;
	return `/admin/workflow/workflows/${workflowId}/stages#${stage?._id}`;
}

export function buildTemplateUrl(template: string | WorkflowTemplate) {
	if (!template) return '';
	const id = template.hasOwnProperty('_id')
		? (template as WorkflowTemplate)._id
		: template;
	return `/admin/workflow/templates/${id}`;
}

export function buildWorkflowCollectionUrl(
	workflowCollection: WorkflowCollection
) {
	return `/admin/workflow/projects/${workflowCollection._id}`;
}

export function buildUserUrl(user: User) {
	return `/admin/dashboard/users/${user?._id}`;
}

export function buildVersionUrl(version: any) {
	return `/admin/workflow/workflows/${
		(version as any).workflowId
	}/asset-versions/${version._id}`;
}

export function buildAssetDetailsUrl(asset: any) {
	return `admin/dam/assets/${asset._id}`;
}

export function buildVersionDetailsUrl(version: any) {
	return `admin/dam/assets/${version.versionId}/true`;
}
